import React, { Component } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./EquizStyles.web";
import i18n from "../../../web/src/utilities/i18n";
export const configJSON = require("./config.js");


export interface Props extends WithStyles<typeof customStyles> {
    navigation?: any;
    id?: string;
    classes: any;
  }

  export interface S {
    // Customizable Area Start
    isFavQuizzesModalOpen: boolean;
    selectedQuizzes: any[],
    activeTab: string,
    isCampaignsModalOpen: boolean,
    isNewContactsModalOpen: boolean,
    isTasksCompletedModalOpen: boolean,
    isGoogleClicksModalOpen: boolean,
    tasks: any[],
    completedTasks: any[],
    favoriteQuizzes: any[],
    favoriteQuizzesFetched: any[],
    isRemoveFavorite: boolean,
    selectedCheckboxes: any[],
    selectedStatisticsFilter: string,
    newContactsCount: number,
    newContacts: any[],
    selectedDealsStatisticsFilter: string,
    campaignsReachCount: number,
    googleClicksCount: number,
    allQuizes: any[],
    dealStatistics: {
      initiated: {
        count: number,
        potential_earnings: number,
      },
      sent_proposal: {
        count: number,
        potential_earnings: number,
    },
      follow_up: {
        count: number,
        potential_earnings: number,
    },
      won: {
        count: number,
        earned: number,
      },
      lost: {
        count: number,
        missed_opportunity: number,
      }

    },
    // Customizable Area End
  }
  export interface SS {
    id: any;
    // Customizable Area Start
  
    // Customizable Area End
  }


  export default class OwnerDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
    userSessionData: any;
    userToken: any;
    projectListApiID: string = "";
    taskFilterApiID: string = "";
    newContactsApiId: string = "";
    dealStatisticsApiId: string = "";
    allStatisticsApiId: string = "";
    allFavouriteEQuizes: string = "";
    getUserQuizesAPICallId: string = "";

    constructor(props: any) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            isFavQuizzesModalOpen: false,
            selectedQuizzes: [],
            activeTab: "Favorite",
            isCampaignsModalOpen: false,
            isNewContactsModalOpen: false,
            isTasksCompletedModalOpen: false,
            isGoogleClicksModalOpen: false,
            tasks: [],
            completedTasks: [],
            favoriteQuizzes: [],
            favoriteQuizzesFetched: [],
            isRemoveFavorite: false,
            selectedCheckboxes: [],
            selectedStatisticsFilter: "Last 7 Days",
            newContactsCount: 0,
            newContacts: [],
            selectedDealsStatisticsFilter: "Last 7 Days",
            campaignsReachCount: 0,
            googleClicksCount: 0,
            allQuizes: [],
            dealStatistics: {
              initiated: {
                count: 0,
                potential_earnings: 0,
              },
              sent_proposal: {
                count: 0,
                potential_earnings: 0,
            },
              follow_up: {
                count: 0,
                potential_earnings: 0,
            },
              won: {
                count: 0,
                earned: 0,
              },
              lost: {
                count: 0,
                missed_opportunity: 0,
              }
              
            },
          };

        this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
        this.userToken = JSON.parse(this.userSessionData);
    }

    async componentDidMount(): Promise<void> {
        this.getTasks();
        this.getNewContacts();
        this.getDealStatistics();
        this.getAllStatistics();
        this.getAllFavouriteEQuizes();
        this.getAllUserQuizes();
        let favQ = this.getQuizzesForTab();
        const lang = localStorage.getItem("lang") ?? "en";
        await (i18n as any).changeLanguage(lang);
      }

      componentDidUpdate(prevProps: Props, prevState: S) {
        if (
            JSON.stringify(prevState.selectedQuizzes) !== JSON.stringify(this.state.selectedQuizzes)
        ) {
            this.updateFavoriteQuizzes();
            this.getAllUserQuizes();
        }

        if (prevState.selectedStatisticsFilter !== this.state.selectedStatisticsFilter) {
            this.getNewContacts();
            this.getAllStatistics();
        }

        if (prevState.selectedDealsStatisticsFilter !== this.state.selectedDealsStatisticsFilter) {
          this.getDealStatistics();
      }
    }
    
      t(key:any, variables?: Record<string, any>) {
        return (i18n as any).t(key, { ns: "translation" , ...variables } )
      }

      async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (apiRequestCallId && responseJson) {
            if (!responseJson.errors) {
              switch (apiRequestCallId) {
                case this.taskFilterApiID:
                  let completedTasks = responseJson.data.filter((obj: any) => obj.attributes.status === 'completed');
                  this.setState({
                    tasks: responseJson.data,
                    completedTasks: completedTasks,
                  });
                  break;
                case this.newContactsApiId:
                  let newContacts = responseJson;
                  this.setState({
                    newContactsCount: newContacts.contacts.length,
                    newContacts: newContacts.contacts
                  })
                  break;
                case this.dealStatisticsApiId:
                  let dealStats = responseJson;
                  this.setState({
                    dealStatistics: dealStats,
                  })
                  break;
                case this.allStatisticsApiId:
                  let allStats = responseJson;
                  this.setState({
                    campaignsReachCount: allStats.campaign_count,
                    googleClicksCount: allStats.google_click_count,
                  })
                  break;
                case this.allFavouriteEQuizes:
                  let allFavQuizes = responseJson.data;
                  this.setState({favoriteQuizzes: allFavQuizes});
                  break;
                case this.getUserQuizesAPICallId:
                  let allQuiz = responseJson.data;
                  this.setState({allQuizes: allQuiz});
              }
            }
          }
        }
      }
    taskFilterEndPoint = "/account_block/task_filter";
    newContactsEndPoint = "bx_block_statisticsreports/contact_statistics?date_range=";
    dealStatisticsEndPoint = "bx_block_statisticsreports/deal_statistics?date_range=";
    allStatisticsEndPoint = "bx_block_statisticsreports/all_statistics_count?date_range=";
    allFavouriteEQuizesEndPoint = "bx_block_statisticsreports/fetch_favorites";
    getAllUserQuizesEndPoint = "bx_block_dashboard/quizzes";

    handleModalOpen = () => {
        this.setState({ isFavQuizzesModalOpen: true });
    };

    openCampaignsReachModal = () => {
        this.setState({ isCampaignsModalOpen: true });
    };
    
    openNewContactsModal = () => {
        this.setState({ isNewContactsModalOpen: true });
    };
    
    openTasksCompletedModal = () => {
        this.setState({ isTasksCompletedModalOpen: true });
    };
    
    openGoogleClicksModal = () => {
        this.setState({ isGoogleClicksModalOpen: true });
    };
    
    handleModalClose = () => {
        this.setState({ isFavQuizzesModalOpen: false });
        this.setState({ isCampaignsModalOpen: false });
        this.setState({ isNewContactsModalOpen: false });
        this.setState({ isTasksCompletedModalOpen: false });
        this.setState({ isGoogleClicksModalOpen: false });

    };

    getHeaderData = () => {
        return [
            {
                status: this.state.campaignsReachCount === 0 ? "Not Started Yet" : " ",
                count: this.state.campaignsReachCount,
                key: "Campaigns Reach",
                onClick: this.openCampaignsReachModal,
            },
            {
                status: this.state.newContactsCount === 0 ? "Not Started Yet" : " ",
                count: this.state.newContactsCount,
                key: "New Contacts",
                onClick: this.openNewContactsModal,
            },
            {
                status: this.state.completedTasks.length === 0 ? "Not Started Yet" : " ",
                count: this.state.completedTasks.length,
                key: "Tasks Completed",
                onClick: this.openTasksCompletedModal,
            },
            {
                status: this.state.googleClicksCount === 0 ? "Not Started Yet" : " ",
                count: this.state.googleClicksCount,
                key: "Google Clicks",
                onClick: this.openGoogleClicksModal,
            },
        ];
    };

    getFooterData = () => {
      const { dealStatistics } = this.state;
        return [
            {
                key: "Initiated",
                count: dealStatistics.initiated.count,
                status: `${dealStatistics.initiated.potential_earnings} potential earnings`,
            },
            {
                key: "Proposals Sent",
                count: dealStatistics.sent_proposal.count,
                status: `${dealStatistics.sent_proposal.potential_earnings} potential earnings`,
            },
            {
                key: "Follow-ups",
                count: dealStatistics.follow_up.count,
                status: `${dealStatistics.follow_up.potential_earnings} potential earnings`,
            },
            {
                key: "Won",
                count: dealStatistics.won.count,
                status: `${dealStatistics.won.earned} earned`,
            },
            {
                key: "Lost",
                count: dealStatistics.lost.count,
                status: `${dealStatistics.lost.missed_opportunity} lost`,
            },
        ];
    };

    data = [
        {
            "label": "Series 1",
            "data": [
                {
                    "primary": "2024-10-02T00:00:00.000Z",
                    "secondary": 72
                },
                {
                    "primary": "2024-10-03T00:00:00.000Z",
                    "secondary": 49
                },
                {
                    "primary": "2024-10-04T00:00:00.000Z",
                    "secondary": 70
                },
                {
                    "primary": "2024-10-05T00:00:00.000Z",
                    "secondary": 58
                },
                {
                    "primary": "2024-10-06T00:00:00.000Z",
                    "secondary": 13
                },
                {
                    "primary": "2024-10-07T00:00:00.000Z",
                    "secondary": 86
                },
                {
                    "primary": "2024-10-08T00:00:00.000Z",
                    "secondary": 76
                },
                {
                    "primary": "2024-10-09T00:00:00.000Z",
                    "secondary": 53
                },
                {
                    "primary": "2024-10-10T00:00:00.000Z",
                    "secondary": 49
                },
                {
                    "primary": "2024-10-11T00:00:00.000Z",
                    "secondary": 72
                }
            ]
        },
    
    ];

    tabValues = ["Favorite", "Recent", "Top 3", "Bottom 3"];

    handleTabClick = (tab: any) => {
        this.setState({activeTab: tab});
      };

    quizzes = [
        { id: 1, name: "Carbon Footprint", date: "Aug 07, 2022", color: "#4CAF50", grade: "A" },
        { id: 2, name: "Soil Regeneration Index", date: "Aug 07, 2022", color: "#03A9F4", grade: "B" },
        { id: 3, name: "Economic", date: "Jan 31, 2023", color: "#FFEB3B", grade: "C" },
        { id: 4, name: "Labor Cost", date: "Jan 31, 2023", color: "#FF7043", grade: "D" },
        { id: 5, name: "Tools and Machines Costs", date: "Jun 24, 2022", color: "#F44336", grade: "E" },
        { id: 6, name: "Inputs and Chemicals Costs", date: "Jun 24, 2022", color: "#4CAF50", grade: "A"  },
    ];

    handleCheckboxChange = (id: number) => {
        this.setState((prevState) => {
            let updatedCheckboxes;
    
            if (prevState.selectedCheckboxes.includes(id)) {
                updatedCheckboxes = prevState.selectedCheckboxes.filter((quizId) => quizId !== id);
            } else if (prevState.selectedCheckboxes.length < 3) {
                updatedCheckboxes = [...prevState.selectedCheckboxes, id];
            } else {
                alert('You can select up to 3 quizzes only.');
                return null; 
            }
    
            return { selectedCheckboxes: updatedCheckboxes };
        });
    };

    continueToFavorites = () => {
        this.setState({
            selectedQuizzes: this.state.selectedCheckboxes,
            isFavQuizzesModalOpen: false, 
        });
    }; 

    getHexBadgeColor = (grade: string) => {
        const gradeColorMap: { [key: string]: string } = {
            A: "#00BA88", // Green
            B: "#12AEF1", // Blue
            C: "#F7E223", // Yellow
            D: "#EE8464", // Light Red
            E: "#C7263E", // Dark Red
        };
        return gradeColorMap[grade] || "#757575"; // Default gray if grade is undefined
    };

    updateFavoriteQuizzes = () => {
        const { selectedQuizzes, favoriteQuizzes } = this.state;

        if (!Array.isArray(this.quizzes)) {
            console.error("quizzes is not defined or is not an array.");
            return;
        }

        const newFavoriteQuizzes = this.quizzes.filter((quiz) => selectedQuizzes.includes(quiz.id));

        if (JSON.stringify(newFavoriteQuizzes) !== JSON.stringify(favoriteQuizzes)) {
            this.setState({ favoriteQuizzes: newFavoriteQuizzes });
        }
    };

    removeFavorite = (id: number) => {
    this.setState((prevState) => {
        const updatedSelectedQuizzes = prevState.selectedQuizzes.filter((quizId) => quizId !== id);
        const updatedFavQuiz = prevState.favoriteQuizzes.filter((quiz) => quiz.id !== id);
        
        return { 
            selectedQuizzes: updatedSelectedQuizzes, 
            favoriteQuizzes: updatedFavQuiz,
            selectedCheckboxes: updatedSelectedQuizzes,
        };
    });
    this.setState({isRemoveFavorite: false});
};

    getGradeValue = (grade: string): number => {
        const gradeMap = { A: 4, B: 3, C: 2, D: 1 }  as const; 
        return gradeMap[grade as keyof typeof gradeMap] || 0;

    };
    
    getTop3Quizzes = () => {
        return [...this.quizzes]
            .sort((a, b) => this.getGradeValue(b.grade) - this.getGradeValue(a.grade)) // Sort by grade descending
            .slice(0, 3); // Pick top 3
    };
    
    getBottom3Quizzes = () => {
    return [...this.quizzes]
        .sort((a, b) => this.getGradeValue(a.grade) - this.getGradeValue(b.grade)) // Sort by grade ascending
        .slice(0, 3); // Pick bottom 3
};

    getQuizzesForTab = () => {
        const { activeTab } = this.state;
    
        switch (activeTab) {
            case "Favorite":
                return this.state.favoriteQuizzes;
            case "Top 3":
                return this.getTop3Quizzes();
            case "Bottom 3":
                return this.getBottom3Quizzes();
           
        }
    };
    

    getPrimaryAxis = () => {
        return {
            getValue: (datum: any) => new Date(datum.primary),
        };
    };

    getSecondaryAxes = () => {
        return [
            {
                getValue: (datum: any) => datum.secondary,
            },
        ];
    };

    getTasks = () => {
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: this.userToken.meta.token,
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.taskFilterApiID = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          this.taskFilterEndPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };

      getNewContacts = () => {
        const { selectedStatisticsFilter } = this.state;

        const mappedFilter = selectedStatisticsFilter === "Last 7 Days" ? "7 days" : selectedStatisticsFilter;

        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: this.userToken.meta.token,
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.newContactsApiId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${this.newContactsEndPoint}${encodeURIComponent(mappedFilter)}`
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };


      getDealStatistics = () => {
        const { selectedDealsStatisticsFilter } = this.state;

        const mappedFilter = selectedDealsStatisticsFilter === "Last 7 Days" ? "7 days" : selectedDealsStatisticsFilter;

        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: this.userToken.meta.token,
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.dealStatisticsApiId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${this.dealStatisticsEndPoint}${encodeURIComponent(mappedFilter)}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };

      getAllStatistics = () => {
        const { selectedStatisticsFilter } = this.state;

        const mappedFilter = selectedStatisticsFilter === "Last 7 Days" ? "7 days" : selectedStatisticsFilter;

        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: this.userToken.meta.token,
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.allStatisticsApiId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
           `${this.allStatisticsEndPoint}${encodeURIComponent(selectedStatisticsFilter)}`
          );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      getAllFavouriteEQuizes = () => {

        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: this.userToken.meta.token,
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.allFavouriteEQuizes = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
           this.allFavouriteEQuizesEndPoint
          );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      getAllUserQuizes = () => {
        const { selectedStatisticsFilter } = this.state;

        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: this.userToken.meta.token,
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getUserQuizesAPICallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          this.getAllUserQuizesEndPoint
          );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

 }

