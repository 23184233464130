import React from "react";

import // Customizable Area Start
  { Box,
    Typography,
    Button,
    Card,
    Grid }
    from
// Customizable Area End
"@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import { customStyles } from "./EquizStyles.web";
import { Link } from "react-router-dom";
import GlobalHeaderWeb from "../../customisableuserprofiles/src/GlobalHeader.web";
import {
  sortVariant,
  framePng,
  up, down,
  wine, instagram, facebook, down_arrow
} from "./assets";
import FbFollowersModal from "./components/FbFollowersModal";
import InstagramFollowersModal from "./components/InstagramFollowersModal";


// Customizable Area Start
import MarketingDashboardController, {
    Props,
  } from "./MarketingDashboardController.web"
  import WebLoader from "../../../components/src/WebLoader.web";

  export const formatDate = (isoString: string) => {
    const date = new Date(isoString);
    
    const options: Intl.DateTimeFormatOptions = {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
  
    return date.toLocaleString("en-US", options);
  };
// Customizable Area End

export class MarketingDashboard extends MarketingDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  currentlyNodataAvailable = () => {
    return (
        <>
            <Card className={'followers-card'} >
                <CardContent className="content-card">
                    <Box className="right-content" display="flex" justifyContent={"space-between"}>

                        <Box display="flex" justifyContent={"flex-start"} alignItems={'center'}>
                            <Typography className="quiz-title" variant="h1">
                            currently no data available
                        </Typography>
                            
                        </Box>
                        
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-end'} >
                    </Box>
                </CardContent>
            </Card>
        </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const pageURL = window.location.pathname;
    // Customizable Area End
    return (
      // Customizable Area Start
    
        <Box>
            {this.state.isLoading && <WebLoader />}
            <Box
                className={`wrapper ${classes.wrapper} ${classes.dashboardWrapper}`}
                data-test-id="marketingDashboard"
            >
                <div style={{position: "fixed", width: "100%", zIndex: 2}}>
                 <GlobalHeaderWeb  pageLink={pageURL}/>
                </div>
                <Box style={{marginTop: "80px"}} className={classes.innerWrapper}>
                    <Box className={classes.teamModuleWrapper}>
                        <Grid container spacing={2}>
                            <Grid item sm={8} xs={8}>
                                <Box className="google-heading-wrapper">
                                    <Box className="heading-left">
                                        <Typography className="heading" variant="h1">
                                            {this.t("Social Media Followers")}
                                        </Typography>
                                    </Box>
                                    <Box style={{marginRight: "-20px"}} className="heading-right">
                                        <Box className="filter-list">
                                            <FormControl className="select-control sortby-control">
                                                <Select
                                                    MenuProps={{
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "left",
                                                        },
                                                        getContentAnchorEl: null,
                                                        classes: { paper: classes.dropdownClass },
                                                    }}
                                                    // onChange={this.handleSubmittedQuizesSort}
                                                    
                                                    displayEmpty
                                                    defaultValue={this.t("Last 7 Days")}
                                                    style={{fontFamily: "Expletus Sans", minWidth: "130px"}}
                                                    inputProps={{ IconComponent: () => null }}
                                                    renderValue={(value) => {
                                                        return (
                                                            <Box className={classes.filterBox}>
                                                                <span>{value as string}</span>
                                                                <img
                                                                    className="select-icon"
                                                                    style={{marginRight: "-20px", marginLeft: "15px"}}
                                                                    src={down_arrow}
                                                                    alt="sortVariant"
                                                                />
                                                            </Box>
                                                        );
                                                    }}
                                                    data-test-id="sbmt-quiz-dropdown"
                                                    
                                                >
                                                    <MenuItem className={classes.dropdown_ele} value="Last 4 weeks">{this.t("Last 4 weeks")}</MenuItem>
                                                    <MenuItem className={classes.dropdown_ele} value="This month">{this.t("This month")}</MenuItem>
                                                    <MenuItem className={classes.dropdown_ele} value="This quarter">{this.t("This quarter")}</MenuItem>
                                                    <MenuItem className={classes.dropdown_ele} value="This semester">{this.t("This semester")}</MenuItem>
                                                    <MenuItem className={classes.dropdown_ele} value="This year">{this.t("This year")}</MenuItem>
                                                </Select>
                                            </FormControl>

                                        </Box>
                                    </Box>
                                    <Box sx={{ width: "100%" }}>
                                        <Box className="google-card-row">
                                            <Card className={'followers-card'} data-test-id="fbmodal" onClick={() => {
                                              this.setState({isFbModal: true})
                                              }}>
                                                <CardContent className="content-card">
                                                    <Box className="right-content">
                                                        <Box className="quiz-title">
                                                            {this.t("Facebook")}
                                                        </Box>
                                                        <Box className="quiz-title" display="flex" justifyContent={'flex-start'} alignItems={"center"}>
                                                            <span>
                                                                120 &nbsp;
                                                            </span>

                                                            <img src={up} alt="emptydata" />
                                                            <Typography style={{ marginLeft: "2px", fontWeight: 400, fontSize: "12px", color: "#00BA88", fontFamily: "Expletus Sans" }} variant="caption">
                                                                10%
                                                            </Typography>

                                                        </Box>
                                                        <Box className="quiz-date">
                                                            {this.state.fbFollowerCount} {this.state.fbFollowerCount > 0 && this.t("more followers")}
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                            <Card className={'followers-card'} data-test-id="instamodal" onClick={() => {
                                              this.setState({isInstaModal: true})
                                              }} >
                                                <CardContent className="content-card">
                                                    <Box className="right-content">
                                                        <Box className="quiz-title">
                                                            {this.t("Instagram")}
                                                        </Box>
                                                        <Box className="quiz-title" display="flex" justifyContent={'flex-start'} alignItems={"center"}>
                                                            <span>
                                                                340 &nbsp;
                                                            </span>

                                                            <img src={down} alt="emptydata" />
                                                            <Typography style={{ marginLeft: "2px", fontWeight: 400, fontSize: "12px", color: "#C7263E", fontFamily: "Expletus Sans" }} variant="caption">
                                                                3%
                                                            </Typography>

                                                        </Box>
                                                        <Box className="quiz-date">
                                                            {this.state.instaFollowerCount} {this.state.instaFollowerCount > 0 && this.t("fewer followers")}
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box className="google-heading-wrapper">
                                    <Box className="heading-left">
                                        <Typography className="heading" variant="h1">
                                            {this.t("Last post performance")}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ width: "100%" }}>
                                        <Box className="google-card-row">
                                           {this.state.metaPageErrorResponse == 'No pages found' ? <>
                                            <>
                                            {this.currentlyNodataAvailable()}
                                            {this.currentlyNodataAvailable()}
                                            </>
                                           </> : 
                                            <>
                                                <>
                                                {!(this.state.lastDatePostFbError) ? <Card className={'followers-card'} >
                                                    <CardContent className="content-card">
                                                        <Box className="right-content" display="flex" justifyContent={"space-between"}>

                                                            <Box display="flex" justifyContent={"flex-start"} alignItems={'center'}>
                                                                <img src={wine} alt="emptydata" />
                                                                <Box>
                                                                    <Box className="quiz-title">
                                                                        {this.state.lastPostFbName}
                                                                    </Box>

                                                                    <Box className="quiz-date">
                                                                        {this.state.lastDatePostFb ?  formatDate(this.state.lastDatePostFb)  : `${this.t("July")} 5th at 11:05 AM` }
                                                                        
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-end'} >
                                                                <img src={facebook} alt="emptydata" height={24} width={24} data-test-id="fbicon" onClick={this.handleMetaFbClick} />

                                                                <Typography style={{ margin: "8px 0px", fontWeight: 400, fontSize: '16px', color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="caption">
                                                                    {this.t("Impressions")}
                                                                </Typography>

                                                                <Typography style={{ fontWeight: 500, fontSize: '26px', color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="h1">
                                                                    240
                                                                </Typography>

                                                            </Box>
                                                        </Box>
                                                    </CardContent>
                                                </Card> : <>{this.currentlyNodataAvailable()}</>}
                                                </>
                                                {!this.state.lastDatePostInstaErr ? <Card className={'followers-card'} >
                                                    <CardContent className="content-card">
                                                        <Box className="right-content" display="flex" justifyContent={"space-between"}>

                                                            <Box display="flex" justifyContent={"flex-start"} alignItems={'center'}>
                                                                <img src={wine} alt="emptydata" />
                                                                <Box>
                                                                    <Box className="quiz-title">
                                                                        {this.state.lastPostInstaName}
                                                                    </Box>

                                                                    <Box className="quiz-date">
                                                                    {this.state.lastDatePostInsta ?  formatDate(this.state.lastDatePostInsta)  : `${this.t("July")} 5th at 11:05 AM` }
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-end'}>
                                                                <img src={instagram} alt="emptydata" height={24} width={24} />

                                                                <Typography style={{ margin: "8px 0px", fontWeight: 400, fontSize: '16px', color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="caption">
                                                                    {this.t("Impressions")}
                                                                </Typography>

                                                                <Typography style={{ fontWeight: 500, fontSize: '26px', color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="h1">
                                                                    240
                                                                </Typography>

                                                            </Box>
                                                        </Box>
                                                    </CardContent>
                                                </Card> : <>{this.currentlyNodataAvailable()}</>}
                                            </>
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item sm={4} xs={4}>
                                <Box className="google-side-wrapper" >
                                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                                        <Typography style={{ fontWeight: 400, fontSize: "22px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="h1">
                                            {this.t("Last Email Campaign")}
                                        </Typography>

                                        <Link to={"/email-campaigns"} style={{ textDecoration: "none" }}>
                                            <Typography style={{ fontWeight: 500, fontSize: "14px", color: "#4BA3B7", fontFamily: "Expletus Sans", cursor: "pointer" }} variant="h3">
                                            {this.t("See All")}
                                            </Typography>
                                        </Link>
                                    </Box>

                                    <Box>
                                        <Typography style={{ marginBottom: 5, marginTop: 25, fontWeight: 400, fontSize: "16px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="subtitle1">
                                            {this.t("FR sommeller mass mailing")} 2022
                                        </Typography>

                                        <Typography style={{ fontWeight: 400, fontSize: "12px", color: "#94A3B8" }} variant="subtitle1">
                                            {this.t("Sep")} 12, 2022 11:19 AM
                                        </Typography>
                                    </Box>

                                    <Box className="marketing-row" display="flex" flexDirection="column">
                                        <Card className={'followers-card'} >
                                            <CardContent className="content-card">
                                                <Box display="flex" flexDirection="column" justifyContent="space-between" >
                                                    <Typography style={{ marginBottom: 10, fontWeight: 400, fontSize: "16px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="subtitle1">
                                                        {this.t("Sent to")}
                                                    </Typography>

                                                    <Typography style={{ fontWeight: 500, fontSize: "26px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="h1">
                                                        270
                                                    </Typography>

                                                </Box>
                                            </CardContent>
                                        </Card>
                                        <Card className={'followers-card'} >
                                            <CardContent className="content-card">
                                                <Box display="flex" flexDirection="column" justifyContent="space-between" >
                                                    <Typography style={{ marginBottom: 10, fontWeight: 400, fontSize: "16px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="subtitle1">
                                                        {this.t("Viewed By")}
                                                    </Typography>

                                                    <Typography style={{ fontWeight: 500, fontSize: "26px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="h1">
                                                        340
                                                    </Typography>

                                                </Box>
                                            </CardContent>
                                        </Card>
                                        <Card className={'followers-card'} >
                                            <CardContent className="content-card">
                                                <Box display="flex" flexDirection="column" justifyContent="space-between" >
                                                    <Typography style={{ marginBottom: 10, fontWeight: 400, fontSize: "16px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="subtitle1">
                                                        {this.t("Clickers")}
                                                    </Typography>

                                                    <Typography style={{ fontWeight: 500, fontSize: "26px", color: "#2b2b2b", fontFamily: "Expletus Sans" }} variant="h1">
                                                        15,652
                                                    </Typography>

                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
            {this.state.isFbModal &&
                <FbFollowersModal
                    classes={classes}
                    modalOpen={this.state.isFbModal}
                    followersCount={this.state.fbFollowerCount}
                    closeModal={this.closeFbModal}
                />
            }
            {this.state.isInstaModal &&
                <InstagramFollowersModal
                    classes={classes}
                    modalOpen={this.state.isInstaModal}
                    followersCount={this.state.instaFollowerCount}
                    closeModal={this.closeInstaModal}
                />
            }
        </Box>
  
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(customStyles, { withTheme: true })(MarketingDashboard);
// Customizable Area End
