// Customizable Area Start
import React from "react";
import { Box, InputAdornment } from "@material-ui/core";
import { attachment, close, sendIcon } from "../../blocks/LiveChat2/src/assets";
import CustomInputContent from "./CustomInput.web";
import { configJSON } from "../../blocks/LiveChat2/src/LiveChat2Controller.web";
import { FileDrop } from "react-file-drop";

interface CustomInputProps {
  filesUploaded: File[] | null;
  previews: string[];
  handleCloseAttachment: () => void;
  translateChat: any;
  chatTxtMessage: string;
  handleAllInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBrowse: () => void;
  formRef: React.RefObject<HTMLFormElement>;
  fileRef: React.RefObject<HTMLInputElement>;
  handleSubmitMessage: () => void;
  dragLeave?: () => void;
  dragEnter?: () => void;
  handleOnDrop: (files: FileList | null) => void;
  messageId: string;
  createExternalAndInternalChatMessgaeEvent?: () => void;
  chatType?: string;
  fileDrop: boolean,
  handleKeyDown: (event: React.KeyboardEvent<Element>) => void;
}

export const ChatImagePreview: React.FC<CustomInputProps> = (
  props: CustomInputProps
) => {
  const {
    previews,
    filesUploaded,
    handleCloseAttachment,
    formRef,
    fileRef,
    handleOnDrop,
    handleSubmitMessage,
    translateChat,
    dragEnter, 
    dragLeave,
    chatTxtMessage,
    handleAllInputChange,
    onBrowse,
    handleKeyDown,
    messageId,
    fileDrop,
    chatType,
    createExternalAndInternalChatMessgaeEvent,
  } = props;
  const file = filesUploaded && filesUploaded[0];
  const fileType = file && file.type.split("/")[0];
  return (
    <>
      {filesUploaded &&
        previews.map((preview: string, index: number) => {
          switch (fileType) {
            case "image":
              return (
                <Box key={preview} className="image-video-audio-preview">
                  <Box className="close-icon">
                    <img
                      src={close}
                      alt="close"
                      onClick={handleCloseAttachment}
                    />
                  </Box>
                  <img
                    src={preview}
                    alt="file preview"
                    className="preview-image"
                  />
                </Box>
              );
            case "audio":
              return (
                <Box key={preview} className="image-video-audio-preview">
                  <Box className="close-icon">
                    <img
                      src={close}
                      alt="close"
                      onClick={handleCloseAttachment}
                    />
                  </Box>
                  <audio
                    key={index}
                    src={preview}
                    controls
                    className="preview-image"
                  />
                </Box>
              );
            case "video":
              return (
                <Box key={preview} className="image-video-audio-preview">
                  <Box className="close-icon">
                    <img
                      src={close}
                      alt="close"
                      onClick={handleCloseAttachment}
                    />
                  </Box>
                  <video
                    key={index}
                    src={preview}
                    controls
                    className="preview-image"
                  />
                </Box>
              );
            default:
              return (
                <Box key={preview} className="image-video-audio-preview">
                  <Box className="close-icon">
                    <img
                      src={close}
                      alt="close"
                      onClick={handleCloseAttachment}
                    />
                  </Box>
                  <img
                    src={preview}
                    alt="No Preview"
                    className="preview-image"
                  />
                </Box>
              );
          }
        })}
      {messageId && (
           <FileDrop
           onDrop={handleOnDrop}
           onDragOver={dragEnter}
           onDragLeave={dragLeave}
         >
        <CustomInputContent
          type="text"
          placeholder={translateChat(`${configJSON.typeMessage}`)}
          value={chatTxtMessage}
          onChange={handleAllInputChange}
          onKeyDown={handleKeyDown}
          autoComplete="off"
          name="chatTxtMessage"
          data-test-id="chatTxtMessage"
          endAdornment={
            <>
           
                <Box
                  className={`fileUploadWrapper ${fileDrop && 'fileDropDrag'}
                     `}
                  onClick={onBrowse}
                  data-testid="onBrowse"
                >

                  <form ref={formRef}>
                    <input
                      name="upload-photo"
                      type="file"
                      onChange={(e) => {
                        if (e.preventDefault) {
                          e.preventDefault();
                        }
                        handleOnDrop(e.target.files);
                      }}
                      hidden
                      ref={fileRef}
                      data-testid="fileInput"
                    />
                  </form>
                  <Box className="file-upload-block" >
                    <Box className="file-heading" >
                    </Box>
                      <Box component="span" className="browse-link">
                        <img
                        className="attachment-img"
                        src={attachment}
                        alt="attachmenticon"
                        />
                      </Box>
                  </Box>
                </Box>

              <InputAdornment
                position="end"
                data-test-id={`handleSubmitMessage`}
                onClick={
                  chatType === "etoh_shop_support"
                    ? handleSubmitMessage
                    : createExternalAndInternalChatMessgaeEvent
                }
              >
                <img className="send-img" src={sendIcon} alt="attachmenticon" />
              </InputAdornment>
            </>
          }
        />
                      </FileDrop>
       
      )}
    </>
  );
};

export default ChatImagePreview;
// Customizable Area End
