import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../../framework/src/Message";
// Customizable Area Start
import React from "react";
import { DateObject } from "react-multi-date-picker";
import i18n from "../../../../web/src/utilities/i18n";
export const configJSON = require("../config.js");
import moment from "moment";
import { normalizeDate } from "../../../../components/src/helpers/Utilities";
import {deleteIcon,copyIcon, copyDuplicate, accountPlusBlack, backCheckIcon} from "../assets";
import { convertToFiles, createCommonToastNotification, getCurrentTime } from "../../../../components/src/ReusableFunctions";
import { Project ,Task } from "../../../../components/src/ReusableEnums";
const baseConfig = require("../../../../framework/src/config");
import { toast } from "react-toastify";
import { CardMedia } from "@material-ui/core";
export const warningIcon = require("../../assets/warning-red.png");
import { CostData } from "../../../../components/src/ReusableEnums";
// Customizable Area End



export interface Props{
  // Customizable Area Start
  classes: any;
  navigation?: any;
  id?: string;
  isOpen: boolean;
  projectClose: () => void;
  projectId: number;
  tasklistPopoverOpen: (e: any,popoverRef:React.RefObject<any>) => void;
  taskPopoverOpen: (e: any, id: number,popoverRef:React.RefObject<any>) => void;
  createToastNotification: (toastMesssage: string, toastIcon?: string) => void;
  fetchAllProjects: () => void;
  updateData?:() => void;
  popoverHandleClose:()=>void;
  projectList:any[];
  showCreateProject?:boolean;
  editProjectOpen?:boolean;
  deleteCallback?:() => void;
  showTimeSheet?:boolean;
  closeTimeSheet?:()=>void;
  taskListId?: number;
  getTasks?:()=>void;
  // Customizable Area End
}


interface S {
  // Customizable Area Start
  anchorEl: any;
  currentProject: any;
  viewValueOfTabs: number;
  projectTaskTabsValue: number;
  projectTaskList: any[];
  tasklistTaskChecked: any[];
  startDate: string;
  endDate: string;
  error: {
    title: string;
    startDate: string;
    category: string;
    taskListName: string;
    taskListProject: string;
    due_date: string;
  };
  title: string;
  category: any;
  categoryList: any[];
  description: string;
  isDeletingCategory: boolean;
  deleteCategoryId: string;
  assignees: any[];
  isActiveProjectCreated: boolean;
  isActiveChangeProjectLead: boolean;
  assigneeList: any[];
  sortByAssignee: string;
  assigneeSearchText: string;
  popoverAnchorEl:any;
  isEditing:boolean;
  isDeletingProject: boolean;
  createProjectEl: any;  
  draftProjects: any[];
  currentDraftId: number;
  updateProjectId:number;  
  isGetTimesheet: boolean;
  isActiveTimesheetChooseAssignees: boolean;  
  logDates: DateObject[];
  isTimesheetGenerating:boolean;
  isActiveFilter: boolean;
  timeSheetProject:number;
  logAssignees: any[];
  activityLogList: any[];
  projectStatus:number;
  isActiveAllAssignee: boolean;
  fileDropEvent: boolean;
  filesUploaded: any;
  attachmentRemove:boolean;
  attachmentID:string;
  isUploading:boolean;
  openUseExisting:boolean;
  chooseTemplateChecked:number;
  projectList:Project[];
  searchQuery:string;
  useExistingProjectId:number;
  filteredProjects:Project[];
  isSearching:boolean;
  sortQuery:string;
  isSorting:boolean;
  sortedProjects:Project[];
  taskTimers:{
    [taskId: string]: boolean;
  };
  taskModalOpen: boolean;
  taskID:any;
  detailsOfTaskCost:CostData;
  projectTask:any;
  statusUpdate:number;
  currId:number;
  showAddExpendituresBtn: boolean;
  popoveAnchorEl:any;
  due_date:string;
  deadline_date:string;
  labour_cost: number;
  hours: string;
  minutes: string;
  seconds: string;
  taskHours: string;
  priority: number;
  status: number;
  updateTaskId: number;
  expenditures:any;
  projectId:number;
  productsSelectedId:any;
  taskListObject: {
    id: number;
    project: number;
    name: string;
  };
  taskSelectedAssignees: any[];
  isActiveAssigneeChanges: boolean;
  timerStandAnchorEl: any;
  tasksInDraft: any[];
  showHoursModal: boolean;
  showAddProductsAssets:boolean;
  showAssetsOfInventory:boolean;
  addDealShow:boolean;
  dealsSelected:string;
  viewProductsSelected:boolean;
  selectedProductsData:any;
  productNameAndCount:any;
  fileDrop:boolean;
  attachmentTaskRemove:boolean;
  isUpdating:boolean;
  createTaskanchorEl: any;
  singleDataDeal: any;
  teams: any[];
  selectedTeamList:string[];
  assigneesTabValue: number;
  sortAssignee: string;
  deleteTaskId: number;
  isDeletingTask:boolean;
  isMoveTaskProject:boolean;
  moveTaskChecked: any;
  taskTopDetailsAnchorEl: any;
  changePriorityViewEl: any;
  selectedTasks: number[];
  dataOfTaskList: any[];
  movedTasklist:string;
  allDealsData:any;
  resultsOfSearch:any;
  valueOfSeach: string;
  sortBySelectedVal: string;
  filterStageVal:string;
  contact:string;
  dealOwner:string;
  dealsHasTask: boolean;
  hasAttachment: boolean;
  hasAssociatedProduct: boolean;
  fromAmount:string;
  toAmount:string;
  selectedDate:string;
  closeDate:string;
  isFiltersOnDeal:boolean;
  newStatus: number | null;
  addNote: string;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ProjectViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userToken: any;
  userSessionData: any;
  getProjectDetailApiID: string = "";
  createDuplicateProject:string = "";
  projectTasklistViewApiID: string = "";
  getCategoryListApiID:string = "";
  deleteCategoryApiID:string = ""  
  editCategoryApiID: string = "";
  createCategoryApiID: string = "";
  projectCreateApiId: string = "";
  editProjectApiID: string = "";
  memberListApiID: string = "";  
  activityLogFilterApiID: string = "";
  projectActivityLogApiID: string = "";
  projectStatusApiID:string="";  
  showDialogCategory: any = null;
  setErrorCategory: any = null;
  currentCategory: { id: number; category: string } = { id: 0, category: "" };
  deleteProjectApiID:string = "";  
  popoverRef: React.RefObject<any>;
  draftProjectId: number = 1;
  fileRef: React.RefObject<HTMLInputElement>;
  formRef: React.RefObject<HTMLFormElement>;
  attachmentsReqestApiId: string = "";
  projectListApiRequestID: string = "";
  updateTaskProgressAPICallId:string ="";
  getTaskId:string="";
  getTaskCostDetailApiID:string="";
  userRole:any;
  updateTaskStartAPICallId: string = "";
  updateTaskStopAPICallId: string = "";
  getAllProductsFromInventoryApiID: string = "";
  draftTaskId: number = 1;
  setErrorTaskList: any;
  showDialogTaskList: any;
  taskListCreateApiId: string = "";
  tasklistAddApiId: string = "";
  getAllDealsRequestId: string = "";
  createTaskApiID: string = "";
  editTaskApiID: string = "";
  getSingleDealRequestId: string = "";
  fetchTeamApiCallId: string = "";
  removeAssigneesApiID: string = "";
  addAssigneeApiID: string = "";
  createDuplicateTaskApiID: string = "";
  getTaskListRequestApiID: string = "";
  changePriorityApiID: string = "";
  moveTasklistProjectApiID: string = "";
  searchDealAPICallId: string = "";
  taskDeleteApiID: string = "";
  changeStatusApiID: string = "";
  apiCallsCount: number = 1;
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    // Customizable Area Start
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);    
    this.popoverRef = React.createRef();
    this.fileRef = React.createRef();
    this.formRef = React.createRef();
    this.userRole = this.userToken.data.attributes.job_type;
    // Customizable Area End


    this.state = {
    // Customizable Area Start
    anchorEl: null,
    currentProject: null,
    viewValueOfTabs: 0,
    projectTaskTabsValue: 0,
    projectTaskList: [],
    tasklistTaskChecked: [0],
    error: {
      title: "",
      startDate: "",
      category: "",
      taskListName: "",
      taskListProject: "",
      due_date: "",
    },
    title: "",
    description: "",
    category: null,
    startDate: "",
    endDate: "",
    categoryList: [],
    isDeletingCategory: false,
    deleteCategoryId: "",
    assignees: [],
    isActiveProjectCreated: false,
    isActiveChangeProjectLead: false,
    assigneeList: [],
    sortByAssignee: "",
    assigneeSearchText: "",
    isEditing:false,
    popoverAnchorEl:null,
    isDeletingProject:false,
    createProjectEl: null,    
    draftProjects: [],
    currentDraftId: 0,
    updateProjectId: 0,
    isGetTimesheet: false,
    isActiveTimesheetChooseAssignees:false,    
    logDates: [],
    isTimesheetGenerating:false,
    isActiveFilter:false,
    timeSheetProject:0,
    logAssignees: [],
    activityLogList: [],
    projectStatus:0,
    isActiveAllAssignee: false,
    fileDropEvent: false,
    filesUploaded: [],
    attachmentRemove:false,
    attachmentID:"",
    isUploading:false,
    openUseExisting:false,
    chooseTemplateChecked: 0,
    projectList:[],
    searchQuery:"",
    useExistingProjectId:0,
    filteredProjects:[],
    isSearching:false,
    sortQuery:"",
    isSorting:false,
    sortedProjects:[],   
    taskTimers:{},
    taskModalOpen:false,
    taskID:[],
    detailsOfTaskCost:{  
      total_cost: "",
      inventory_costs: {},
      labour_cost: "",
      expenditures: "0.0",
      hours: "",
      actual_hours: 0,
      employee_count:0,
    },
    projectTask:null,
    statusUpdate:0,
    currId:0,
    showAddExpendituresBtn:false,
    popoveAnchorEl: null,
    due_date:"",
    deadline_date:"",
    labour_cost:0,
    hours: "",
    minutes: "",
    seconds: "",
    taskHours: "",
    priority:0,
    status: 0,
    updateTaskId: 0,
    expenditures:"",
    projectId:0,
    productsSelectedId:[],
    taskListObject: {
      id: 0,
      name: "",
      project: 0,
    },
    taskSelectedAssignees: [],
    isActiveAssigneeChanges: false,
    timerStandAnchorEl: null,
    tasksInDraft: [],
    showHoursModal: false,
    showAddProductsAssets:false,
    showAssetsOfInventory:false,
    addDealShow:false,
    dealsSelected:"",
    viewProductsSelected:false,
    selectedProductsData:[],
    productNameAndCount:[],
    fileDrop:false,
    attachmentTaskRemove:false,
    isUpdating:false,
    createTaskanchorEl: null,
    singleDataDeal:[],
    teams: [],
    selectedTeamList:[],
    assigneesTabValue: 0,
    sortAssignee: "",
    deleteTaskId: 0,
    isDeletingTask:false,
    isMoveTaskProject:false,
    moveTaskChecked: 0,
    taskTopDetailsAnchorEl: null,
    changePriorityViewEl: null,
    selectedTasks: [],
    dataOfTaskList: [],
    movedTasklist:"",
    allDealsData:[],
    resultsOfSearch:[],
    valueOfSeach: "",
    sortBySelectedVal: "",
    filterStageVal:"",
    contact:"",
    dealOwner:"",
    hasAttachment: false,
    hasAssociatedProduct: false,
    dealsHasTask: false,
    fromAmount:"",
    toAmount:"",
    closeDate:"",
    selectedDate:"",
    isFiltersOnDeal:false,
    newStatus: null,
    addNote: "",
    // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidUpdate(prevProps: Props, prevState: S) {
    this.checkCategoryUpdate(prevState);
    
    if (prevProps.isOpen != this.props.isOpen && this.props.projectId) {
      if(this.props.isOpen)
      this.getProjectDetails(this.props.projectId);
      this.TasklistGetofProject(this.props.projectId);
      this.getAssigneeList();
      this.getCategory();
      this.setState({timeSheetProject:this.props.projectId})
    }
    
    if(prevProps.editProjectOpen != this.props.editProjectOpen && this.props.editProjectOpen){
      this.getCategory();
      this.getProjectDetails(this.props.projectId)
      this.getAssigneeList();
      this.TasklistGetofProject(this.props.projectId);
      
    }

    if(prevProps.showTimeSheet != this.props.showTimeSheet  && this.props.showTimeSheet){
      this.getTimesheetModalOpen({preventDefault:()=>{}})
      
    }
    if (
      prevState.projectTask !== this.state.projectTask &&
      this.state.projectTask !== null
    ) {
      //this.getActivityLog(this.state.projectTask.id);
    if (this.state.projectTask !== null) {
        const result: string[] = this.state.projectTask.attributes.assignees.map(
          (item: any) => String(item.id)
        );
        this.setState({ taskSelectedAssignees: result });
      }
    }
  }
  async componentDidMount(): Promise<void> {
    const lang = localStorage.getItem("lang") ?? "en";
    await (i18n as any).changeLanguage(lang);
    this.setState({ filteredProjects: this.state.projectList });
    this.getTeams();
  }

  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (!responseJson.errors) {
          switch (apiRequestCallId) {
            case this.projectListApiRequestID:
              this.setState({
                projectList: responseJson.data,
            });
            break;
            case this.projectTasklistViewApiID:
              this.setState({
                projectTaskList: responseJson.data,
              });
            break;                         
            case this.memberListApiID:
              this.setState({ assigneeList: responseJson.members.data });
              break;
            case this.attachmentsReqestApiId:
                 this.getProjectDetails(this.props.projectId);
                 this.setState({filesUploaded:[]})
              break;   
              case this.updateTaskProgressAPICallId:
                this.TasklistGetofProject(this.props.projectId);
                this.getProjectDetails(this.props.projectId);
             break;
             case this.getAllDealsRequestId:
              this.setState({ allDealsData: responseJson.data});                  
              break; 
              case this.createTaskApiID:
              if (this.state.currentDraftId !== 0) {
                this.setState({
                  tasksInDraft: this.state.tasksInDraft.filter(
                    (x) => x.id !== this.state.currentDraftId
                  ),
                  currentDraftId: 0,
                });
              }
              this.setState({filesUploaded:[]})
              this.popoverHandleClose();
              this.props.createToastNotification(this.t("Task created successfully"));
              this.refreshData();
              this.setState({filesUploaded:[]});
              break;
             case this.editTaskApiID:
              this.props.createToastNotification(responseJson.message);
              this.getAllProjects();
              this.props.getTasks?.();
              this.popoverHandleClose();
              this.refreshData();
              this.setState({filesUploaded:[]})
              break;
             case this.taskListCreateApiId:
              this.props.createToastNotification(responseJson.message);
              break;
             case this.taskDeleteApiID:
              this.props.createToastNotification(responseJson.message);
              this.setState({
                selectedTasks: [],
                isDeletingTask: false,
                projectTask: null,
                deleteTaskId: 0,
              });
              this.handleTaskModalClose();
              this.props.getTasks?.()
              this.getAllProjects();
              this.refreshData()
              break;
              case this.changeStatusApiID:
              createCommonToastNotification(this.t(configJSON.taskCompeletd),backCheckIcon);
              setTimeout(() => {
              if (this.state.projectTask !== null) {
                this.getTaskDetails(this.state.projectTask.id);
              }
              },5000)
              break;
             case this.searchDealAPICallId:
                this.setState({resultsOfSearch : responseJson.data}) 
              break;
             case this.moveTasklistProjectApiID:
                this.moveTaskProjectResponse(responseJson);
                this.getAllProjects();
              break;    
             case this.getTaskListRequestApiID:
               this.setState({dataOfTaskList:responseJson.data})
              break;
             case this.changePriorityApiID:
              if (this.state.projectTask !== null) {
                this.getTaskDetails(this.state.projectTask.id);
              }
              break;
             case this.createDuplicateTaskApiID:
              this.props.createToastNotification(this.t(configJSON.duplicateSuccesssMsg),copyDuplicate);
              this.setState({ taskModalOpen: false });
              this.refreshData()
              this.getAllProjects();
            break;
             case this.getTaskId:
              this.setState({
                projectTask: responseJson.data,
              });
              break;  
             case this.getTaskCostDetailApiID: 
                this.setState({detailsOfTaskCost:responseJson})                 
             break;   
             case this.getSingleDealRequestId: 
                this.setState({ singleDataDeal: responseJson.data});                
             break;   
             case this.fetchTeamApiCallId: 
                this.setState({ teams: responseJson.data});                
             break;  
             case this.addAssigneeApiID:
             case this.removeAssigneesApiID:
              if(responseJson.message){
                this.props.createToastNotification(this.t(configJSON.taskAssign), accountPlusBlack);
              }
              if (this.apiCallsCount === 1) {
                if (this.state.taskModalOpen) {
                  this.getTaskDetails(this.state.projectTask.id);
                } else {
                  this.setState({ projectTask: null });
                }
              }          
              this.apiCallsCount = this.apiCallsCount - 1;
            break; 
            default:
              this.categoryApiResponse(apiRequestCallId,responseJson)
              this.projectApiResponse(apiRequestCallId,responseJson)
            }          
        } else {
          this.checkApiError(responseJson, apiRequestCallId);
        }
      }
    }
    // Customizable Area End
   }


  // Customizable Area Start
  dragEnterEvent = () => {
    this.setState({
      fileDropEvent: true,
    });
  };
  dragLeaveEvent = () => {
    this.setState({
      fileDropEvent: false,
    });
  };
  onBrowseEvent = () => {
    if (this.fileRef.current !== null) {
      this.fileRef.current.click();     
    }
  };

  handleUpload =()=>{
    this.
    setState({isUploading:true})
  }

  startTaskTimerAPI = (taskId:string,actionType:string) => {
    const timestamp = getCurrentTime();   
    
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
      const body = {
        "data":{
           "task_id": taskId,
           "status_updated_at": timestamp,
           "action_type": actionType       
       }
    } 
    
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateTaskProgressAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateTaskProgress
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    this.setState((prevState) => ({
      taskTimers: {
        ...prevState.taskTimers,
        [taskId]: actionType === "started",
      }
    }));  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleOnDropEvent = (fileEvent: React.ChangeEvent<HTMLInputElement>) => { 
    this.setState({attachmentRemove:false,attachmentID:""});
    const uplodedFiles = fileEvent.target.files;
    if (uplodedFiles) {
      const newFilesArray = Array.from(uplodedFiles);
  
      this.setState((prevState) => {
        const allUploadedFiles = [...prevState.filesUploaded, ...newFilesArray];
        const uniqueFilesUplods = Array.from(
          new Map(
            allUploadedFiles.map((fileItme) => [`${fileItme.name}-${fileItme.lastModified}`, fileItme])
          ).values()
        );  
        return { filesUploaded: uniqueFilesUplods };
      }); 
      if(this.state.isUploading){
        this.addAttachmentsForProject(newFilesArray);       
      }
    }   
  };

  removeFileUPloadEvent = (indexNumber: any) => {
    const updatedFilesArray = [
      ...this.state.filesUploaded.slice(0, indexNumber),
      ...this.state.filesUploaded.slice(indexNumber + 1),
    ];
    this.setState({ filesUploaded: updatedFilesArray });
  };

  setProjectStatus=(status:any)=>{
    let statusId=0
    status=status?status.toLowerCase():""
    switch(status){
      case "on track":
        statusId=1
        break;
        case "off track":
        statusId=2
        break;
        case "completed":
        statusId=3
        break;
    }
    this.setState({projectStatus:statusId})
  }

  projectApiResponse=(apiRequestCallId:any,responseJson:any) =>{
    const updateData = () => {
      if (this.props.updateData) {
        this.props.updateData();
      }
    }
    switch(apiRequestCallId)
    {
      case this.getProjectDetailApiID:

        this.setState({
          currentProject: responseJson.data,
        },()=>{
          if(this.props.editProjectOpen){
            this.editProjectOpen()
          }
        });
        this.setProjectStatus(responseJson.data.attributes.status)
        break;
      case this.createDuplicateProject:
        this.props.createToastNotification(this.t("Project Duplicated."),copyIcon);
        this.props.projectClose();
        this.props.fetchAllProjects();
        updateData();
        break;
      case this.projectCreateApiId:
        this.projectCreated();
        this.props.fetchAllProjects();
        updateData();
        this.setState({filesUploaded:[]})
        break;
      case this.deleteProjectApiID:
        this.props.createToastNotification("Project deleted.",deleteIcon);
        this.props.projectClose();
        this.projectDeleteModalClose();
        if (this.props.deleteCallback) {
          this.props.deleteCallback();
        }
        this.props.fetchAllProjects();
        updateData();
        break;
      case this.editProjectApiID:
        this.projectFromDraft();
        this.props.createToastNotification(responseJson.message);
        this.props.fetchAllProjects();
        this.popoverHandleClose();
        updateData();
        this.setState({filesUploaded:[]})
        break;
    
      case this.projectActivityLogApiID:
        this.setState({ activityLogList: responseJson.data });
        break;
      case this.projectStatusApiID:
        this.props.fetchAllProjects();
        this.getProjectDetails(this.props.projectId);
        updateData();
        break;     
    }
  }

  categoryApiResponse=(apiRequestCallId:any,responseJson:any) =>{
    switch(apiRequestCallId)
    {
      case this.getCategoryListApiID:
        this.setState({
          categoryList: responseJson.data,
        });
        break;
      case this.createCategoryApiID:
        this.createCategorySuccess(responseJson);
        break;
      case this.editCategoryApiID:
        this.updateCategorySuccess(responseJson);
        break;
    }
  }

  projectFromDraft = () => {
    if (this.state.currentDraftId !== 0) {
      this.setState({
        draftProjects: this.state.draftProjects.filter(
          (x) => x.id !== this.state.currentDraftId
        ),
        currentDraftId: 0,
      });
    }
  }

  openDraftProject= (event: any, id: number) => {
    const draftProject:any = this.state.draftProjects.find((x) => x.id == id);
    this.setState({
      title: draftProject.title,
      startDate: draftProject.startDate,
      endDate: draftProject.endDate,
      description: draftProject.description,
      assignees: draftProject.assignees,
      popoverAnchorEl: event.currentTarget,
      currentDraftId: id,
      category: draftProject.category,
      updateProjectId:draftProject.projectId,
      isEditing:draftProject.projectId>0
    });
  };

  deleteDraftProject = (id: number) => {
    const draftProject = this.state.draftProjects?.filter((x) => x.id !== id);
    this.setState({ draftProjects: draftProject });
  };
  saveAsDraftProject = () => {
    let draftProjects = this.state.draftProjects;
    if (this.state.currentDraftId !== 0) {
      let draftProject = draftProjects.find(
        (x: any) => x.id == this.state.currentDraftId
      );
      draftProject.title = this.state.title;
      draftProject.startDate = this.state.startDate;
      draftProject.endDate = this.state.endDate;
      draftProject.description = this.state.description;
      draftProject.assignees = this.state.assignees;
      draftProject.category = this.state.category;
    } else {
      draftProjects = [
        ...draftProjects,
        {
          id: this.draftProjectId,
          projectId:this.state.isEditing?this.state.currentProject.id:0,
          title: this.state.title,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          description: this.state.description,
          assignees: this.state.assignees,
          category: this.state.category,
        },
      ];
      this.draftProjectId = this.draftProjectId + 1;
      this.setState({
        draftProjects: draftProjects,
      });
    }   
    this.popoverHandleClose();
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  handleMenuOpen = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };
  setTabsViewValue = (obj: any, val: any) => {
    this.setState({
      viewValueOfTabs: val,
    });
  };
  setProjectTaskTabsValue = (obj: any, val: any) => {
    this.setState({
      projectTaskTabsValue: val,
    });
  };

  getProjectDetails = (projectId: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProjectDetailApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectShowEndPoint + `?id=${projectId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  TasklistGetofProject = (projectId: number) => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectTaskListViewEndPoint + `?project_id=${projectId}`
    );
    this.projectTasklistViewApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  tasklistOpenPopover = (e: any) => {
    e.preventDefault();
    this.props.tasklistPopoverOpen(e,this.popoverRef);
    this.setState({
      anchorEl: null,
    },this.props.projectClose);
  };

  taskOpenPopover = (e: any) => {
    e.preventDefault();
    this.setState({
      anchorEl: null,
    },this.props.projectClose);
    this.props.taskPopoverOpen(e, this.props.projectId,this.popoverRef);
  };
  
  projectDelete = () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    
    this.deleteProjectApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectDeleteEndPoint + `?id=${this.props.projectId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   
  };

  projectDeleteModalClose = () => {
    this.setState({
      isDeletingProject: false,
    });
  };

  deleteProjectHandler = () => {
    this.setState({
      isDeletingProject: true,
      anchorEl: null,
    });
  };

  duplicateProject = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createDuplicateProject = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.duplicateProject + `?id=${this.state.openUseExisting?this.state.useExistingProjectId:this.state.currentProject.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleTaskListTasks = (value: number) => () => {
    const currentIndex = this.state.tasklistTaskChecked.indexOf(value);
    const newtasklistTaskChecked: any = [...this.state.tasklistTaskChecked];

    if (currentIndex === -1) {
      newtasklistTaskChecked.push(value);
    } else {
      newtasklistTaskChecked.splice(currentIndex, 1);
    }

    this.setState({ tasklistTaskChecked: newtasklistTaskChecked });
  };

  getCategory = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryListEndPoint
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCategoryListApiID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
    
   
  };
  
  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as keyof S;
    const value = event.target.value;
    this.setState({ [name]: value } as unknown as Pick<S, keyof S>);
    let error = this.state.error;
    if (name=="title") {
        error.title = "";
    }
    this.setState({ error: error });
  };

  
  categoryChangeHandler = (e: any, newValue: any) => {
    this.setState({
      category: newValue,
      error:{
        ...this.state.error,
        category:"",
      }
    });
  };

  deleteCategory = (data: any) => {
    this.setState({ isDeletingCategory: true, deleteCategoryId: data.id });
  };
  deleteCategoryModalClose = () => {
    this.setState({
      isDeletingCategory: false,
    });
  };
  deleteCategoryApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteCategoryApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryListEndPoint + `?id=${this.state.deleteCategoryId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addCategory = (data: any, setError?: any, setShowDialog?: any) => {
    this.setErrorCategory = setError;
    this.showDialogCategory = setShowDialog;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   
    if (data.id != null) {
      this.editCategoryApiID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.editCategoryEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            id: data.id,
            category: data.title,
          },
        })
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodPATCH
      );
    } else {
      this.createCategoryApiID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCategoryEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            category: [data.title],
          },
        })
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodPOST
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changeStartDate = (date: any) => {
    this.setState({
      startDate: date?.isValid ? date.format("YYYY-MM-DD") : "",
      error: { ...this.state.error, startDate: "" },
    });

  };
  changeEndDate = (date: any) => {
    this.setState({
      endDate: date?.isValid ? date.format("YYYY-MM-DD") : "",
    });
  };
  checkApiError = (responseJson: any, apiRequestCallId: string) => {
    if (apiRequestCallId == this.editCategoryApiID) {
        const msg: string = responseJson.errors[0].message;
        if (msg.includes("already exists")) {
          this.setErrorCategory("Category already exists");
        } else {
          this.setErrorCategory(msg);
        }
    }
  };
  checkCategoryUpdate = (prevState: S) => {
    if (prevState.categoryList !== this.state.categoryList) {
      if (this.currentCategory.id !== 0) {
        const curCategory = this.state.categoryList.find(
          (x) => x.attributes.id == this.currentCategory.id
        );

        this.setState({
          category: {
            id: curCategory.id,
            title: curCategory.attributes.category,
          },
        });
      } else if (this.currentCategory.category != "") {
        const curCategory = this.state.categoryList.find(
          (x) => x.attributes.category == this.currentCategory.category
        );
        this.setState({
          category: {
            id: curCategory.id,
            title: curCategory.attributes.category,
          },
        });
      }
      this.currentCategory = { id: 0, category: "" };
    }
  };
  createCategorySuccess = (responseJson: any) => {
    if (responseJson.failed_categories) {
      const msg: string = responseJson.failed_categories;
      if (msg.includes("already exists")) {
        this.setErrorCategory("Category already exists");
      } else {
        this.setErrorCategory(msg);
      }
    } else {
      this.currentCategory.category = responseJson.created_categories[0];
      this.showDialogCategory(false);
      this.getCategory();
    }
  };
  updateCategorySuccess = (responseJson: any) => {
    this.currentCategory.id = responseJson.data.attributes.id;
    this.currentCategory.category = responseJson.data.attributes.category;
    this.showDialogCategory(false);
    this.getCategory();
  };

  createProject = async () => {
    let error = this.state.error;
    let hasError = false;
    if (this.state.title.trim() === "") {
      error.title = this.t("Please enter title");
      this.setState({ error: error });
      hasError = true;
    }
    // if (this.state.startDate.trim() === "") {
    //   error.startDate = this.t("Please enter start date");
    //   this.setState({ error: error });
    //   hasError = true;
    // }
    // if (!this.state.category) {
    //   error.category = this.t("Please select Category");
    //   this.setState({ error: error });
    //   hasError = true;
    // }
    if (hasError) {
      return;
    }

    let assignees = [...this.state.assignees];

    if (assignees.length == 0) {
      assignees = [''];
    }
    const formdata = new FormData();
    formdata.append("data[title]", this.state.title);
    formdata.append("data[start_date]", this.state.startDate);
    formdata.append("data[end_date]",this.state.endDate);
    formdata.append("data[description]", this.state.description);
    formdata.append("data[project_lead]", assignees[0]);
    let filesArray;
   
    if (this.state.isEditing) {
      filesArray = await convertToFiles(this.state.filesUploaded);
    } else {
      filesArray = this.state.filesUploaded;
    }

    if(filesArray){
      for (const file of filesArray) {
        formdata.append("data[attachments][]", file);
      }
    }

    if(this.state.isEditing){
      formdata.append("data[id]", this.state.updateProjectId.toString())
    }

    if (this.state.isEditing) {
      this.editProject(formdata);
      return;
    }
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectCreateApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectListEndPoint+`?category_id=${this.state.category.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // JSON.stringify({ data: body })
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  attachmentRemove = (attachmentID:string) =>{
   this.setState({attachmentRemove:true,attachmentID:attachmentID,filesUploaded:[]},
    () => this.addAttachmentsForProject([])
   )
  }

  addAttachmentsForProject = (attachments:any) => {
    const formdata = new FormData();
      formdata.append("attachments[0][remove]", this.state.attachmentRemove.toString());
      formdata.append("id", this.props.projectId.toString());
      formdata.append("attachments[0][id]", this.state.attachmentID )
      if(attachments){
         attachments.forEach((file:any, index:number) => {
           const key = `attachments[${index}][data]`;
           formdata.append(key, file);
         });
      } 

    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.attachmentsReqestApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectAttachments
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  projectCreatedModalClose = (e: any) => {
    e.preventDefault();
    this.setState({ isActiveProjectCreated: false });
  };
  projectLeadChangeModalClose = (e: any) => {
    e.preventDefault();
    this.setState({ isActiveChangeProjectLead: false });
  };
  projectLeadHandler = (e: any, id: number = 0) => {
    e.preventDefault();
    this.setState({ isActiveChangeProjectLead: true });
  };

  clearProjectModal = () => {
    this.setState({
      title: "",
      startDate: "",
      endDate: "",
      description: "",
      category: null,      
      currentDraftId: 0,
      assignees: [],
      error: {
        title: "",
        startDate: "",
        category: "",
        taskListName: "",
        taskListProject: "",
        due_date: "",
      },
    
    });
  };

  popoverHandleClose = () => {
    this.clearProjectModal();
    this.setState({
      popoverAnchorEl: null,
      isEditing: false,
      filesUploaded:[],
      popoveAnchorEl: null,
    });
    this.props.popoverHandleClose();
  };

  projectCreated = () => {
    this.setState({
      isActiveProjectCreated: true,
    });
    this.popoverHandleClose();
  };

  removeProjectLead = () => {
    this.setState({ assignees: [] });
  };



  displayAssigneeOptions = () => {
    const assigneeNames = this.state.assigneeList.map(
      (item: {
        id: number;
        attributes: {
          team: any[];
          last_name: string;
          email: string;
          first_name: string;
          deactivated:boolean;
        };
      }) => ({
        title: `${item.attributes.first_name} ${item.attributes.last_name}`,
        id: item.id,
        email: item.attributes.email,
        initials: `${item?.attributes?.first_name
          ?.charAt(0)
          ?.toUpperCase()}${item?.attributes?.last_name?.charAt(0)?.toUpperCase()}`,
        team: item.attributes.team
          ? item.attributes.team.map((team) => team.title)
          : [],
        deactivated:item.attributes.deactivated
      })
    );
    return assigneeNames.filter((obj: { title: string }) =>
      this.containsText(obj.title, this.state.assigneeSearchText)
    );
  };

  getAssigneeNames=(assigneeList:any)=>{
    return assigneeList.filter((x:any)=>x.first_name!=null).map((assignee:any)=>
      { 
        var item = this.state.assigneeList.find(x=>x.id==assignee.id);
      return ({
      ...assignee,
      title: `${assignee.first_name} ${assignee.last_name}`,
      id: assignee.id,
      initials: `${assignee?.first_name?.charAt(0)
        ?.toUpperCase()}${assignee?.last_name?.charAt(0)?.toUpperCase()}`,
        team: item?.attributes.team
          ? item.attributes.team.map((team:any) => team.title)
          : [],
    })})
  }

  containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  changeProjectLead = (assigneeId: number, deactivated:boolean) => {
    let selectedAssignee = this.state.assignees;
    if (selectedAssignee.includes(assigneeId)) {
      selectedAssignee = [];
    } else {
      if(!deactivated)
      selectedAssignee = [assigneeId];
      else {
        toast.error("Deactivated User Can't be select", {
        position: toast.POSITION.TOP_CENTER,
        icon: () => (
          <CardMedia component="img" src={warningIcon} alt="emptydata" />
        ),
      });}
    }
    this.setState({
      assignees: selectedAssignee,
    });
  };

  sortByAssigneeHandler = (e: any) => {
    this.setState({ sortByAssignee: e.target.value });
  };
  sortAssignees = (dataToSort: any) => {

    const { sortByAssignee } = this.state;
    
    if (sortByAssignee != "") {
      let rightSort: number;
      let leftSort: number;
      rightSort = sortByAssignee == "Z-A" ? -1 : 1;
      leftSort = sortByAssignee == "Z-A" ? 1 : -1;
      dataToSort = dataToSort.sort((assignee1: any, assignee2: any) => {        
        if (assignee1.title.toLowerCase() > assignee2.title.toLowerCase())
          return rightSort;
        if (assignee1.title.toLowerCase() < assignee2.title.toLowerCase())
          return leftSort;
        return 0;
      });
    }

    return dataToSort;
  };
  editProject = (formData: any) => {
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editProjectApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectUpdateEndPoint+`?category_id=${this.state.category.id}` 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  editProjectOpen = () => {
    let curProject=this.state.currentProject;
    const assigneeValue: string[] = curProject.attributes.project_lead.map(
      (item: any) => String(item.id)
    );

    this.setState({
      popoverAnchorEl: this.popoverRef.current,
      viewValueOfTabs: 0,
      isEditing: true,
      title: curProject.attributes.title,
      description: curProject.attributes.description,
      startDate: curProject.attributes.start_date,
      endDate: curProject.attributes.end_date,
      category: {id:curProject.attributes.category?.id,title:curProject.attributes.category?.category},
      assignees: assigneeValue,
      updateProjectId:curProject.id,
      filesUploaded:curProject.attributes.attachments_url
    });
    this.props.projectClose()
    this.handleMenuClose();
  };

  getAssigneeList = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.memberListEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    this.memberListApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   
   
  };
  createAnotherProject = () => {
    this.setState({
      isActiveProjectCreated: false,
      popoverAnchorEl: this.popoverRef.current,
    });
  };
  createProjectOpen = (event: any) => {
    this.setState({ createProjectEl: event.currentTarget });
  };
  createProjectClose = () => {
    this.setState({
      createProjectEl: null,
    });
  };

  popoverHandleClick = (event: any) => {
    this.getAssigneeList();
    this.getCategory();
    this.setState({
      createProjectEl:null,
      popoverAnchorEl: event.currentTarget,
      viewValueOfTabs: 0,
      isEditing: false,
      currentDraftId: 0,
    });
  };


  getTimesheetModalClose = () => {
    this.setState({ isGetTimesheet: false },this.props.closeTimeSheet);
  };

  getTimesheetModalOpen = (e: any) => {
    e.preventDefault();
    this.setState({ isGetTimesheet: true,anchorEl:null });
  };  
  
  timesheetChooseAssigneesHandler = (e:any) => {
    e.preventDefault();
    this.setState({ isActiveTimesheetChooseAssignees: true });
  };

  timesheetChooseAssigneesModalClose = (e: any) => {
    e.preventDefault();
    this.setState({ isActiveTimesheetChooseAssignees: false });
  };

  dateRangeFilter = (date: any) => {
    this.setState({ logDates: date });
  };
  
  
  
  handleProjectChange = (event: { target: { value: any } }) => {
    if (event.target.value != undefined) {
      this.setState({
          timeSheetProject: event.target.value,
      });
    }
  };
  
  TimesheetGeneratingModalClose = () => {
    this.setState({
      isTimesheetGenerating: false,
    });
  };

  TimesheetGenerating = () => {
    this.setState({ isTimesheetGenerating: true });
  };

  
  selectAssignee = (assigneeId: number) => {
    let selectedAssignee = this.state.logAssignees;
    if (selectedAssignee.includes(assigneeId)) {
      selectedAssignee = selectedAssignee.filter((x) => x !== assigneeId);
    } else {
      selectedAssignee.push(assigneeId);
    }
    this.setState({
      logAssignees: selectedAssignee,
    });
  };

  changeTimeSheetAssignee = (assigneeId: number) => {
    let selectedAssignee = this.state.assignees;
    if (selectedAssignee.includes(assigneeId)) {
      selectedAssignee = selectedAssignee.filter((x) => x !== assigneeId);
    } else {
      selectedAssignee.push(assigneeId);
    }
    this.setState({
      assignees: selectedAssignee,
    });
  };

  projectStatusUpdate = (statusId:number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectStatusApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectStatusEndPoint + `?id=${this.props.projectId}&status=${statusId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPATCH
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  projectChangeStatus = (e: any) => {
    this.setState({projectStatus:e.target.value ?? 0})
    this.projectStatusUpdate(e.target.value ?? 0);
  };
  allAssigneeModalOpen = () => {
    this.setState({ isActiveAllAssignee: true });
  };
  allAssigneeModalClose = () => {
    this.setState({ isActiveAllAssignee: false });
  };

  getTodayDate = () => normalizeDate(moment());

  getTomorrowDate = () => normalizeDate(moment().add(1, "day"));

  getThisWeeksDate = () => {
    const startOfWeek = moment().startOf("week");
    const endOfWeek = moment().endOf("week");

    return {
      startDate: startOfWeek.format("YYYY-MM-DD"),
      endDate: endOfWeek.format("YYYY-MM-DD"),
    };
  };

  getProjectTasksByTab = () => {
    const projectTasks:any = this.state.currentProject?.attributes.tasks.data;
    const today = projectTasks.filter((task:any) => {
      if (task.attributes.due_date === this.getTodayDate()) return task;
    });
    const tomorrow = projectTasks.filter((task:any) => {
      if (task.attributes.due_date === this.getTomorrowDate()) return task;
    });
    const thisWeek = projectTasks.filter((task:any) => {
      if (
        moment(task.attributes.due_date).isBetween(
          this.getThisWeeksDate().startDate,
          this.getThisWeeksDate().endDate
        ) ||
        moment(task.attributes.due_date).isSame(
          moment(this.getThisWeeksDate().startDate)
        ) ||
        moment(task.attributes.due_date).isSame(
          moment(this.getThisWeeksDate().endDate)
        )
      )
        return task;
    });
    const thisMonth = projectTasks.filter((task:Task) => {
      if (
        moment(task.attributes.due_date).isBetween(
          moment().startOf('month'),
          moment().endOf('month')
        ) ||
        moment(task.attributes.due_date).isSame(
          moment().startOf('month')
        ) ||
        moment(task.attributes.due_date).isSame(
          moment().endOf('month')
        )
      )
        return task;
    });
    const later = projectTasks.filter((task:Task) => {
      if (moment(task.attributes.due_date).isAfter(moment().endOf('month'))) return task;
    });
    return {
      today: today,
      tomorrow: tomorrow,
      thisWeek: thisWeek,
      thisMonth: thisMonth,
      later: later
    }    
  }
  handleOpenUseExisting = () =>{
    this.setState({openUseExisting:true});
    this.getAllProjects();
  }
  handleCloseUseExisting = () =>{
    this.setState({openUseExisting:false});
  }
  handleTemplateGroupButton = (value: number) => () => {
    this.setState({ chooseTemplateChecked: value , useExistingProjectId:value });
  };
  handleSearchChange = (event: { target: { value: string } }) => {
    this.setState({ searchQuery: event.target.value , isSearching:true ,isSorting:false }, () => {
      this.filterProjects();
    });
  };
  handleSortChange = (event: { target: { value: string } }) => {
    this.setState({ sortQuery: event.target.value , isSorting:true ,isSearching:false }, () => {
      this.sortProjects();
    });
  };
  sortProjects = () => {
    const { projectList, sortQuery } = this.state;
    
    let sortedProjects;
    if (sortQuery === 'A-Z') {
      sortedProjects = projectList.sort((a, b) => a.attributes.title.localeCompare(b.attributes.title));
    } else {
      sortedProjects = projectList.sort((a, b) => b.attributes.title.localeCompare(a.attributes.title));
    }
  
    this.setState({ sortedProjects });
  };
  filterProjects = () => {
    const { projectList, searchQuery } = this.state;
    const filteredProjects = projectList.filter((project:Project) =>
      project.attributes.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    this.setState({ filteredProjects });
  };
  getAllProjects = () => { 
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    ); 
    this.projectListApiRequestID = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.projectListEndPoint
    );    

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleProjectEscEvent = (event: any) => {
    event.stopPropagation()
    if (event.key === 'Escape') {
      this.saveAsDraftProject()
    }
  }
  getProjectLead(currentProject: any): string | null {
    const projectLead = currentProject?.attributes?.project_lead?.[0];
    if (projectLead) {
      return `${projectLead.first_name} ${projectLead.last_name}`;
    }
    return null;
  }
  
  projectDataEmpty = () => {
    this.setState({
      currentProject: {
        "attributes": {
          "title": "",
          "description": "",
          "status": "",
          "attachments_url": [],
          "tasks": {
            "data": []
          },
          "labor_data": {
            "projected_labor_cost": 0,
            "projected_labor_hours": 0,
            "actual_labor_cost": 0,
            "actual_labor_hours": 0,
            "labor_count": 0
          },
          "machinery_data": {
          "projected_machinery_cost": 0,
          "projected_machinery_hours": 0,
          "actual_machinery_cost": 0,
          "actual_machinery_hours": 0
        }
        }
      }
    });
  }

  parseTimeToHours = (timeString?: string): number => {
    if (!timeString) return 0;
  
    const timeParts = timeString.split(":").map(part => parseInt(part, 10) || 0);
  
    if (timeParts.length !== 3) return 0; 
  
    const [hours, minutes, seconds] = timeParts;
    const totalHours = hours + minutes / 60 + seconds / 3600;
  
    return parseFloat(totalHours.toFixed(2)); 
  };

  deleteTaskFromList = () => {
    if (this.state.projectTask !== null) {
      this.confirmDelete(JSON.stringify([this.state.projectTask.id].map(Number)));
    } else if (this.state.deleteTaskId > 0) {
      this.confirmDelete(JSON.stringify([this.state.deleteTaskId].map(Number)));
    } else {
      this.confirmDelete(JSON.stringify(this.state.selectedTasks.map(Number)));
    }
  };

  handleClose = () => {
    this.setState({
      isDeletingTask: false,
      deleteTaskId: 0,
    });
  };

  confirmDelete = (result: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const body = {
      data: {
        ids: result,
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.taskDeleteApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskDeleteEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  dateChangeFilter = (date: any) => {
    const strDate = date.format("YYYY-MM-DD");
    this.setState({selectedDate:strDate})
  }

  handleCloseFilterOnDeals = ()=>{
    this.setState({isFiltersOnDeal:false})
  }

  handleInputFiltersChange = (event:any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  handleChecked = (event:any) => {
    const { name, checked } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: checked,      
    }));
  };

  applyFilters = ()=> {
    this.setState({isSearching:true})
    this.searchDealsAPI();
    this.handleCloseFilterOnDeals();
  }

  removeDealFilters = ()=>{
    this.setState({
    filterStageVal:"",
    contact:"",
    dealOwner:"",
    hasAttachment: false,
    hasAssociatedProduct: false,
    dealsHasTask: false,
    fromAmount:"",
    toAmount:"",
    closeDate:"",
    selectedDate:""
  })
  }

  dealsValue = (value: any) => () => {
    this.setState({ dealsSelected: value });
  };

  handleContinouDeal = (selectedId:any)=>{
    this.setState({dealsSelected:selectedId, addDealShow:false})
    this.getSingleDealData(selectedId)
  }

  handleDealsSort = (event : {target : {value : any}}) => {    
    if (event.target.value != undefined) {
      this.setState({sortBySelectedVal : event.target.value, isSearching: true}, () => {this.searchDealsAPI()})
    } 
  }

  handleDealFilter = ()=>{
    this.setState({isFiltersOnDeal:true})
  }

  handleDealSearch = (event: { target: { value: string } })=>{
    this.setState({valueOfSeach: event.target.value, isSearching: true },() => {this.searchDealsAPI()})
  }

  searchDealsAPI = () => {
    const {valueOfSeach,
    sortBySelectedVal,
    filterStageVal,
    contact,
    dealOwner,
    hasAttachment,
    hasAssociatedProduct,
    dealsHasTask,
    fromAmount,
    toAmount,
    closeDate,
    selectedDate
  } = this.state;

    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchDealAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchDealEndPoint}?search=${valueOfSeach}&sort_by=${sortBySelectedVal}&contact_id=${contact}&deal_owner_id=${dealOwner}&stage=${filterStageVal}&attachments=${hasAttachment}&products=${hasAssociatedProduct}&tasks=${dealsHasTask}&from_amount=${fromAmount}&to_amount=${toAmount}&close_date_type=${closeDate}&close_date=${selectedDate}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDueDate = (date: any) => {
    this.setState({
      due_date: date?.isValid ? date.format("YYYY-MM-DD") : "",
      error: { ...this.state.error, due_date: "" },
    });

    if (date?.isValid) {
      this.setState({
        deadline_date: moment(date.format("YYYY-MM-DD"))
          .add(14, "day")
          .format("YYYY-MM-DD"),
      });
    }
  }
  handleDeadlineDate = (date: any) => {
    this.setState({
      deadline_date: date?.isValid ? date.format("YYYY-MM-DD") : "",
    });
  }

  handleEventUpload =()=>{
    this.setState({isUpdating:true})
  }

  viewChangeStatus = (e: any) => {
    let ids = [Number(this.state.projectTask.id)];
    this.updateStatus(ids, e.target.value ?? 0);
  };

  updateStatus = (ids: number[], statusId: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changeStatusApiID = requestMessage.messageId;

    let body = {
      data: {
        id: JSON.stringify(ids),
        status: statusId,
      },
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskChangeStatusEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPATCH
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  moveTasklistProjectHandler = () => {
    const body = {
      "task_ids": this.state.taskID,
      "project_id": this.state.moveTaskChecked ? this.state.moveTaskChecked : "" ,
      "task_list_id": this.state.movedTasklist ? this.state.movedTasklist: "" ,
    };
    const header = {
      token: this.userToken.meta.token,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.moveTasklistProjectApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.moveTasklistAndProjectApiPath 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: body })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  moveTaskProjectResponse =(responseJson:{message:string})=>{
      if(responseJson.message){
        const taskCount = this.state.selectedTasks.length;
        const taskText =
        taskCount > 1
            ? this.t(`${configJSON.tasksMovedTxt}`)
            : this.t(`${configJSON.taskMovedTxt}`);
        const message = `${taskCount} ${taskText}.`;             
        this.moveTaskProjectModalClose() 
        this.closePriorityView()          
      }
    }

  movedTasklistEvent = (value: any) => () => {
    this.setState({  movedTasklist: value });
  };

  closePriorityView = (priority: number = -1) => {
    if (priority > -1) {
      this.changePriority(priority);
    }
    this.setState({ changePriorityViewEl: null, anchorEl: null });
  };

  changePriority = (priority: number, id: number = 0) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changePriorityApiID = requestMessage.messageId;

    let ids = this.state.selectedTasks.map((id) => Number(id));

    if (this.state.projectTask != null) {
      ids = [Number(this.state.projectTask.id)];
    }
    if (id > 0) {
      ids = [Number(id)];
    }
    let body = {
      data: {
        id: JSON.stringify(ids),
        priority: priority,
      },
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskChangePriorityEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPATCH
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteTaskInProject = (id: number = 0) => {
    let deleteTaskId = this.state.deleteTaskId;
    if (id > 0) {
      deleteTaskId = id;
    }
    this.setState({ isDeletingTask: true, deleteTaskId: deleteTaskId });
  };

  handleMoveTasklistProject = (e: any, taskIds:number[]) => {  
    e.preventDefault();    
    this.setState({ 
      isMoveTaskProject: true,
      moveTaskChecked:0 ,
      taskTopDetailsAnchorEl:null,      
      taskID:taskIds.length===0 ? this.state.taskID:taskIds
    });
    this.getTaskListsMoveData();
    this.getProjectDetails(this.state.projectTask?.attributes.account_block_project_id);
  };

  moveTaskProjectModalClose = () => {
    this.setState({ isMoveTaskProject: false , taskModalOpen:false});
    this.handleMenuClose();
  };

  moveTaskGroupList = (value: any) => () => {
    this.setState({ moveTaskChecked: value });
  };

  getTaskListsMoveData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTaskListRequestApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskListEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDuplicateTask = (taskId: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createDuplicateTaskApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createDuplicateTask + `?id=${taskId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sortRecords = (filterData: any) => {
    const { sortAssignee } = this.state;
    if (sortAssignee != "") {
      let leftIndex: number;
      let rightIndex: number;
      leftIndex = sortAssignee == "Z-A" ? 1 : -1;
      rightIndex = sortAssignee == "Z-A" ? -1 : 1;
      filterData = filterData.sort((member1: any, member2: any) => {
        if (member1.title.toLowerCase() < member2.title.toLowerCase())
          return leftIndex;
        if (member1.title.toLowerCase() > member2.title.toLowerCase())
          return rightIndex;
        return 0;
      });
    }

    return filterData;
  };

  handleAssigneeWithMemberId = (assigneeId: string[], teamId:string) => {
    let selectedAssignee = this.state.taskSelectedAssignees;
    assigneeId.forEach((item:string)=>{
    if (!selectedAssignee.includes(item)) {
      selectedAssignee.push(item);
    }
  });
    this.setState({
      taskSelectedAssignees: selectedAssignee,
      selectedTeamList:[...this.state.selectedTeamList, teamId]
    });
  };

  handleSortByAssignee = (e: any) => {
    this.setState({ sortAssignee: e.target.value });
  };

  handleAssigneeChange = (assigneeId: number) => {
    let selectedAssignee = this.state.taskSelectedAssignees;
    if (selectedAssignee.includes(assigneeId)) {
      selectedAssignee = selectedAssignee.filter((x) => x !== assigneeId);
    } else {
      selectedAssignee.push(assigneeId);
    }
    this.setState({
      taskSelectedAssignees: selectedAssignee,
    });
  };

  handleAssigneeTabsValue = (val: any) => {
    this.setState({
      assigneesTabValue: val,
    });
  };

  removeAssignees = (assignee: any[]) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeAssigneesApiID = requestMessage.messageId;

    const body = {
      id: this.state.projectTask.id,
      account_ids: assignee,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeAssigneesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addAssignee = (assignee: any[]) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const body = {
      data: {
        id: this.state.projectTask.id,
        assignees: assignee.map(String),
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addAssigneeApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addAssigneesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateAssignee = () => {
    let selAssignee = this.state.taskSelectedAssignees;

    if (selAssignee.length == 0) {
      selAssignee = [this.state.projectTask.attributes.created_by];
    }
    const removeAssignee = this.state.projectTask.attributes.assignees.filter(
      (x: any) => !selAssignee.includes(String(x.id))
    );

    const addAssignee = selAssignee.filter(
      (x: any) =>
        this.state.projectTask.attributes.assignees.find(
          (y: any) => y.id == Number(x)
        ) == undefined
    );
    this.apiCallsCount = 1;
    if (removeAssignee.length > 0 && addAssignee.length > 0) {
      this.apiCallsCount = 2;
    }

    if (removeAssignee.length > 0) {
      this.removeAssignees(removeAssignee.map((x: any) => String(x.id)));
    }

    if (addAssignee.length > 0) {
      this.addAssignee(addAssignee);
    }
    if (!this.state.taskModalOpen) {
      this.setState({ projectTask: null });
    }
  };

  handleAssigneeModalClose = (e: any) => {
    e.preventDefault();
    if (this.state.projectTask !== null) {
      this.updateAssignee();
    } else if (this.state.popoveAnchorEl !== null) {
      this.setState({ assignees: this.state.taskSelectedAssignees });
    }

    this.setState({ isActiveAssigneeChanges: false });
  };

  removeSelectedAssignees = () => {
    this.setState({ taskSelectedAssignees: [], selectedTeamList:[] });
  };

  getTeams = () => {
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webTeamsGetEndPoint
    );

      
    this.fetchTeamApiCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  displayTeamsOptions = () => {
    type obj = {
      id: string;
    }
    const teamsName = this.state.teams.map(
      (item: {
        id: string;
        attributes: {
          id: number;
          title: string;
          users: number;
          member: obj[]
      }}) => ({
        id: item.id,
        title: `${item.attributes.title}`,
        users: item.attributes.users,
        initials: `${item?.attributes?.title?.charAt(
          0
        )}${item.attributes.title.charAt(0)}`,
        memberId:item.attributes.member.map((item:{ id:string })=>{
            return item.id.toString()
        })
      })
    );
    return teamsName.filter((obj: { title: string }) =>
      this.containsText(obj.title, this.state.assigneeSearchText)
    );
  };

  handleCloseAddDealsModal = ()=>{
    this.setState({dealsSelected:"",addDealShow:false})
  }

  getSingleDealData = (dealId:number) => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleDealRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewSingleDeal + `id=${dealId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editTask = (body: any) => {
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editTaskApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskEditEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createTaskBtn = async () => {
    let error = this.state.error;
    let hasError = false;
    if (this.state.title.trim() === "") {
      error.title = this.t("Please enter title");
      this.setState({ error: error });
      hasError = true;
    }
    
    if (hasError) {
      return;
    }
    let labour_cost: number = 0;
    let assignees = [...this.state.assignees];

    if (assignees.length == 0) {
      assignees = [this.userToken.data.id];
    }
    let taskHours = this.state.taskHours;
    let hours = this.state.hours;
    let minutes = this.state.minutes;
    if (taskHours == "") {
      taskHours = "00:00";
      hours = "0";
      minutes = "0";
    }
    for (const element of assignees) {
      const assignee = this.state.assigneeList.find((x) => x.id == element);
      labour_cost +=
        (assignee.attributes.hourly_pay *
          (Number(hours) + Number(minutes) / 60)) /
        assignees.length;
    }
    const priorityMapping:{ [key: string]: string } = {
      "0": "normal",
      "1": "critical",
      "2": "emergency",
    };
    const formdata = new FormData();
    formdata.append("data[title]", this.state.title);
    formdata.append("data[due_date]", this.state.due_date);
    formdata.append("data[deadline_date]",this.state.deadline_date == ""
    ? moment(this.state.due_date).add(14, "day").format("YYYY-MM-DD")
    : this.state.deadline_date);
    formdata.append("data[description]", this.state.description);
    formdata.append("data[labour_cost]", labour_cost.toFixed(2),);
    formdata.append("data[hours]", taskHours);
    formdata.append("data[priority]", priorityMapping[this.state.priority.toString()]);
    formdata.append("data[status]", "open");
    formdata.append("data[expenditures]", this.state.expenditures);
    formdata.append("data[account_block_project_id]", this.state.projectId?.toString());
    formdata.append("data[account_block_task_list_id]", this.state.taskListObject.id.toString());
    formdata.append("data[deal_ids][]",this.state.dealsSelected.toString());
   
    if(this.state.productsSelectedId.length > 0){
      this.state.productsSelectedId.forEach((item:any) => {
        formdata.append("data[inventory_or_assets][]", JSON.stringify(item));
      });    
    }

    if(assignees.length > 0){
      assignees.forEach((productId:any) => {
        formdata.append("data[assignees][]", productId.toString());
      });    
    }
    let filesTaskArray;
   
    if (this.state.isEditing) {
      filesTaskArray = await convertToFiles(this.state.filesUploaded);
    } else {
      filesTaskArray = this.state.filesUploaded;
    }

    if(filesTaskArray){
      for (const file of filesTaskArray) {
        formdata.append("data[attachments][]", file);
      }
    }

    if(this.state.filesUploaded){
      for (const file of this.state.filesUploaded) {
        formdata.append("data[attachments][]", file);
       }
    }

    if(this.state.isEditing){
      formdata.append("data[id]", this.state.updateTaskId.toString())
    }

    if (this.state.isEditing) {
      this.editTask(formdata);
      return;
    }

    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createTaskApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taskCreateEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleInventoryAssetsView = ()=>{
    this.setState({viewProductsSelected:true})
  }

  dragFileEnter = () => {
    this.setState({
      fileDrop: true,
    });
  };
  dragFileLeave = () => {
    this.setState({
      fileDrop: false,
    });
  };

  handleOnDrop = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({attachmentTaskRemove:true,attachmentID:""})
      const files = event.target.files;
      if (files) {
        const newFilesArray = Array.from(files);
    
        this.setState((prevState) => {
          const allFiles = [...prevState.filesUploaded, ...newFilesArray];
          const uniqueFiles = Array.from(
            new Map(
              allFiles.map((file) => [`${file.name}-${file.lastModified}`, file])
            ).values()
          );  
          return { filesUploaded: uniqueFiles };
        });
        if(this.state.isUpdating){
          this.addAttachmentsForProject(newFilesArray);       
        }
      }   
    };

  selectedProducts=(productsSelected:any,selectedProductsData:any)=>{
    this.getAllProductsFromInventory(productsSelected);
    this.setState({selectedProductsData:selectedProductsData})
    const formattedProducts = productsSelected.map((productId:any) => {
  
      return {
            id: productId , 
            dosage: ""
        };
    });

    this.setState({productsSelectedId:formattedProducts});
  }

  LabourCostCalculation = () => {
    let projectedLaborCost = 0;
    let minPerPerson = (Number(this.state.hours) * 60 + Number(this.state.minutes)) / this.state.taskSelectedAssignees.length as number;
    const members = this.state.assigneeList.filter((item) => {
      return (this.state.taskSelectedAssignees.includes(item.id));
    });
    members.forEach((item) => {
      projectedLaborCost += Number(item.attributes.hourly_pay) * (minPerPerson / 60);
    });
    return Math.ceil(projectedLaborCost).toString();
  }

  handleDropMenu = (event: any) => {
    this.setState({ createTaskanchorEl: event.currentTarget });
  };

  dropMenuClose = (priority: number) => {
    this.setState({
      createTaskanchorEl: null,
      priority: priority,
    });
  };

  handleViewInventoryAssetsClose = ()=>{
    this.setState({viewProductsSelected:false})
  }

  handleInventoryAssetsShowClose = ()=>{
    this.setState({showAssetsOfInventory:false})
  }

  handleInventoryAssetsShow = ()=>{
    this.setState({showAssetsOfInventory:true})
  }

  handleShowAddExpenditures = ()=>{
    this.setState({showAddExpendituresBtn:true})
  }

  handleShowAddDealsModal = ()=>{
    this.setState({addDealShow:true})
    this.getAllDealsData();
  }

  toggleHoursModal = () => {
    this.setState({ showHoursModal: !this.state.showHoursModal });
  };

  hoursChange = (event: any) => {
    const name = event.target.name as keyof S;
    let newValue = event.target.value;
    newValue = newValue.replace(/\D/g, "");
    if (Number(newValue) > 999) {
      newValue = this.state.hours;
    }
    newValue = String(Number(newValue));
    if (Number(newValue < 10)) {
      newValue = "0" + newValue;
    }
    this.setState({ [name]: newValue } as unknown as Pick<S, keyof S>);
  };

  minutesChange = (event: any) => {
    const name = event.target.name as keyof S;
    let newValue = event.target.value;
    newValue = newValue.replace(/\D/g, "");
    if (Number(newValue) > 59) {
      newValue = this.state.minutes;
    }
    newValue = String(Number(newValue));
    if (Number(newValue < 10)) {
      newValue = "0" + newValue;
    }
    this.setState({ [name]: newValue } as unknown as Pick<S, keyof S>);
  };

  setHours = () => {
    let totalHours = "";
    if (this.state.hours !== "" || this.state.minutes !== "" || this.state.seconds !== "") {
      totalHours = this.state.hours == "" ? "00" : this.state.hours;
      totalHours += ":";
      totalHours += this.state.minutes == "" ? "00" : this.state.minutes;
      totalHours += ":";
      totalHours += this.state.seconds == "" ? "00" : this.state.seconds;
    }
    this.setState({
      taskHours: totalHours,
      showHoursModal: false,
    });
  };

  getAllDealsData = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllDealsRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDealsEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleTaskList = (e: any, newValue: any) => {
    this.setState({      
      taskListObject:{ ...this.state.taskListObject,id: newValue.id, name: newValue.title },
    });
  };

  changeProjectHandler = (event: { target: { value: any } }) => {
    this.TasklistGetofProject(event.target.value);
    if (event.target.value != undefined) {
      this.setState({
          projectId: event.target.value,
      });
    }
  };

  saveAsTaskInDraft = () => {
    let tasksInDraft = this.state.tasksInDraft;
    if (this.state.currentDraftId !== 0) {
      let taskInDraft = tasksInDraft.find(
        (x: any) => x.id == this.state.currentDraftId
      );
      taskInDraft.title = this.state.title;
      taskInDraft.due_date = this.state.due_date;
      taskInDraft.deadline_date = this.state.deadline_date;
      taskInDraft.description = this.state.description;
      taskInDraft.taskHours = this.state.taskHours;
      taskInDraft.hours = this.state.hours;
      taskInDraft.minutes = this.state.minutes;
      taskInDraft.priority = this.state.priority;
      taskInDraft.assignees = this.state.assignees;
    } else {
      tasksInDraft = [
        ...tasksInDraft,
        {
          id: this.draftTaskId,
          title: this.state.title,
          due_date: this.state.due_date,
          deadline_date: this.state.deadline_date,
          description: this.state.description,
          taskHours: this.state.taskHours,
          hours: this.state.hours,
          minutes: this.state.minutes,
          priority: this.state.priority,
          assignees: this.state.assignees,
        },
      ];
    }
    this.setState({
      tasksInDraft: tasksInDraft,
    });
    this.draftTaskId = this.draftTaskId + 1;
    this.setState({
      popoveAnchorEl: null,
      showHoursModal: false,
      isEditing: false,
    });
  };

  handleEscTaskEvent = (event: any) => {
    event.stopPropagation()
  if (event.key === 'Escape') {
    this.saveAsTaskInDraft()
  }
  }

  getAllProductsFromInventory = (productsSelected:any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };
    const body = {"catalogue_ids": productsSelected}     
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllProductsFromInventoryApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.allInventoryProductApiEndpint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  openTaskInDraft = (event: any, id: number) => {
    const taskInDraft = this.state.tasksInDraft.find((x) => x.id == id);
    this.setState({
      title: taskInDraft.title,
      due_date: taskInDraft.due_date,
      deadline_date: taskInDraft.deadline_date,
      description: taskInDraft.description,
      taskHours: taskInDraft.taskHours,
      hours: taskInDraft.hours,
      minutes: taskInDraft.minutes,
      priority: taskInDraft.priority,
      assignees: taskInDraft.assignees,
      popoveAnchorEl: event.currentTarget,
      currentDraftId: id,
    });
  };

  deleteDraftTask = (id: number) => {
    const taskInDraft = this.state.tasksInDraft?.filter((x) => x.id !== id);
    this.setState({ tasksInDraft: taskInDraft });
  };

  handleAssignee = (e: any, id: number = 0) => {
    e.preventDefault();
    this.setState({ isActiveAssigneeChanges: true });
    if (this.state.projectTask !== null) {
      const result: string[] = this.state.projectTask.attributes.assignees.map(
        (item: any) => String(item.id)
      );
      this.setState({ taskSelectedAssignees: result });
    } else if (this.state.popoveAnchorEl !== null) {
      this.setState({ taskSelectedAssignees: this.state.assignees });
    } else {
      this.setState({
        taskSelectedAssignees: [],
      });
      if (id > 0) {
        this.getTaskDetails(id);
      }
    }
  };

  taskListCreateApi = (tlName: string, projectId: number, typeIndex: number = 0) => {
    let error = this.state.error;
    let hasError = false;
    
    if (projectId == 0) {
      error.taskListName = "Please choose the project";
      hasError = true;
    }
    if (hasError) {
      this.setState({ error: error });
      return;
    }
    const body = {
      name: tlName,
    };
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (typeIndex == 0) {
      this.taskListCreateApiId = requestMessage.messageId;
    } else {
      this.tasklistAddApiId = requestMessage.messageId;
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createTaskListEndPoint +
        `?id=${projectId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: body })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  taskListAdd = (data: any, setError?: any, setShowDialog?: any) => {
    this.setErrorTaskList = setError;
    this.showDialogTaskList = setShowDialog;
    this.taskListCreateApi(data.title, this.state.projectId, 1);
  };

  refreshData=()=>{
    if (this.props.projectId) {
      this.TasklistGetofProject(this.props.projectId);
    } else {
      this.getAllProjects();
    }
    if (this.props.updateData) {
      this.props.updateData();
    }
  }

  editTaskOpen = (e: any) => {
    this.getAllProjects();
    if(this.state.projectTask?.attributes.expenditures) {
      this.setState({ showAddExpendituresBtn: true })
    }
    const status = this.state.projectTask?.attributes.status;
    let statusValue = 1;

    if (status === "open") {
      statusValue = 0;
    } else if (status === "completed") {
      statusValue = 2;
    }

    const priority = this.state.projectTask?.attributes.priority;
    let priorityValue = 0;

    if (priority === "emergency") {
      priorityValue = 2;
    } else if (priority === "critical") {
      priorityValue = 1;
    }
    const assigneeValue: string[] =
      this.state.projectTask.attributes.assignees.map((item: any) =>
        String(item.id)
      );

    const hoursValue = this.state.projectTask.attributes.hours.split(":");
    this.setState({
      popoveAnchorEl: this.popoverRef.current,
      currentDraftId: 0,
      taskModalOpen: false,
      viewValueOfTabs: 0,
      logAssignees: [],
      isEditing: true,
      title: this.state.projectTask.attributes.title,
      due_date: this.state.projectTask.attributes.due_date,
      deadline_date: this.state.projectTask.attributes.deadline_date,
      description: this.state.projectTask.attributes.description,
      labour_cost: this.state.projectTask.attributes.labour_cost,
      hours: hoursValue[0],
      minutes: hoursValue[1],
      taskHours: this.state.projectTask.attributes.hours,
      priority: priorityValue,
      status: statusValue,
      assignees: assigneeValue,
      updateTaskId: Number(this.state.projectTask.id),
      projectTask: null,
      filesUploaded: this.state.projectTask.attributes.attachments_url,
      expenditures: this.state.projectTask?.attributes.expenditures,
      projectId:this.state.projectTask?.attributes.account_block_project_id,
      productsSelectedId:this.state.projectTask?.attributes.inventory_or_assets,
      taskListObject:{id:this.state.projectTask?.attributes.account_block_task_list_id,name:this.state.projectTask?.attributes.task_list_name,project:this.props.projectId??0}
    });
    // this.taskOpenPopover(e)
    this.handleMenuClose();
  };

  handleTaskModalClose = () => {
    this.setState({
      taskModalOpen: false,
      projectTask: null,
    });
  };

  getTaskDetails = (taskId: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTaskId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.singleTaskEndPoint + `?id=${taskId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTotalCostDetails = (taskID:number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTaskCostDetailApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTotalCostEndpoint + `?id=${taskID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handle_modalOpen = (taskId: number) => {
    this.props.projectClose()
    this.setState({ taskModalOpen: true });

    this.setState({taskID:taskId})
    this.getTaskDetails(taskId);
    this.getTotalCostDetails(taskId)
  };

  updateCurrentStatus = (taskId: number) => {
    this.getTaskDetails(taskId)
    if(this.state.statusUpdate === 0) {
      this.setState({ statusUpdate: taskId, currId: taskId })
      this.startTheTaskTimerAPI(taskId)
    } else {
      this.stopTaskTimerAPI(this.state.statusUpdate)
      this.startTheTaskTimerAPI(taskId)
      this.setState((prevState) => ({
        currId:this.state.statusUpdate,
        projectTask: {
          ...prevState.projectTask,
          attributes: {
            ...prevState.projectTask?.attributes,
            current_status: "stopped",
            is_running: "false",
          },
        },
      }),()=>{
        this.setState({statusUpdate: taskId})
      })
    }
  }

  startTheTaskTimerAPI = (taskId: number) => {
    const timestamp = getCurrentTime();
    this.setState((prevState) => ({
      projectTask: {
        ...prevState.projectTask, // Keep other properties unchanged
        attributes: {
          ...prevState.projectTask?.attributes, // Ensure attributes exist
          is_running: "true", // Update is_running key
        },
      },
    }));
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
      const body = {
        "data":{
           "task_id": taskId,
           "status_updated_at": timestamp,
           "action_type": "started"// stopped //started
      
       }
    } 
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateTaskStartAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateTaskProgress
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  stopTaskTimerAPI = (taskId: number) => {
    const currentTimer = getCurrentTime();
    this.setState((prevState) => ({
      projectTask: {
        ...prevState.projectTask, // Keep other properties unchanged
        attributes: {
          ...prevState.projectTask?.attributes, // Ensure attributes exist
          is_running: "false", // Update is_running key
        },
      },
    }));
    const apiHeader = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
      const apiBody = {
        "data":{
           "task_id": taskId,
           "status_updated_at": currentTimer,
           "action_type": "stopped"// stopped //started
      
       }
    } 
    
    const messageRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateTaskStopAPICallId = messageRequest.messageId;

    messageRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateTaskProgress
    );

    messageRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(apiHeader)
    );

    messageRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(apiBody)
    );

    messageRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(messageRequest.id, messageRequest);
  };

  // Customizable Area End

}