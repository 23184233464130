Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfSendinblueApi";
exports.labelBodyText = "CfSendinblueApi Body";
exports.noCampaignText = "No email campaigns";
exports.noCampaignDescription = "Please create a email campaigns to see here.";
exports.brevoViewAllCampaigns = "bx_block_email_notifications/get_emails_data";
exports.createEmailCampaign = "bx_block_email_notifications/campaigns";
exports.getContactLists = "bx_block_email_notifications/get_lists";
exports.validateAPIKeyBrevo = "/bx_block_email_notifications/validate_brevo_api_key";
exports.getSpecificMail = "/bx_block_email_notifications/get_specific_email"
exports.getAllTemplates = "/bx_block_email_notifications/get_email_templates";
exports.getSenderList = "/bx_block_email_notifications/get_senders_list";
exports.methodGET = 'GET';
exports.methodPOST = 'POST';
exports.btnExampleTitle = "CLICK ME";
exports.mailingTxt ="FR sommelier mass mailing";
exports.emailCampaignSubTxt="View the details of your email campaign.";
exports.statusTxt="Status";
exports.sentTxt="Sent";
exports.creatorTxt="Creator";
exports.fromTxt="From";
exports.toTxt="To";
exports.dateTxt="Dates";
exports.createdOnTxt="Created on";
exports.sentOnTxt="Sent on";
exports.scheduledTxt="Scheduled";
exports.designTxt="Design";
exports.previewTxt="Preview";
exports.advanceSettingsTxt="Advanced settings";
exports.viewTxt="View";
exports.editTxt="Edit";
exports.campaignTitleTxt="Campaign Title";
exports.nameTxt="Name";
exports.emailAddressTxt="Email Address";
exports.useTxt="Use";
exports.Selected="Selected";
exports.googleAnalyticsTxt="Google Analytics";
exports.googleSubTxt="The parameters sent to Google Analytics for each campaign are: UTM_SOURCE:BREVO UTM_MEDIUM:EMAIL UTM_CAMPAIGN:NAME_OF_YOURCAMPAIGN";
exports.utmTrackingValueTxt="Customize UTM tracking value";
exports.embedImageEmailTxt="Embed images in the email";
exports.inserImageTxt="You may insert images directly into your email, however, please note that there are potential disadvantages.";
exports.functionNotSupportTxt ="This function may not be supported by some email clients because it produces large email sizes and some message platforms consider this a type of spamming.";
exports.cannotSendLargerImgTxt = "You cannot send a campaign larger than 5MB with embedded images in the email. Note: This feature is not required to display images in your email.This setting only determines the method used for displaying images.";
exports.addAnAttachmentsTxt="Add an attachment";
exports.chooseFileToAttachTxt="Choose a file to attach to your email (4MB maximum). Accepted file types: ics, xlsx, xls, ods, docx, doc, csv, pdf, txt, gif, jpg, jpeg, png, tif, tiff, rtf, msg, pub, mobi, ppt, pptx, eps.";
exports.addAttachmentsTxt="Add attachment";
exports.mirrorTxt="Enable mirror link";
exports.mirrorSubTxt= "This setting is recommended if you don't have mirror link in your message content. A mirror link allows people to click to view the campaign in their web browser.";
exports.finishTxt="Finish";
exports.chooseDateTxt="Choose Date";
exports.chooseTime="Choose Time";
exports.cancelTxt="Cancel";
exports.saveTxt="Save";
exports.completedTxt="Completed";
exports.currentTxt="Current";
exports.createCampaignTxt ="Create Campaign";
exports.firstStepTxt="First step";
exports.continueTxt="Continue";
exports.designPriviewTxt="FR sommelier mass mailing / Design Preview";
exports.useExistingTxt="Use Existing";
exports.createNewTxt="Create New";
exports.sortCampaaignText="Sort by";
exports.timeAndDateTxt="Date & Time";
exports.dateRange="Date Range";
exports.campaign="Campaign";
exports.contactListTxt="Contact List";
exports.senderTxt="Sender";
exports.columnsTxt="Columns";
exports.toContactsorGroups="To (contact or group)";
exports.searchTypeHereTxt="Start typing to search...";
exports.sendNowTxt="Send Now";
exports.scheduleCampaignTxt="Schedule Campaign";
exports.scheduleCampaignNow="Schedule Now";
exports.chooseCampaignTxt="Choose Campaign";
exports.chooseCampaignTxtDescription = "Select a previous campaign to reuse it.";
exports.aTozTxt="A-Z";
exports.zToaTxt="Z-A"; 
exports.createdOn="Created on";
exports.stepsTxt="Steps";
exports.previousCamTxt="Previous Campaigns";
exports.connectBrevoTxt="Let's connect your Brevo account";
exports.connectSubTxt="After connecting your Brevo account, you can send email campaigns and direct emails to contacts.";
exports.connectbtnTxt="Connect";
exports.connectbrevo="Connect Brevo";
exports.brevoAccountHeading="Brevo Account";
exports.brevoAccountSubHeading="Please enter your brevo API key here.";
exports.setUpGuide="Setup Guide";
exports.step1Guide=" 1. Login to your Brevo account at";
exports.step2Guide=" 2. Now go to settings and click on generate API."
exports.step3Guide=" 3. Copy the new generated API key and paste that here."
exports.subjectTxt="Subject line";
exports.previewTxt="Preview";
exports.getProfileList="/account_block/profile_list";
exports.reuseCampaignTxt="Reuse Campaign"
exports.recipientsTxt="recipients";
exports.newestooldTxt= "Newest to oldest";
exports.oldtonewTxt="Oldest to newest";
exports.delivered="Delivered";
exports.openers="Openers";
exports.clicks="Clicks";
exports.unsubscribers="Unsubscribers";
exports.backButtonTxt="Back";
exports.getSubCounts="/bx_block_custom_form/get_counts_of_sub_tabs";
exports.subTitleTxt="Settings used for this email campaign.";
exports.googleAnalytics="Google Analytics";
exports.emailTxt="Embed images in the email";
exports.hasAttachment="Has Attachments";
exports.toContactGroupTxt="To (contact or group)";
exports.noDesignAvailable="No Designs Available";
exports.noDesignsAvailableTxt="You can make designs within your Brevo account and they will automatically be pulled here for use. If you need help making templates, you can hire us in the EtOH Shop!";
exports.exploreShopBtn="Explore EtOH Shop";
exports.viewGroupPath = "bx_block_contactslist/groups";
exports.VisitBrevoBtn="Visit Brevo";
exports.contactsPath = "bx_block_contactslist/contacts";
// Customizable Area End