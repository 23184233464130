// Customizable Area Start
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Switch,
  Popover,
  Checkbox
} from "@material-ui/core";

import FilterDropDown from "./FilterDropDown.web";
import { viewColumn, sortVariant, checked, searchIcon } from "../assets";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import { configJSON } from "../ContactsListController.web";
import TablePagination from "@material-ui/core/TablePagination";

interface ContactBooksProps {
  classes: any;
  anchorEl: any;
  anchorElMore: any;
  handleShowContactDetails: (contactId: string) => void;

  contacts: any;
  closeCreateContact: any;
  handleOpenMenuMore: any;
  handleCloseMenuMore: any;
  searchValue: string;
  handleSearch: (e: any) => void;
  filterGroups: any;
  filterCompany: any;
  filterBusinessCategories: any;
  filterShippingCountry: any;
  onChangeSort:(e: any)=>void;
  sortValue:any;
  hasAnOpenDeal:boolean;
  hasInteractions:boolean;
  hasFavoriteProducts:boolean;
  hasNote:boolean;
  handleChecked:(e:any)=>void;
  handleCheckedHideColumns:(e:any)=> void;
  name:boolean;
  email:boolean;
  group:boolean;
  favProduct:boolean;
  interaction:boolean;
  openDeal:boolean;
  handleColumnOpenMenu:any;
  handleColumnCloseMenu:any;
  anchorElColmun:any;
  handleInputChange:(e:any)=>void;
  groupsData:any;
  businessCategoryList:any;
  searchedContacts:any,
  isSearching:boolean;
  sortedAndFilteredData:any;
  isSortAndFiltering:boolean;
  countryDataList:any;
  t:any;
  checkedColumns:any;
}

const ContactBooks = (props: ContactBooksProps) => {
  const {
    classes,
    anchorElMore,
    handleShowContactDetails,
    handleOpenMenuMore,
    handleCloseMenuMore,
    searchValue,
    handleSearch,
    contacts,
    filterGroups,
    filterCompany,
    filterBusinessCategories,
    filterShippingCountry,
    onChangeSort,
    hasAnOpenDeal,
    hasInteractions,
    hasFavoriteProducts,
    hasNote,
    handleChecked,
    name,
    group,
    email,
    favProduct,
    interaction,
    openDeal,
    handleColumnOpenMenu,
    handleColumnCloseMenu,
    anchorElColmun,
    handleInputChange,
    groupsData,
    businessCategoryList,
    isSearching,
    searchedContacts,
    sortedAndFilteredData,
    isSortAndFiltering,
    countryDataList,
    sortValue,
    handleCheckedHideColumns,
    t,
    checkedColumns,
  } = props;
  const [contactData,setContactData] = useState(contacts)

  useEffect(() => {     
    if (isSortAndFiltering) {
      setContactData(sortedAndFilteredData);
      } else {
      setContactData(contacts);
    }
  }, [isSortAndFiltering,sortedAndFilteredData,contacts]);
  
  useEffect(() => {     
    if (isSearching) {
      setContactData(searchedContacts);
      } else {
      setContactData(contacts);
    }
  }, [isSearching,searchedContacts,contacts]);
  

    const transformedGroupData = groupsData.map((item:any) => ({
      id: item.attributes.id,
      name: item.attributes.name,
    }));
    const transformedBusinessCategoryData =  businessCategoryList.map((item:any) => ({
      id: item.id,
      name: item.name,
    }));

    const countryList = countryDataList.map((item:any) => ({
      id: item.id,
      name: item.country_name,
    }));

    const uniqueCompanyNames = new Set();
    const uniqueCountryNames = new Set();
    
    const uniqueContacts = contacts?.filter((contact:any) => {
      const companyName = contact?.attributes?.business_info?.data?.attributes?.company_name?.trim();
      if (companyName && !uniqueCompanyNames.has(companyName)) {
        uniqueCompanyNames.add(companyName);
        return true;
      }
      return false;
    });
    const shippingCountry = contacts?.filter((contact:any) => {
      const countryName = contact?.attributes?.shipping_address?.data?.attributes?.country?.trim();
      if (countryName && !uniqueCountryNames.has(countryName)) {
        uniqueCountryNames.add(countryName);
        return true;
      }
     return false;
    });
  
    const companyNames = Array.from(
      new Set(
        uniqueContacts
          .map((contact: any, index: any) => ({
            id: index,
            name: contact.attributes.business_info.data.attributes.company_name.trim(),
          }))
          .filter((company:any) => company.name !== "")
      )
    );

    const countryNames = Array.from(
      new Set(
        shippingCountry
          .map((contact: any, index: any) => ({
            id: index,
            name: contact.attributes.shipping_address.data.attributes.country?.trim(),
          }))
          .filter((country:any) => country.name !== "")
      )
    );

    const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
 
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 20));
    setPage(0);
  };
    
    const truncateName = (name:string) => {
      return name.length > 15 ? `${name.substring(0, 10)}...` : name;
    };
    let message;
    const contactText = contactData.length !== 1 ? t(`${configJSON.contactsTxt}`) : t(`${configJSON.contactTxt}`);
    message = contactText?.replace('{{count}}', contactData.length);

    const showFilter = contacts?.some((contact: any) => {
      const productsCount = contact?.attributes?.contact_products_count;
      const dealCount = contact?.attributes?.contact_deals_count;
      const interactions = contact?.attributes?.contact_interactions_count;
      return productsCount > 0 || dealCount > 0 || interactions > 0;
    });
    
    const renderContactRow = (contact: any) => {
      const groups = contact.attributes.contact_groups || [];
      const name = `${contact.attributes.first_name} ${contact.attributes.last_name}`;
      const displayedGroups = groups.slice(0, 4);
      const groupNames = displayedGroups
        .filter((group: any) => group !== null)
        .map((group: any) => group?.name)
        .join(', ');
      const ellipsis = groups.length > 3 ? '...' : '';
    
      return (
        <Box key={contact.id} className="tb-row">
          {!checkedColumns.nameChecked && (
            <Box
              className="tb-cell tb-cell-1 view-contact"
              onClick={() => handleShowContactDetails(contact.id)}
              data-test-id="view-contact-name"
            >
              <Box className="avatar-wrapper">
                <Typography className="text-eh">
                  {contact.attributes.first_name.charAt(0).toUpperCase()}
                  {contact.attributes.last_name.charAt(0).toUpperCase()}
                </Typography>
              </Box>
              <Typography className="tb-cell-content">{truncateName(name)}</Typography>
            </Box>
          )}
          {!checkedColumns.emailChecked && (
            <Box className="tb-cell tb-cell-2">
              <Typography className="tb-cell-content">{contact.attributes.email}</Typography>
            </Box>
          )}
          {!checkedColumns.groupChecked && (
            <Box className="tb-cell tb-cell-3">
              <Typography className="tb-cell-content">
                {groupNames}
                {ellipsis}
              </Typography>
            </Box>
          )}
          {!checkedColumns.favProduct && (
            <Box className="tb-cell tb-cell-4">
              <Typography className="tb-cell-content">
                {contact.attributes.contact_products_count}
              </Typography>
            </Box>
          )}
          {!checkedColumns.interactions && (
            <Box className="tb-cell tb-cell-5">
              <Typography className="tb-cell-content">
                {contact.attributes.contact_interactions_count + contact.attributes.contact_outreaches_count}
              </Typography>
            </Box>
          )}
          {!checkedColumns.openDeal && (
            <Box className="tb-cell tb-cell-6">
              <Typography className="tb-cell-content">
                {contact.attributes.contact_deals_count}
              </Typography>
            </Box>
          )}
          <Box className="tb-cell tb-cell-7">
            <Button
              className="btn-view-action"
              data-test-id="btn-view-action"
              onClick={() => handleShowContactDetails(contact.id)}
            >
              {t(`${configJSON.view}`)}
            </Button>
          </Box>
        </Box>
      );
    };
    
    const renderContacts = (contacts: any[], grouped: boolean = false) => {
      if (grouped) {
        const groupedContacts = contacts.reduce((acc: { [key: string]: any[] }, contact: any) => {
          const firstLetter = sortValue === "Last Name"
            ? contact?.attributes?.last_name?.charAt(0)?.toUpperCase() || '#'
            : contact?.attributes?.first_name?.charAt(0)?.toUpperCase() || '#';
          if (!acc[firstLetter]) {
            acc[firstLetter] = [];
          }
          acc[firstLetter].push(contact);
          return acc;
        }, {});
    
        const groupedArray = Object.keys(groupedContacts).reduce((acc: any[], letter) => {
          const group = groupedContacts[letter].map(contact => ({
            letter,
            contact,
          }));
          return [...acc, ...group];
        }, []);
    
        return groupedArray
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map(({ letter, contact }: any, index: number) => (
            <React.Fragment key={contact.id}>
              {index === 0 || groupedArray[index - 1]?.letter !== letter ? (
                <Typography className="tb-sort-character">{letter}</Typography>
              ) : null}
              {renderContactRow(contact)}
            </React.Fragment>
          ));
      }
       
    return contacts
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((contact: any) => renderContactRow(contact));
    };
    return (
    <Box className={classes.contactBooksWrapper}>     
      <Box className="filter-box">
        <Box className="left-box">
          <Typography className="total-contact">{t(`${message}`)}</Typography>
          <div className="line" ></div>
          <CustomInputWeb
            type="search"
            value={searchValue}
            label=""
            placeholder={t(`${configJSON.searchTxtType}`)}
            onChange={(event: any) => handleSearch(event.target.value)}
            startAdornment={
              <InputAdornment position="start">
                {/* <SearchIcon /> */}
                <img src={searchIcon} className="search-contacts"/>
              </InputAdornment>
            }
            customClass="contacts-search"
            data-test-id="searchfield"
          />
        </Box>
        <Box className="right-box">
          {transformedGroupData.length > 0 && 
          <FilterDropDown
            classes={classes}
            title={t(`${configJSON.group}`)}
            options={transformedGroupData}
            value={filterGroups}
            name="filterGroups"
            onSelectValue={handleInputChange}
            data-test-id="filterDropdown1"
          />
        }
        {companyNames.length > 0 && 
        <FilterDropDown
        classes={classes}
        title={t(`${configJSON.company}`)}
        options={companyNames}
        value={filterCompany}
        name="filterCompany"
        onSelectValue={handleInputChange}
        data-test-id="filterDropdown2"
      />
        }
          
          {transformedBusinessCategoryData.length > 0 && 
              <FilterDropDown
            classes={classes}
            title={t(`${configJSON.businessCategorytxt}`)}
            options={transformedBusinessCategoryData}
            value={filterBusinessCategories}
            name="filterBusinessCategories"
            onSelectValue={handleInputChange}
            data-test-id="filterDropdown3"
          /> 
          }
        {countryNames.length > 0 && 
          <FilterDropDown
            classes={classes}
            title={t(`${configJSON.shippingCountry}`)}
            options={countryNames}
            value={filterShippingCountry}
            name="filterShippingCountry"
            onSelectValue={handleInputChange}
            data-test-id="filterDropdown4"
          />
        }
         {showFilter &&
          <Box className="filter-more">
            <Box className="menu-dropdown more" onClick={handleOpenMenuMore}>
              <Typography className="more-txt">{t(`${configJSON.more}`)}</Typography>
              <ArrowDropDownIcon style={{color:"#737070",marginTop:"-3px"}}/>
            </Box>
            <Popover
              open={Boolean(anchorElMore)}
              anchorEl={anchorElMore}
              onClose={handleCloseMenuMore}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
             classes={{ paper: `${classes.popoverStyle}` }}
            >
              <Box className="view-more-dropdown">
                <Box className="dropdown-menu-item">
                  <Typography className="dropdown-menu-item-text">
                    {t(`${configJSON.openDeal}`)}
                  </Typography>
                  <Switch
                    className="dropdown-menu-item-switch"
                    checked={hasAnOpenDeal}
                    onChange={handleChecked} 
                    name="hasAnOpenDeal"               
                   />
                </Box>
                <Box className="dropdown-menu-item">
                  <Typography className="dropdown-menu-item-text">
                    {t(`${configJSON.openInteraction}`)}
                  </Typography>
                  <Switch
                    className="dropdown-menu-item-switch"
                    checked={hasInteractions}
                    onChange={handleChecked} 
                    name="hasInteractions"
                  />
                </Box>
                <Box className="dropdown-menu-item">
                  <Typography className="dropdown-menu-item-text">                    
                    {t(`${configJSON.openFavProduct}`)}
                  </Typography>
                  <Switch
                    className="dropdown-menu-item-switch"
                    checked={hasFavoriteProducts}
                    onChange={handleChecked} 
                    name="hasFavoritesProduct"
                  />
                </Box>
                <Box className="dropdown-menu-item">
                  <Typography className="dropdown-menu-item-text">
                    {t(`${configJSON.hasNote}`)}
                  </Typography>
                  <Switch
                    className="dropdown-menu-item-switch"
                    checked={hasNote}
                    onChange={handleChecked} 
                    name="hasNotes"
                    data-test-id="hasNotes"
                  />
                </Box>
              </Box>
            </Popover>
          </Box>
           }
          <div className="line"></div>
          <Box className="filter-more">
            <Box className="menu-dropdown more" onClick={handleColumnOpenMenu}>
              <img className="select-icon" src={viewColumn} alt="sortVariant"/>
              <Typography className="more-columns">{t(`${configJSON.columns}`)}</Typography>
            </Box>
            <Popover
              open={Boolean(anchorElColmun)}
              anchorEl={anchorElColmun}
              onClose={handleColumnCloseMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
             classes={{ paper: `${classes.popoverStyle}` }}
            >
              <Box className="view-more-dropdown" >
                <CustomInputWeb
                  type="search"
                  // value={searchValue}
                  label=""
                  placeholder={t(`${configJSON.columnsType}`)}
                  // onChange={(event: any) => handleSearch(event.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  data-test-id="searchfield"
                />
                <Box className="dropdown-menu-item-column">
                  <Typography className="dropdown-menu-item-text">                   
                   {t(`${configJSON.name}`)}
                  </Typography>
                  <Checkbox 
                   className="dropdown-menu-item-checked"
                   checked={checkedColumns.nameChecked} 
                   onChange={handleCheckedHideColumns} 
                   data-test-id="name" 
                   name="nameChecked" 
                   checkedIcon={<img src={checked} alt="checkbox" />}                   
                   />
                 
                </Box>
                <Box className="dropdown-menu-item-column">
                  <Typography className="dropdown-menu-item-text">                   
                   {t(`${configJSON.email}`)}
                  </Typography>
                  <Checkbox 
                   className="dropdown-menu-item-checked"
                   checked={checkedColumns.emailChecked} 
                   onChange={handleCheckedHideColumns} 
                   data-test-id="email" 
                   name="emailChecked" 
                   checkedIcon={<img src={checked} alt="checkbox" />}                    
                   />
                </Box>
                <Box className="dropdown-menu-item-column">
                  <Typography className="dropdown-menu-item-text">
                  {t(`${configJSON.group}`)}
                  </Typography>
                  <Checkbox 
                   className="dropdown-menu-item-checked"
                   checked={checkedColumns.groupChecked} 
                   onChange={handleCheckedHideColumns} 
                   data-test-id="group" 
                   name="groupChecked"  
                   checkedIcon={<img src={checked} alt="checkbox" />}                   
                   />
                </Box>
                <Box className="dropdown-menu-item-column">
                  <Typography className="dropdown-menu-item-text">
                  {t(`${configJSON.favProduct}`)}
                  </Typography>
                  <Checkbox 
                   className="dropdown-menu-item-checked"
                   checked={checkedColumns.favProduct} 
                   onChange={handleCheckedHideColumns} 
                   data-test-id="fav-product" 
                   name="favProduct"   
                   checkedIcon={<img src={checked} alt="checkbox" />}                  
                   />
                </Box>
                <Box className="dropdown-menu-item-column">
                  <Typography className="dropdown-menu-item-text">
                  {t(`${configJSON.interactions}`)}
                  </Typography>
                  <Checkbox 
                   className="dropdown-menu-item-checked"
                   checked={checkedColumns.interactions} 
                   onChange={handleCheckedHideColumns} 
                   data-test-id="interaction" 
                   name="interactions"                    
                   checkedIcon={<img src={checked} alt="checkbox" />} 
                   />
                </Box>
                <Box className="dropdown-menu-item-column">
                  <Typography className="dropdown-menu-item-text">
                  {t(`${configJSON.openDealTxt}`)}
                  </Typography>
                  <Checkbox 
                   className="dropdown-menu-item-checked"
                   checked={checkedColumns.openDeal} 
                   onChange={handleCheckedHideColumns} 
                   data-test-id="openDeal" 
                   name="openDeal"   
                   checkedIcon={<img src={checked} alt="checkbox" />}                  
                   />
                </Box>
                
              </Box>
            </Popover>
          </Box>
        
          <FormControl className="select-control sortby-control">
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
                classes: { paper: classes.dropdownStyle },
              }}
              onChange={(e:any) => onChangeSort(e.target.value)}
              displayEmpty
              defaultValue={t(`${configJSON.sortByTxt}`)}
              inputProps={{ IconComponent: () => null }}
              renderValue={(value) => {
                return (
                  <Box>
                    <img
                      className="select-icon"
                      src={sortVariant}
                      alt="sortVariant"
                    />
                    {value}
                  </Box>
                );
              }}
              data-test-id="sbmt-quiz-dropdown2"
            >
              <MenuItem value={t(`${configJSON.firstNameTxt}`)}>{t(`${configJSON.firstNameTxt}`)}</MenuItem>
              <MenuItem value={t(`${configJSON.lastNameTxt}`)}>{t(`${configJSON.lastNameTxt}`)}</MenuItem>
              <MenuItem value={t(`${configJSON.recentlyAddedTxt}`)}>{t(`${configJSON.recentlyAddedTxt}`)}</MenuItem>
            </Select>
          </FormControl>
          <Box className="contacts-paging">
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={contactData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            data-test-id="table-pagination"
          />
          </Box>
        </Box>
      </Box>
      <Box className="content-wrapper">
        <Box className="table-header">
          {!checkedColumns.nameChecked &&   <Box className="hd-column hd-column-1">
            <Typography className="tb-header-title">{t(`${configJSON.name}`)}</Typography>
          </Box>}
         {!checkedColumns.emailChecked &&  <Box className="hd-column hd-column-2">
            <Typography className="tb-header-title">{t(`${configJSON.email}`)}</Typography>
          </Box>}
          {!checkedColumns.groupChecked &&
          <Box className="hd-column hd-column-3">
            <Typography className="tb-header-title">{t(`${configJSON.group}`)}</Typography>
          </Box>
          }
          {!checkedColumns.favProduct &&
          <Box className="hd-column hd-column-4">
            <Typography className="tb-header-title">
            {t(`${configJSON.favProduct}`)}
            </Typography>
          </Box>
          }
          {!checkedColumns.interactions  &&
          <Box className="hd-column hd-column-5">
            <Typography className="tb-header-title">{t(`${configJSON.interactions}`)}</Typography>
          </Box>
          }
          {!checkedColumns.openDeal && 
          <Box className="hd-column hd-column-5">
            <Typography className="tb-header-title">{t(`${configJSON.openDealTxt}`)}</Typography>
          </Box>
          }
          <Box className="hd-column hd-column-6">
            <Typography className="tb-header-title">{t(`${configJSON.action}`)}</Typography>
          </Box>
        </Box>
        <Box className="table-content">
        {contactData.length > 0 ? (
    <>{renderContacts(contactData, sortValue !== "Recently Added")}</>
  ) : (
    <Box className="error-message">
      <Typography>{t(`${configJSON.notFoundTxt}`)}</Typography>
    </Box>
  )}

        </Box>

      </Box>
    </Box>
  );
};

export default ContactBooks;

// Customizable Area End
