import React from "react";
// Customizable Area Start
import {
  withStyles,
} from "@material-ui/core/styles";
import { customStyles } from "../../../../components/src/TaskListStyles.web";
import TruncatedTextWithTooltip  from "../../../../components/src/CustomTooltip.web";
import {
  Popover,
  Chip,
  Box,
  Tabs,
  Tab,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  InputLabel,
  Checkbox,
  InputAdornment,
  FormControl,
  Select,
  ListSubheader,
  ListItemSecondaryAction,
  IconButton,
  MenuItem,
  Modal,
  Button,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  RadioGroup,
  Radio,
  Tooltip,
  ListItemText,
  Switch
} from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import CustomInputWeb from "../../../../components/src/CustomInput.web";
import CustomDatePicker from "../../../../components/src/CustomDatePicker.web";
import ConfirmActionModal from "../../../email-account-registration/src/teams-web/ConfirmActionModal.web";
import {
  fileTree,
  accountPlus,
  dotsHorizontal,
  checkBoxFilled,
  checkBoxEmpty,
  editGray,
  copyGray,
  attachmentImg1,
  minimizeGray,
  closeGray,
  productImg,
  projectCreate,
  contentSave,
  fileTreeBlue,
  formatTreeBlue,
  dotVerticle,
  uploadImg,
  attacmentsIcon,
  calendarViewIcon,
  grayCheck,
  greenCheck,
  warningIcon,
  inventoryIcon,
  accountGray,
  checkGreen,
  timerSand,
  sortby,
} from "../assets";
import moment from "moment";
import { TabPanel, viwA11yProps ,assigneeA11yProps } from "./TaskList.web";
import FreeSoloCreateOption from "../../../../components/src/FreeSoloCreateOption.web";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { ActivityLog } from "../../../ActivityLog/src/ActivityLog.web";
import { FileDrop } from "react-file-drop";
import { Project } from "../../../../components/src/ReusableEnums";
import { formatTimeTaskHours, hoursToSeconds, renderStatusIcon, renderTaskCount, renderTimerIcon ,formatDueDate, getPlaceholderImage, formatTimeForShowingHours, getCurrencySymbol, getNumberFormat, generateSummary} from "../../../../components/src/ReusableFunctions";
// Customizable Area End

import ProjectViewController,{
  Props,
  // Customizable Area Start
  configJSON,
  // Customizable Area End
} from "./ProjectViewController.web";



// Customizable Area Start
import TimeTrackingBillingWeb from "../../../timetrackingbilling/src/Timetrackingbilling.web";
import AllInventoryAssetsViewModal from "../../../../components/src/AllInevtoryAssestsModel.web";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { AddInventoryAndAssests } from "./AddInventoryAndAssests.web";
import ProductInventoryViewModal from "../../../../components/src/ProductInventoryViewModal.web";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// Customizable Area End


export class ProjectView extends ProjectViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
    showTaskByTab=()=>{
      const tasksToShow = this.getProjectTasksByTab();
      return <>
      {tasksToShow.today.length >= 0 &&
        this.taskTab("Today",tasksToShow.today)}
      {tasksToShow.tomorrow.length >= 0 &&
        this.taskTab("Tomorrow",tasksToShow.tomorrow)}
      {tasksToShow.thisWeek.length >= 0 &&
        this.taskTab("This Week",tasksToShow.thisWeek)}
      {tasksToShow.thisWeek.length >= 0 &&
        this.taskTab("This Month",tasksToShow.thisMonth)}
      {tasksToShow.thisWeek.length >= 0 &&
        this.taskTab("Later",tasksToShow.later)}
      </>
     }

    taskTab = (value:string,tasks:any) => {      
      return(
        <Box className="tasklist-task-wrap">
            <Box className="task-heading">
              <Box className="heading">{value}</Box>
              <Box className="total-task"> {renderTaskCount(tasks, this.t, configJSON)}</Box>
            </Box>
            <List className="tasklist-task-ul">
            {tasks.map((task:any) => {             
              return (
                <ListItem
                  className="tasklist-task-li"
                  key={task.id}
                  role={undefined}
                  dense
                >
                  <Box className="tasklist-inner">
                    <ListItemIcon className="tasklist-icon">                   
                    {renderStatusIcon(task.attributes.status)}
                    </ListItemIcon>
                    <Box className="tasklist-label">
                      <Box className="task-title">
                        <Box className="title"  onClick={() => this.handle_modalOpen(task.id)} data-test-id="modalOpenBtn">{task.attributes.title}</Box>
                        <Box className="title-right">
                          <Box className="controller-link">
                           {renderTimerIcon(
                              task.id,
                              task.attributes.status,
                              task.attributes.is_running,
                              this.startTaskTimerAPI
                            )}
                          </Box>
                        </Box>
                      </Box>
                      <Box className="task-desc">
                        <Box className="desc-left">
                          <Box className="date" component="span">
                          {formatDueDate(task.attributes.due_date)}
                          </Box>
                        </Box>
                        <Box className="desc-right">
                          <Box className="timing" component="span">
                          {formatTimeTaskHours(task.attributes.duration)}/{formatTimeTaskHours(hoursToSeconds(task.attributes.hours))}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </ListItem>
              );
            })}
            </List>
        </Box>
        )
    }

    projectListItem=(classes:any, option:any,selected:boolean=true)=>{
    return(
      <ListItem
      key={option.id}
      value={option.id}
      onClick={() => this.changeProjectLead(option.id, option.deactivated)}
      data-test-id={`projectLead-${option.id}`}
    >
      <Box className="assignee-detail">
        <Box className="assignee-left">
          <Box className="assignee-profile">
            {" "}
            {option.initials}
          </Box>
          <Box className="assignee-info">
            <Box className="assignee-name">
              {option.title}
            </Box>
            <Box className="assignee-email">
              {option.email}
            </Box>
            <Box className="chip-wrapper">
              {option.team
                .slice(0, 2)
                .map((teamName: string) => {
                  return (
                    <Chip
                      key={teamName}
                      className={classes.chipButton}
                      // label="Sales Department"
                      label={teamName}
                    />
                  );
                })}

              {option.team.length > 2 && (
                <Chip
                  className={`more-btn ${classes.chipButton}`}
                  label={`+${
                    option.team.length - 2
                  } ${this.t(configJSON.moreTxt)}`}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box className="assignee-right">
          <Box className={selected?"assinee-selected":"assinee-unselected"}>
            <img
              className="normal-img"
              src={checkBoxEmpty}
              alt="checkBoxEmpty"
            />
            <img
              className="selected-img"
              src={checkBoxFilled}
              alt="checkBoxFilled"
            />
          </Box>
        </Box>
      </Box>
    </ListItem>
    )

    }

    timesheetListItem = (classes:any, option:any,selected:boolean=true)=>{
      return(
        <ListItem
          key={option.id}
          value={option.id}
          onClick={() =>
            this.changeTimeSheetAssignee(option.id)
          }
        >
          <Box className="assignee-detail">
            <Box className="assignee-left">
              <Box className="assignee-profile">
                {" "}
                {option.initials}
              </Box>
              <Box className="assignee-info">
                <Box className="assignee-name">
                  {option.title}
                </Box>
                <Box className="assignee-email">
                  {option.email}
                </Box>
                <Box className="chip-wrapper">
                  <Chip
                    key="Sales Department"
                    className={classes.chipButton}
                    label={this.t(`${configJSON.salesDepartmentTxt}`)}
                  />
                  <Chip
                    key="Sales Department"
                    className={classes.chipButton}
                    label={this.t(`${configJSON.accountsTeamTxt}`)}
                  />
                  <Chip
                    className={`more-btn ${classes.chipButton}`}
                    label={`+2 ${this.t(configJSON.moreTxt)}`}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="assignee-right">
              <Box className="hours-details">
                <Box>$15</Box>
                <Box className="gray-txt">{this.t(`${configJSON.perHoursTxt}`)}</Box>
              </Box>
              <Box className={selected?"assinee-selected":"assinee-unselected"}>
                <img
                  className="normal-img"
                  src={checkBoxEmpty}
                  alt="checkBoxEmpty"
                />
                <img
                  className="selected-img"
                  src={checkBoxFilled}
                  alt="checkBoxFilled"
                />
              </Box>
            </Box>
          </Box>
        </ListItem>
      )
    }
    
    timesheetChooseAssigneesModal = (classes:any) => {
      return (
        <Modal
              hideBackdrop
              open={this.state.isActiveTimesheetChooseAssignees}
              onClose={this.timesheetChooseAssigneesModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className={classes.modalDialog}>
          <Box
                className={`${classes.modalDialogBox} filter-dialogbox timesheet-assignee-dialogbox`}
              >
                <Box className="modal-dialoginner">
                  <Box className="modal-heading">
                    <Box className="filter-heading">
                      <Box
                        className="auth-back"
                        onClick={this.timesheetChooseAssigneesModalClose}
                      >
                        <i>
                          <svg
                            className="MuiSvgIcon-root"
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                          </svg>
                        </i>
                      </Box>
                      <Typography className="modal-title" component="h2">                        
                        {this.t(`${configJSON.timesheetTxt}`)}
                      </Typography>
                    </Box>
                    <Box                      
                      className="close-icon"
                      onClick={this.timesheetChooseAssigneesModalClose}
                      data-test-id="activityLogClose"
                    >
                      <CloseIcon />
                    </Box>
                  </Box>
                  <Box className="modal-description">
                    <Box className="heading-block">
                      <Box className="primary-txt">{this.t(`${configJSON.chooseAssigneesTxt}`)}</Box>
                      <Box component="p" className="sub-txt">
                        {this.t(`${configJSON.setDefaultTxt}`)}
                      </Box>
                    </Box>
                    <Box className="modal-form" component="form">
                      <List className="assignee-multiSelect-list selected-list">
                        <ListSubheader>
                          <CustomInputWeb
                            fullWidth={true}
                            placeholder={this.t(`${configJSON.txtSearchAssignees}`)}
                            name="assigneeSearchText"
                            autoFocus={true}
                            onChange={this.handleOnChange}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            }
                          />
                        </ListSubheader>
                        <ListSubheader className="multiselect-subheader">
                        {this.t(`${configJSON.txtAssignees}`)}
                          <Box
                            
                            // onClick={this.removeProjectLead}
                            className="tasklist-link"
                          >
                             {this.t(`${configJSON.txtRemoveAllAssignees}`)}
                          </Box>
                        </ListSubheader>
                        {/* <ListSubheader className="multiselect-subtxt">
                          Members
                        </ListSubheader> */}
                        {this.displayAssigneeOptions()
                          .filter((x: any) => this.state.assignees.includes(x.id))
                          .map((option: any) => (
                            this.timesheetListItem(classes,option)
                          ))}
                        <ListSubheader className="multiselect-subheader">
                          {this.t(`${configJSON.txtUnassigned}`)}
                        </ListSubheader>
                      </List>
                      <Box className="tasklist-tabwrapper">
                        <Box className="assignee-tabs">
                          <Tabs
                            aria-label="simple tabs example"
                            value={0}
                            data-test-id="updateAssigneeTabs"
                          >
                            <Tab label={this.t(`${configJSON.membersTxt}`)} {...assigneeA11yProps(0)} />
                          </Tabs>
                        </Box>
                        <TabPanel value={0} index={0}>
                          <List className="assignee-multiSelect-list">
                            {this.assigneeListHeader(classes,this.state.assignees)}
                            {this.sortAssignees(this.displayAssigneeOptions())
                              .filter(
                                (x: any) => !this.state.assignees.includes(x.id)
                              )
                              .map((option: any) => (
                                this.timesheetListItem(classes,option,false)
                              ))}
                          </List>
                        </TabPanel>
                      </Box>
                    </Box>
                  </Box>
                </Box>
          </Box>
        </Modal>
      )
    }

    timeSheetModal = (classes:any) => {
      return (
        <Modal
          hideBackdrop
          open={this.state.isGetTimesheet}
          onClose={this.getTimesheetModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modalDialog}>
          <Box className={`${classes.modalDialogBox} create-timesheet-dialog`}>
            <Box className="modal-dialoginner">
              <Box className="modal-heading">
                <Typography className="modal-title" component="h2">
                {this.t(`${configJSON.timesheetTxt}`)}
                </Typography>
                <Box                 
                  className="close-icon"
                  onClick={this.getTimesheetModalClose}
                  data-test-id="timesheetClose"
                >
                  <CloseIcon />
                </Box>
              </Box>
              <Box className="modal-description">
                <Box component="p" className="sub-txt">
                  {this.t(`${configJSON.selectDetailsTxt}`)}
                </Box>
                <Box className="modal-form" component="form">
                  <Box className="form-info-wrapper">
                    <Box className="form-row">
                      <Box className="form-col select-modal-field">
                        <CustomInputWeb
                          label={this.t(`${configJSON.chooseMemeberOrTeam}`)}
                          disabled={true}
                          startAdornment={
                            this.state.assignees.length > 0 ? (
                              <InputAdornment position="start">
                                <Box className="assignee-list">
                                  {this.displayAssigneeOptions()
                                    .filter((x: any) =>
                                      this.state.assignees.includes(x.id)
                                    )
                                    .slice(0, 2)
                                    .map((option: any) => {
                                      return (
                                        <Box
                                          className="assignee-bubble"
                                          key={option.id}
                                        >
                                          {option.initials}
                                        </Box>
                                      );
                                    })}
                                  {this.state.assignees.length > 2 && (
                                    <Box className="assignee-bubble">
                                      +{this.state.assignees.length - 2}
                                    </Box>
                                  )}
                                  {/* <Box className="assignee-bubble">BS</Box>
                              <Box className="assignee-bubble">LA</Box>
                              <Box className="assignee-bubble">+2</Box> */}
                                </Box>
                              </InputAdornment>
                            ) : null
                          }
                          endAdornment={
                            <InputAdornment
                              position="end"
                              onClick={this.timesheetChooseAssigneesHandler}
                              data-test-id="timesheetAssigneeModalOpen"
                            >
                              <ChevronRightIcon />
                            </InputAdornment>
                          }
                          isRequired={true}
                        />
                      </Box>
                    </Box>
                    <Box className="form-row">
                      <Box className="form-col">
                        <Box className="form-control">
                          <CustomDatePicker
                            placeholder={this.t(`${configJSON.dateRangeTxt}`)}
                            range={true}
                            value={this.state.logDates}
                            currentDate=""
                            // datePickerRef={this.datePickerRef}
                            onChange={this.dateRangeFilter}
                            onOpenPickNewDate={false}
                            t={this.t}
                            data-test-id="logDateFilter"
                            required={true}
                            error={""}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box className="form-row">
                      <Box className="form-col">
                        <FormControl
                          className="select-outer"
                          variant="outlined"
                        >
                          <InputLabel id="project">{this.t(`${configJSON.projectTxt}`)}</InputLabel>
                          <Select
                            labelId="project"
                            id="project"
                            value={this.state.timeSheetProject}
                            onChange={this.handleProjectChange}
                            name="project"
                            label={this.t(`${configJSON.projectTxt}`)}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                              classes: {
                                paper: `${classes.dropdownStyle} modal-dropdown`,
                              },
                            }}
                            data-test-id="modalChangeStatus"
                            IconComponent={ExpandMoreIcon}
                          >
                            {this.props.projectList.map((proj) => {
                              return (
                                <MenuItem value={proj.id} key={proj.id}>
                                  {proj.attributes.title}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <Box className="form-row">
                      <Box className="form-col">
                        <Box className="form-control">
                          <Autocomplete
                            multiple
                            className="team-multiselect"
                            options={this.state.projectTaskList}
                            disableCloseOnSelect
                            ChipProps={{ deleteIcon: <CloseIcon /> }}
                            popupIcon={<ExpandMoreIcon />}
                            getOptionLabel={(option) => option.attributes.name}
                            classes={{
                              popper: classes.teamAutocompleteDropbox,
                            }}
                            renderOption={(option, { selected }) => (
                              <Box className="tasklist-li">
                                <Box className="tasklist-left">
                                  {option.attributes.name}
                                </Box>
                                <Box className="tasklist-icon">
                                  <Checkbox
                                    className="checklist-check"
                                    icon={
                                      <img
                                        src={checkBoxEmpty}
                                        alt="checkBoxEmpty"
                                      />
                                    }
                                    checkedIcon={
                                      <img
                                        src={checkBoxFilled}
                                        alt="checkBoxFilled"
                                      />
                                    }
                                    tabIndex={-1}
                                    disableRipple
                                    checked={selected}
                                  />
                                </Box>
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={this.t(`${configJSON.chooseTaskListTxt}`)}
                                placeholder=""
                                autoComplete="off"
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box className="form-row">
                      <Box className="form-col">
                        <Box className="form-control">
                          <Autocomplete
                            multiple
                            renderOption={(option, { selected }) => (
                              <Box className="tasklist-li">
                                <Box className="tasklist-left">
                                  {option.title}
                                </Box>
                                <Box className="tasklist-icon">
                                  <Checkbox
                                    className="checklist-check"
                                    icon={
                                      <img
                                        src={checkBoxEmpty}
                                        alt="checkBoxEmpty"
                                      />
                                    }
                                    checkedIcon={
                                      <img
                                        alt="checkBoxFilled"
                                        src={checkBoxFilled}
                                      />
                                    }
                                    checked={selected}
                                    disableRipple
                                    tabIndex={-1}
                                  />
                                </Box>
                              </Box>
                            )}
                            className="team-multiselect"
                            disableCloseOnSelect
                            ChipProps={{ deleteIcon: <CloseIcon /> }}
                            options={ [
                              { title: "Red Wine Photoshoot 1" },
                              { title: "Red Wine Photoshoot 2" },
                              { title: "Red Wine Photoshoot 3" },
                              { title: "Red Wine Photoshoot 4" },
                            ]}
                            
                            getOptionLabel={(option) => option.title}
                            classes={{
                              popper: classes.teamAutocompleteDropbox,
                            }}
                            popupIcon={<ExpandMoreIcon />}
                           
                            renderInput={(params) => (
                              <TextField
                                autoComplete="off"
                                {...params}
                                placeholder=""
                                label="Choose Task"
                                variant="outlined"
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="button-wrapper full-btn">
                    <Button
                      className={`primary-btn ${classes.primaryButton}`}
                      title="+ Create Timesheet"
                      onClick={this.TimesheetGenerating}
                      data-test-id="createTimesheet"
                    >
                      + {this.t(`${configJSON.createTimesheetTxt}`)}
                    </Button>
                  </Box>
                  {/* timesheet generating modal start */}
                    <ConfirmActionModal
                      modalClass="nestedModal"
                      isOpen={this.state.isTimesheetGenerating}
                      handleClose={this.TimesheetGeneratingModalClose}
                      modalMessage={this.t(`${configJSON.requestConfirmationTxt}`)}                      
                      confirmBtnTxt={this.t(`${configJSON.okTxt}`)}
                      cancelBtnTxt={this.t(`${configJSON.caneleRequestTxt}`)}
                      modalHeading={this.t(`${configJSON.timesheetGeneratingTxt}`)}
                      data-testid="confirmDeleteTask"
                    />
                  {/* timesheet generating modal end */}
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      )
    }

    sortByDesign = (classes:any,onChange:any)=>{

      return (
        <FormControl className="select-control sortby-control">
          <Select
            data-testid="sortTeams"
            displayEmpty
            defaultValue={this.t(`${configJSON.txtSortBy}`)}
            renderValue={(value: any) => {
              return <Box>{value}</Box>;
            }}
            IconComponent={ExpandMoreIcon}
            onChange={onChange}

            MenuProps={{
              anchorOrigin: {
                horizontal: "left",
                vertical: "bottom",
              },
              transformOrigin: {
                horizontal: "left",
                vertical: "top",
              },
              getContentAnchorEl: null,
              classes: { paper: classes.dropdownStyle },
            }}
          >
            <MenuItem value="A-Z">{this.t(`${configJSON.aTozTxt}`)}</MenuItem>
            <MenuItem value="Z-A">{this.t(`${configJSON.zToaTxt}`)}</MenuItem>
          </Select>
        </FormControl>
      )
    }

    assigneeListHeader=(classes:any,selAssignee:any[])=>{
      const assignLength = this.displayAssigneeOptions().filter(
        (x: any) =>
          !selAssignee.includes(x.id)
      ).length
      return(
        <ListSubheader className="multiselect-membres">
            <Box className="total-member">
              {assignLength}&nbsp;
              {assignLength >1 ? this.t(`${configJSON.membersTxt}`) :this.t(`${configJSON.memberTxt}`)}
            </Box>
           {this.sortByDesign(classes,this.sortByAssigneeHandler)}
      </ListSubheader>
      )
    }
    projectLeadModal = (classes:any) => {
      return (
        <Modal
              open={this.state.isActiveChangeProjectLead}
              onClose={this.projectLeadChangeModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className={classes.modalDialog}
            >
              <Box className={`${classes.modalDialogBox} project-dialogbox`}>
                <Box className="modal-dialoginner">
                  <Box className="modal-heading">
                    <Typography className="modal-title" component="h2">                      
                     {this.t(`${configJSON.changeProjectLeadTxt}`)}
                    </Typography>
                    <Box                     
                      className="close-icon"
                      onClick={this.projectLeadChangeModalClose}
                      data-test-id="projectLeadModalClose"
                    >
                      <CloseIcon />
                    </Box>
                  </Box>
                  <Box className="modal-description">
                    <Box component="p" className="sub-txt">
                      {this.t(`${configJSON.selectSubTxt}`)}
                    </Box>
                    <Box className="modal-form" component="form">
                      <List className="assignee-multiSelect-list selected-list">
                        <ListSubheader>
                          <CustomInputWeb
                            placeholder={this.t(`${configJSON.searchProjectLeadTxt}`)}
                            fullWidth={true}
                            autoFocus={true}
                            name="assigneeSearchText"
                            onChange={this.handleOnChange}
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            }
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                            data-test-id="projectLeadSearch"
                          />
                        </ListSubheader>
                        <ListSubheader className="multiselect-subheader">
                          {this.t(`${configJSON.projectLeadTxt}`)}
                          <Box                           
                            className="tasklist-link"
                            onClick={this.removeProjectLead}
                            data-test-id="clearProjectLead"
                          >
                            {this.t(`${configJSON.projectRemoveTxt}`)}
                          </Box>
                        </ListSubheader>
                        {/* <ListSubheader className="multiselect-subtxt">
                          Members
                        </ListSubheader> */}
                        {this.displayAssigneeOptions()
                          .filter((x: any) => this.state.assignees.includes(x.id))
                          .map((option: any) => (
                            this.projectListItem(classes,option)
                          ))}
                        <ListSubheader className="multiselect-subheader">
                          {this.t(`${configJSON.txtUnassigned}`)}
                        </ListSubheader>
                      </List>
                      <Box className="tasklist-tabwrapper">
                        {/* <Box className="assignee-tabs">
                          <Tabs
                            aria-label="simple tabs example"
                            value={0}
                            data-test-id="updateAssigneeTabs"
                          >
                            <Tab label="Members" {...assigneeA11yProps(0)} />
                          </Tabs>
                        </Box> */}
                        {/* <TabPanel value={0} index={0}> */}
                          <List className="assignee-multiSelect-list">
                            {this.assigneeListHeader(classes,this.state.assignees)}
                            {this.sortAssignees(this.displayAssigneeOptions())
                              .filter(
                                (x: any) => !this.state.assignees.includes(x.id)
                              )
                              .map((option: any) => (
                                this.projectListItem(classes,option,false)
                              ))}
                          </List>
                        {/* </TabPanel> */}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
        </Modal>
      )
    }
    deleteProjectModal = () => {
      return (
        <ConfirmActionModal
          isOpen={this.state.isDeletingProject}
          handleClose={this.projectDeleteModalClose}
          modalConfirmAction={this.projectDelete}
          modalMessage={this.t(configJSON.confirmDeleteProject, { title: this.state.currentProject?.attributes.title })}
          confirmBtnTxt={this.t(`${configJSON.txtDelete}`)}
          modalHeading={this.t(`${configJSON.deleteProjectTxt}`)} 
          cancelBtnTxt={this.t(`${configJSON.txtCancel}`)}
          data-testid="confirmDeleteProject"
        />
      )
    }

    projectCreatedModal=(classes:any)=>{
      return(
        <Modal
          open={this.state.isActiveProjectCreated}
          onClose={this.projectCreatedModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modalDialog}        
        >
        <Box className={`${classes.modalDialogBox} project-created-dialog`}>
          <Box className="modal-dialoginner">
            <Box className="modal-heading">
              <Box                
                className="close-icon"
                onClick={this.projectCreatedModalClose}
                data-test-id="projectCreatedModalClose"
              >
                <CloseIcon />
              </Box>
            </Box>
            <Box className="modal-description">
              <Box className="project-create-wrapper">
                <Box className="project-img">
                  <img src={projectCreate} alt="projectCreate" />
                </Box>
                <Typography className="modal-title" component="h2">                 
                  {this.t(`${configJSON.projectCreatedMsgTxt}`)}
                </Typography>
                <Box component="p" className="sub-txt">
                  {this.t(`${configJSON.projectCreateSuccessSubTxt}`)}
                </Box>
              </Box>
            </Box>
            <Box className="modal-footer">
              <Button
                className={`secondary-btn ${classes.secondaryButton}`}
                title="Create Another Project"
                onClick={this.createAnotherProject}
              >                
                {this.t(`${configJSON.createAnotherProject}`)}
              </Button>
              <Button
                className={`primary-btn ${classes.primaryButton}`}
                title="Create Tasks"
                onClick={this.taskOpenPopover}
              >
                {this.t(`${configJSON.createTaskTxt}`)}
              </Button>
            </Box>
          </Box>
        </Box>
        </Modal>
      )
    }

    createProjectBlock=(classes:any)=>{
      const popOverOpen = Boolean(this.state.popoverAnchorEl);
      const popoverId = popOverOpen ? "simple-popover" : undefined;
      return(
          <Box className={classes.createTaskWrapper}>
            <Box className="task-actions">
              {this.state.draftProjects.map((draftProject: any) => {
                return (
                    <Button
                      className={`secondary-btn ${classes.secondaryButton}`}
                      key={`draftProject-${draftProject.id}`}
                    >
                      <Box
                        className="draft-name"
                        component="span"
                        onClick={(e: any) => this.openDraftProject(e, draftProject.id)}
                        data-test-id={`draftProject-${draftProject.id}`}
                      >
                        <img
                          className="draft-img"
                          src={fileTree}
                          alt="fileTree"
                        />
                        {draftProject.title == "" ? this.t(configJSON.draftTxt) : draftProject.title}
                      </Box>
                      <Box                      
                        className="close"
                        onClick={(e: any) => this.deleteDraftProject(draftProject.id)}
                        data-test-id={`draftProjectDelete-${draftProject.id}`}
                      >
                        <img src={closeGray} alt="closeGray" />
                      </Box>
                    </Button>
                );
              })}
              <Button
                className={`primary-btn ${classes.primaryButton}`}
                style={{visibility:this.props.showCreateProject==undefined || this.props.showCreateProject?"visible":"hidden"}}
                aria-controls="createProjectEl"
                aria-haspopup="true"
                onClick={this.createProjectOpen}
                data-test-id="createProject"
              >
                + {this.t(`${configJSON.createProjectTxt}`)}
              </Button>
            </Box>
  
            <Menu
              id="createProjectEl"
              anchorEl={this.state.createProjectEl}
              keepMounted
              open={Boolean(this.state.createProjectEl)}
              onClose={this.createProjectClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              className={`changepriority-dropdown ${classes.dropDropdown}`}
            >
              <MenuItem
                aria-describedby={popoverId}
                onClick={this.popoverHandleClick}
                ref={this.popoverRef}
                data-test-id="createNewProject"
              >
                {this.t(`${configJSON.createNewTxt}`)}
              </MenuItem>
              <MenuItem 
              onClick={this.handleOpenUseExisting}
              data-test-id="handleOpenUseExisting"
              >
               {this.t(`${configJSON.useExistingTxt}`)}
              </MenuItem>
            </Menu>
          </Box>
      )
    }

    

    allAssigneeModal=(classes:any)=>{
      const { currentProject } = this.state;
      const projectAssignees = this.getAssigneeNames(currentProject?.attributes?.assignees ?? []);
      return(
      <Modal
        hideBackdrop
        open={this.state.isActiveAllAssignee}
        onClose={this.allAssigneeModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalDialog}
      >
        <Box
          className={`filter-dialogbox all-assignee-dialogbox ${classes.modalDialogBox}`}
        >
          <Box className="modal-dialoginner">
            <Box className="modal-heading">
              <Box className="filter-heading">
                <Box
                  className="auth-back"
                  onClick={this.allAssigneeModalClose}
                >
                  <i>
                    <svg
                      className="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                    </svg>
                  </i>
                </Box>
                <Typography className="modal-title" component="h2">
                   {currentProject?.attributes.title}
                </Typography>
              </Box>
              <Box              
                className="close-icon"
                onClick={this.allAssigneeModalClose}
                data-test-id="activityLogClose"
              >
                <CloseIcon />
              </Box>
            </Box>
            <Box className="modal-description">
              <Box className="heading-block">
                <Box className="primary-txt">{this.t(`${configJSON.allAssignessTxt}`)}</Box>
                <Box component="p" className="sub-txt">
                  {this.t(configJSON.allAssignessSubTxt, { length: projectAssignees.length })}
                </Box>
              </Box>
              <Box className="modal-form" component="form">
                <Box className="form-info-wrapper">
                  <List className="tasklist-member">
                    <ListSubheader className="tasklist-total">
                      <Box className="total-member">{projectAssignees.length} {this.t(`${configJSON.txtAssignees}`)}</Box>
                      {this.sortByDesign(classes,this.sortByAssigneeHandler)}
                    </ListSubheader>
                    {this.sortAssignees(projectAssignees).map((assignee:any) => {
                      return (
                        <ListItem
                          className="tasklist-li"
                          key={assignee.id}
                          role={undefined}
                          data-test-id={`logAssignee-${assignee.id}`}
                        >
                          <Box
                            className="tasklist-left"
                          >
                            <Box className="assignee-profile">
                              {assignee.initials}
                            </Box>
                            <Box className="assignee-info">
                              <Box className="assignee-name">
                                {assignee.title}
                              </Box>
                              <Box className="assignee-email">
                                {assignee.email}
                              </Box>
                              <Box className="chip-wrapper">
                              {assignee.team
                                  .slice(0, 2)
                                  .map((teamName: string) => {
                                    return (
                                      <Chip
                                        key={teamName}
                                        className={classes.chipButton}
                                        // label="Sales Department"
                                        label={teamName}
                                      />
                                    );
                                  })}

                                {assignee.team.length > 2 && (
                                  <Chip
                                    className={`more-btn ${classes.chipButton}`}
                                    label={`+${
                                      assignee.team.length - 2
                                    } more`}
                                  />
                                )}
                              </Box>
                            </Box>
                          </Box>
                          <ListItemIcon className="tasklist-icon">
                            <Box className="tasklist-hours">
                              10{" "}
                              <Box component="span" className="hours">
                                {this.t(`${configJSON.txtHours}`)}
                              </Box>
                            </Box>
                            {/* <Checkbox
                            className="checklist-check"
                            icon={
                              <img
                                src={checkBoxEmpty}
                                alt="checkBoxEmpty"
                              />
                            }
                            checkedIcon={
                              <img
                                src={checkBoxFilled}
                                alt="checkBoxFilled"
                              />
                            }
                            checked={
                              this.state.logAssignees.indexOf(
                                assignee.id
                              ) !== -1
                            }
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          /> */}
                          </ListItemIcon>
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      )
    }

    projectPopover = (classes:any) => {
      const { popoverAnchorEl,isEditing } = this.state;
    
      const popOverOpen = Boolean(popoverAnchorEl);
      const popoverId = popOverOpen ? "simple-popover" : undefined; 

      return (
      <Popover
        id={popoverId}
        open={popOverOpen}
        anchorEl={popoverAnchorEl}
        onClose={this.popoverHandleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        classes={{ paper: `${classes.createTaskBox}` }}
        data-test-id="create-project-popover"
        onKeyDown={(event) => {this.handleProjectEscEvent(event)}}
      >
        <Box
          onClick={this.popoverHandleClose}
          className="auth-back show-sm"
        >
          <i>
            <svg
              className="MuiSvgIcon-root"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
            </svg>
          </i>
        </Box>
        <Box className="task-heading">
          <Box className="heading-left">
            <Typography className="modal-title" component="h2">
              {isEditing ? this.t(configJSON.editProjectTxt) : this.t(configJSON.createProjectTxt)}
            </Typography>
            <Box component="p" className="sub-txt">
              {isEditing
                ? this.t(configJSON.editProjectSubTxt)
                : this.t(configJSON.letsCreateProjectTxt)}
            </Box>
          </Box>
          <Box className="heading-right">
              <Box
                className="heading-icon hide-sm"
                onClick={this.saveAsDraftProject}
                data-test-id="saveAsDraftProject"
              >
                <img src={minimizeGray} alt="minimizeGray" />
              </Box>
            <Box
              className="heading-icon hide-sm"
              data-test-id="popoverProjectClose"
              onClick={this.popoverHandleClose}
            >
              <img src={closeGray} alt="closeGray" />
            </Box>
          </Box>
        </Box>
        <Box className="task-description">
          <Box className="task-form" component="form">
            <Box className="form-info-wrapper">
              <Box className="form-row">
                <Box className="form-col">
                  <CustomInputWeb
                    type="text"
                    label={this.t(`${configJSON.txtTitle}`)}
                    value={this.state.title}
                    isRequired={true}
                    name="title"
                    onChange={this.handleOnChange}
                    errors={this.state.error.title}
                    data-test-id="crateProjectTitle"
                  />
                </Box>
              </Box>
              <Box className="form-row">
                <Box className="form-col">
                  <FreeSoloCreateOption
                    options={this.state.categoryList.map((val) => {
                      return {
                        id: val.id,
                        title: val.attributes.category,
                      };
                    })}
                    addEditCallback={this.addCategory}
                    addLinkTitle={this.t(`${configJSON.addNewCategoryTxt}`)}
                    noOptionTitle={this.t(`${configJSON.noCategoryFoundTxt}`)}
                    label={this.t(`${configJSON.categoryTxt}`)}
                    id="job-id"
                    onChange={this.categoryChangeHandler}
                    value={this.state.category}
                    // errors={this.state.error.category}
                    deleteCallback={this.deleteCategory}
                    errorMsgNoRecord={this.t(`${configJSON.noCategoryCreatedTxt}`)}
                    data-test-id="projectCategory"
                    notRequired={true}
                  />
                    <ConfirmActionModal
                      isOpen={this.state.isDeletingCategory}
                      handleClose={this.deleteCategoryModalClose}
                      modalConfirmAction={this.deleteCategoryApi}
                      deleteTeamId={this.state.deleteCategoryId}
                      modalMessage={this.t(`${configJSON.confirmationTxt}`)}
                      confirmBtnTxt={this.t(`${configJSON.txtDelete}`)}
                      modalHeading={this.t(`${configJSON.deleteCategoryTxt}`)}
                      cancelBtnTxt={this.t(`${configJSON.txtCancel}`)}
                      data-testid="confirmDeleteCategory"
                    />
                </Box>
              </Box>
              <Box className="form-row">
                <Box className="form-col col6">
                  <Box className="form-control">
                    <CustomDatePicker
                      placeholder={this.t(`${configJSON.startDateTxt}`)}
                      currentDate={this.state.startDate}
                      onChange={this.changeStartDate}
                      onOpenPickNewDate={false}
                      data-test-id="createProjectstartDate"
                      // required={true}
                      error={this.state.error.startDate}
                      t={this.t}
                      inPortal={true}
                      maxDate={this.state.endDate == ""?undefined:this.state.endDate}
                    />
                  </Box>
                </Box>
                <Box className="form-col col6">
                  <Box className="form-control">
                    <CustomDatePicker
                      placeholder={this.t(`${configJSON.endDateTxt}`)}
                      currentDate={this.state.endDate}
                      onChange={this.changeEndDate}
                      onOpenPickNewDate={false}
                      data-test-id="createProjectEnddate"
                      showPastWarning={true}
                      inPortal={true}
                      t={this.t}
                      minDate={this.state.startDate == ""?undefined:this.state.startDate}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="form-row">
                <Box className="form-col">
                  <CustomInputWeb
                    type="text"
                    label={this.t(`${configJSON.txtDescription}`)}
                    isMultiline
                    rows={4}
                    value={this.state.description}
                    name="description"
                    onChange={this.handleOnChange}
                    data-test-id="crateProjectDesc"
                  />
                </Box>
              </Box>
            </Box>
            <Box className="attachments-container">
            <FileDrop
                    onDrop={()=>this.handleOnDropEvent}
                    onDragOver={this.dragEnterEvent}
                    onDragLeave={this.dragLeaveEvent}
                    data-test-id="handleOnDrop"
                  >
                    <Box
                      className={`${classes.fileUploadWrapper} ${this.state
                        .fileDropEvent && classes.fileDropDrag} `}
                      onClick={this.onBrowseEvent}
                      data-test-id="onBrowse"
                    >
                      <form ref={this.formRef}>
                        <input
                          name="upload-photo"
                          accept="*"
                          type="file"
                          onChange={this.handleOnDropEvent}
                          multiple
                          hidden
                          ref={this.fileRef}
                          data-test-id="add-project-attachment"
                        />
                      </form>
                      <Box className="file-upload-block 1">
                        <Box className="file-subtxt" component="p" >
                          {this.t(`${configJSON.clickHereTxt}`)}
                          <Box className="browse-link" component="span">
                            {this.t(`${configJSON.browseTxt}`)}
                          </Box>
                          {this.t(`${configJSON.yourPictureTxt}`)}
                        </Box>
                        <Box className="upload-icon 1">
                          <img src={uploadImg} alt="uploadImg1" />
                        </Box>
                        <Button className={`secondary-btn2 ${classes.secondaryButton}`}>{this.t(`${configJSON.addAttachmentsTxt}`)}
                        </Button>
                      </Box>
                    </Box>
            </FileDrop>
            </Box>
                {this.state.filesUploaded.length > 0 && (
                 <List style={{display:"flex"}}>
                    {this.state.filesUploaded.map((file: any, index: any) => {
                      return(
                      <ListItem key={file.name}>
                    <Box className="form-info-wrapper">         
                    <Box className="upload-list-row">
                        <Box className="upload-col col3">
                  <Box className="upload-inner">
                    <Box className="close-icon" data-test-id="removeFileUPload" onClick={()=>this.removeFileUPloadEvent(index)}>
                      <CloseIcon />
                    </Box>
                    <Box className="upload-img">
                      {getPlaceholderImage(file.type)}
                    </Box>                
                  </Box>
                  <Box className="upload-details">
                    <Box className="img-name">{file.name || file.filename}</Box>
                  </Box>
                       </Box>
                       </Box>
                     </Box>
                      </ListItem>
                    )})}
                  </List>
                )}             
          </Box>
        </Box>
        <Box className="createtask-bottom">
          <Box className="createtask-left">
            <Box className="outerbutton-wrapper">
              <Box                
                className="outer-link"
                onClick={this.projectLeadHandler}
                data-test-id="projectLeadAssignee"
              >
                {this.state.assignees.length > 0 ? (
                  <>
                    <Box className="cancel-link">
                      <CancelIcon
                        onClick={(e: any) => {
                          e.stopPropagation();
                          e.preventDefault();
                          this.setState({ assignees: [] });
                        }}
                      />
                    </Box>
                    {/* <Box className="assignee-list"> */}
                    {this.displayAssigneeOptions()
                      .filter((x: any) =>
                        this.state.assignees.includes(x.id)
                      )
                      .slice(0, 4)
                      .map((option: any) => {
                        return (
                          <LightTooltip title={`${option.title}`} arrow placement="top">
                            <Box className="assignee-bubble" key={option.id}>
                              {option.initials}
                            </Box>
                          </LightTooltip>
                        );
                      })}
                    {this.state.assignees.length > 4 && (
                      <Box className="assignee-bubble">
                        +{this.state.assignees.length - 4}
                      </Box>
                    )}
                    {/* </Box> */}
                  </>
                ) : (
                  <img src={accountPlus} alt="accountPlus" />
                )}
              </Box>
            </Box>
          </Box>
          <Box className="createtask-right">
            <Button
              className={`primary-btn ${classes.primaryButton}`}
              onClick={this.createProject}
              data-test-id="saveProjectBtn"
            >
              {isEditing
                ? this.t(`${configJSON.updateProjectTxt}`)
                : this.t(`${configJSON.createProjectTxt}`)}
            </Button>
          </Box>
        </Box>
      </Popover>
      )
    }

    projectTemplateModal=(classes:Record<string,string>)=>{
      let filterdData ;
      if (this.state.isSearching) {
        filterdData = this.state.filteredProjects;
      } else if( this.state.isSorting) {
        filterdData = this.state.sortedProjects;
      } else {
        filterdData = this.state.projectList;
      }
      return(
        <Modal
        open={this.state.openUseExisting}
        onClose={this.handleCloseUseExisting}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalDialog}
        >
          <Box
            className={`${classes.modalDialogBox} project-template-dialog`}
          >
            <Box className="modal-dialoginner">
              <Box className="modal-heading">
                <Typography className="modal-title" component="h2">
                  {this.t(`${configJSON.chooseProjectTxt}`)}
                </Typography>
                <Box 
                  className="close-icon"
                  data-test-id="handleCloseUseExisting"
                  onClick={this.handleCloseUseExisting}
                >
                  <CloseIcon />
                </Box>
              </Box>
            <Box className="modal-description">
                <Box component="p" className="sub-txt">
                  {this.t(`${configJSON.selectedProjectTxt}`)}
                </Box>
                <Box className="modal-form" component="form">
                  <Box className="form-info-wrapper">
                    <RadioGroup
                      aria-label="templatelist"
                      name="templatelist1"
                    >
                      <List className="template-list">
                        <ListSubheader>
                          <CustomInputWeb
                            placeholder={this.t(`${configJSON.searchTypeTxt}`)}
                            autoFocus={true}
                            name="search"
                            value={this.state.searchQuery}
                            onChange={this.handleSearchChange}
                            data-test-id="handleSearchChange"
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            }                         
                          />
                        </ListSubheader>
                        <ListSubheader className="multiselect-membres">
                          <Box className="total-member">{filterdData.length}&nbsp;{filterdData.length>1?this.t(`${configJSON.templatesTxt}`):this.t(`${configJSON.templateTxt}`)}</Box>
                          {this.sortByDesign(classes,this.handleSortChange)}
                        </ListSubheader>
                        {filterdData.map((project:Project) => {
                          return (
                            <ListItem
                              className="template-li"
                              key={project.id}
                              role={undefined}
                              onClick={this.handleTemplateGroupButton(project.id)}
                              data-test-id={`handleTemplateGroupButton-${project.id}`}
                            >
                              <Box
                                className="template-left"
                              >
                                <Box className="name">{project.attributes.title}</Box>
                                <Box className="sub-txt">
                                  {this.t(`${configJSON.createdOnTxt}`)} Aug 30, 2022 11:42PM
                                </Box>
                              </Box>
                              <ListItemIcon className="template-icon">                             
                                <Radio
                                  checked={
                                    this.state.chooseTemplateChecked === project.id
                                  }
                                  tabIndex={-1}
                                />
                              </ListItemIcon>
                            </ListItem>
                          );
                        })}
                      </List>
                    </RadioGroup>
                  </Box>
                </Box>
              </Box> 
              <Box className="modal-footer">
                <Button onClick={ () => {
                  this.duplicateProject();
                  this.handleCloseUseExisting()
                  }
                  } className={`primary-btn ${classes.primaryButton}`} data-test-id="duplicateProject">
                  {this.t(`${configJSON.createProjectTxt}`)}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )
    }
    actualAndProjectedTxt = (classes:any) => {
      return(
        <Box className="legends-wrapper">
        <Box className="slider-legends">
          <Box className="legends-label">{this.t(`${configJSON.actualTxt}`)}</Box>
          <Box className="legends-dots actual"></Box>
        </Box>
        <Box className="slider-legends">
          <Box className="legends-label">{this.t(`${configJSON.projectedTxt}`)}</Box>
          <Box className="legends-dots"></Box>
        </Box>
       </Box>
      )
      
    }

    renderCloseDate = (date: any) => {
        if (date === null) {
          return "-";
        }
        return moment(date).format("MMM DD, YYYY");
      };

      moveTaskFromProject=(classes:any)=>{
        return(
          <Modal
            open={this.state.isMoveTaskProject}
            onClose={this.moveTaskProjectModalClose}
            aria-describedby="modal-modal-description"
            className={classes.modalDialog}
            aria-labelledby="modal-modal-title"
            >
              <Box className={`${classes.modalDialogBox} move-tasklist-dialog`}>
                <Box className="modal-dialoginner 1">
                  <Box className="modal-heading">
                    <Typography component="h2" className="modal-title">
                      {this.t(`${configJSON.moveTasklistProjectTitle}`)}
                    </Typography>
                     <Box
                      className="close-icon"
                       
                      onClick={this.moveTaskProjectModalClose}
                      data-test-id="assigneeModalClose"
                    >
                      <CloseIcon />
                     </Box>
                  </Box>
                  <Box className="modal-description">
                    <Box className="sub-txt" component="p">
                    {this.t(`${configJSON.moveTasklistProjectSubTitle}`)}
                    </Box>              
                    <Box className="tasklist-tabwrapper">
                  <Box>
                    <Tabs
                      aria-label="simple tabs example"
                      onChange={this.setTabsViewValue}
                      value={this.state.viewValueOfTabs}
                      data-test-id="viewTaskBottomTabs1"
                    >
                      <Tab label={this.t(`${configJSON.projectsTxt}`)} {...viwA11yProps(0)} />
                      <Tab label={this.t(`${configJSON.tasklistsTxt}`)} {...viwA11yProps(1)} />                  
                    </Tabs>
                  </Box>
                  <TabPanel value={this.state.viewValueOfTabs} index={0}>
                  <Box className="modal-form" component="form">
                      <Box className="form-info-wrapper">
                        <RadioGroup
                          aria-label="templatelist"
                          name="templatelist1"
                        >
                          <List className="move-tasklist-list 1">
                            <ListSubheader className="multiselect-membres 1">
                              <Box className="total-member 1">
                                {this.t(`${configJSON.currentProjectTxt}`)}
                              </Box>
                            </ListSubheader>
                            <ListItem
                              className="template-li" role={undefined} key={this.props.projectId}
                            >
                              <Box className="template-left">
                                <Box className="name 1">
                                  {this.state.currentProject?.attributes.title}
                                </Box>
                                <Box className="sub-txt 1">
                                  {this.state.currentProject?.attributes.category?.category}
                                </Box>
                              </Box>
                            </ListItem>
                          </List>
                        </RadioGroup>
                      </Box>
                      <Box className="form-info-wrapper 2">
                        <RadioGroup
                          aria-label="templatelist"
                          name="templatelist1"
                        >
                          <List className="move-tasklist-list 2">
                            <ListSubheader className="multiselect-membres 2">
                              <Box className="total-member 2">
                                {this.t(`${configJSON.otherProjectsTxt}`)}
                              </Box>
                            </ListSubheader>
                            {this.props.projectList.filter((x)=>x.id !== this.state.currentProject?.id).map((value:any, index: number) => {
                              return (
                                <ListItem
                                  className="template-li" 
                                  onClick={this.moveTaskGroupList(value.id)}
                                  role={undefined}
                                  key={value.id}
                                  data-test-id="moveTasklistBtn"
                                >
                                  <Box className="template-left">
                                    <Box className="name 2">
                                    {value.attributes.title}
                                  </Box>
                                    <Box className="sub-txt 2">
                                    {value.attributes.category?.category}
                                  </Box>
                                  </Box>
                                  <ListItemIcon className="template-icon 2">
                                    <Radio
                                      tabIndex={-1}                                
                                      checked={this.state.moveTaskChecked === value.id}
                                    />
                                  </ListItemIcon>
                                </ListItem>
                              );
                            })}
                          </List>
                        </RadioGroup>
                      </Box>
                    </Box>
                  </TabPanel>
                  <TabPanel value={this.state.viewValueOfTabs} index={1}>
                  <Box className="modal-form" component="form">
                      <Box className="form-info-wrapper">
                        <RadioGroup
                          aria-label="templatelist "
                          name="templatelist1 "
                        >
                          <List className="move-tasklist-list">
                            <ListSubheader className="multiselect-membres">
                              <Box className="total-member">
                                {this.t(`${configJSON.currentTasklistTxt}`)} 
                              </Box>
                            </ListSubheader>
                            <ListItem
                              className="template-li" role={undefined} key={this.props.projectId} 
                            >
                              <Box  className="template-left">
                                <Box className="name 3">
                                  {this.state.projectTask?.attributes.task_list_name}
                                </Box>
                                <Box className="sub-txt 3">
                                  {this.state.projectTask?.attributes.project_title}
                                </Box>
                              </Box>
                            </ListItem>
                          </List>
                        </RadioGroup>
                      </Box>
                      <Box className="form-info-wrapper ">
                        <RadioGroup
                          aria-label="templatelist "
                          name="templatelist1 "
                        >
                          <List className="move-tasklist-list ">
                            <ListSubheader className="multiselect-membres ">
                              <Box className="total-member">
                                {this.t(`${configJSON.otherTasklistTxt}`)}
                              </Box>
                            </ListSubheader>
                            {this.state.dataOfTaskList.filter((x)=>x.id !== this.state.projectTask?.id).map((taskListValue:any, index: number) => {
                              return (
                                <ListItem
                                  className="template-li" key={taskListValue.id} role={undefined}
                                  data-test-id={`movedTasklistEvent-${index}`}
                                  onClick={this.movedTasklistEvent(taskListValue.attributes.account_block_task_list_id)}
                                >
                                  <Box
                                    className="template-left"                           
                                  >
                                    <Box className="name">
                                    {taskListValue.attributes.task_list_name}
                                  </Box>
                                    <Box className="sub-txt">
                                    {taskListValue.attributes.title}
                                  </Box>
                                  </Box>
                                  <ListItemIcon className="template-icon">
                                    <Radio
                                      tabIndex={-1}
                                      checked={this.state.movedTasklist === taskListValue.attributes.account_block_task_list_id}
                                    
                                    />
                                  </ListItemIcon>
                                </ListItem>
                              );
                            })}
                          </List>
                        </RadioGroup>
                      </Box>
                    </Box>
                  </TabPanel>              
                </Box>
                  </Box>
                  <Box className="modal-footer">                 
                    <Button
                      className={`primary-btn ${classes.primaryButton}`}
                      title="Save"
                      onClick={this.moveTasklistProjectHandler}
                      data-test-id="moveTasklistProjectHandler"
                    >
                     {this.t(`${configJSON.txtSave}`)}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Modal>
        )
      }

    stageStatusClassMap = (dealStatusStage: string) => {
        if (dealStatusStage === "Initiated") {
          return <Box className="status sky-blue"></Box>;
        } else if (dealStatusStage === "sent proposal") {
          return <Box className="status orange"></Box>;
        } else if (dealStatusStage === "follow up") {
          return <Box className="status yellow"></Box>;
        } else if (dealStatusStage === "won") {
          return <Box className="status green"></Box>;
        } else if (dealStatusStage === "lost") {
          return <Box className="status black"></Box>;
        }
      };

      addDealModalOpen=(classes:any)=>{  
        let listOfDeals;
        const { allDealsData, resultsOfSearch } =
          this.state;
        if (this.state.isSearching) {
          listOfDeals = resultsOfSearch;
        } else {
          listOfDeals = allDealsData;
        } 
        return(
          <Modal
            onClose={this.handleCloseAddDealsModal}
            open={this.state.addDealShow}
            aria-describedby="modal-modal-description"
            aria-labelledby="modal-modal-title"
            className={classes.modalDialog}
            >
              <Box className={`${classes.modalDialogBox} move-tasklist-dialog`}>
                <Box className="modal-dialoginner">
                  <Box className="modal-heading">
                    <Typography component="h2" className="modal-title" >
                      {this.t(`${configJSON.chooseDealsTxt}`)}
                    </Typography>
                     <Box
                      onClick={this.handleCloseAddDealsModal}
                      className="close-icon"
                      data-test-id="handleCloseAddDealsModal"
                    >
                      <CloseIcon />
                     </Box>
                  </Box>
                  <Box className="modal-description">
                    <Box component="p" className="sub-txt">
                    {this.t(`${configJSON.chosseDealsSubTxt}`)}
                    </Box>
                    <Box className="modal-form" component="form">
                      <Box className="form-info-wrapper">
                        <RadioGroup
                          aria-label="templatelist"
                          name="templatelist1"
                        >
                          <List className="move-tasklist-list">
                          <Box className="search-wrapper">
                          <Box className="search-box">
                            <CustomInputWeb
                              label=""
                              type="search"
                              value={this.state.valueOfSeach}
                              placeholder={this.t(`${configJSON.searchPlaceholder}`)}
                              onChange={this.handleDealSearch}
                              startAdornment={
                                <InputAdornment position="start">
                                  <SearchIcon style={{ color: "#225980" }} />
                                </InputAdornment>
                              }
                              data-test-id="searchfieldBtn"
                            />
                            <Box className="sort-by" data-test-id="handleFiltersBtn"
                             onClick={this.handleDealFilter}
                             >
                              <img src={sortby} alt="bg-image" className="filter-image" />
                            </Box>
                          </Box>
                        </Box>
                            <ListSubheader className="multiselect-membres">
                              <Box className="total-member">
                              {listOfDeals.length > 1
                              ? `${
                                listOfDeals.length
                              } ${this.t(`${configJSON.dealsTxt}`)}`
                              : `${
                                listOfDeals.length
                              } ${this.t(`${configJSON.dealTxt}`)}`}
                              </Box>
                              <FormControl className="select-control sortby-control">
                                    <Select
                                    IconComponent={ExpandMoreIcon}
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "left",
                                        },
                                        transformOrigin: {
                                          horizontal: "left",
                                          vertical: "top",
                                        },
                                        getContentAnchorEl: null,
                                        classes: { paper: classes.dropdownStyle },
                                      }}
                                      onChange={this.handleDealsSort}
                                      displayEmpty                                 
                                      data-test-id="sortDeals"
                                      defaultValue={this.t(`${configJSON.txtSortBy}`)}
                                      renderValue={(renderedValue: any) => {
                                        return <Box>{renderedValue}</Box>;
                                      }}                                  
                                    >
                                      <MenuItem value="A-Z">{this.t(`${configJSON.aTozTxt}`)}</MenuItem>
                                      <MenuItem value="Z-A">{this.t(`${configJSON.zToaTxt}`)}</MenuItem>
                                    </Select>
                              </FormControl>                      
                            </ListSubheader> 
                              <Box className="line"></Box>
                          </List>
                        </RadioGroup>
                      </Box>
                      <Box className="form-info-wrapper">
                        <RadioGroup
                          name="templatelist1"
                          aria-label="templatelist"
                        >
                          <List className="move-tasklist-list">                  
                            {listOfDeals.map((dealValue:any, index: number) => {
                              return (
                                <ListItem
                                  className="template-li"
                                  key={dealValue.id}
                                  data-test-id="dealsValueBtn"
                                  onClick={this.dealsValue(dealValue.id)}
                                >
                                  <Box
                                    className="template-left"
                                  >
                                    <Box className="deal-container">
                                      <Typography className="deal-close-date">
                                        Close by:&nbsp;
                                        {this.renderCloseDate(dealValue.attributes.close_date)}
                                        </Typography>
                                        <Typography className="deal-close-date content-dots"></Typography>                                   
                                                <Box className="status-item">
                                                <Box className="status-name">
                                                  {this.t(`${dealValue.attributes.stage}`)}
                                                </Box>
                                                {this.stageStatusClassMap(
                                                  dealValue.attributes.stage
                                                )}
                                              </Box>
                                    </Box>
                                    <Box className="deal-name">
                                    {dealValue.attributes.name}
                                  </Box>
                                  <Box>                              
                                  <table>
                                    <tr>
                                      <th className="deal-owner">{this.t(`${configJSON.contactTxt}`)}</th>
                                      <th className="deal-owner">{this.t(`${configJSON.dealOwnerNameTxt}`)}</th>
                                    </tr>
                                    <tr>
                                      <td className="deal-owner-name">{dealValue.attributes.contacts.length > 0 && (
                                          <>
                                            {dealValue.attributes.contacts
                                              .map(
                                                (contactValue: {
                                                  id: string;
                                                  name: string;
                                                }) => contactValue.name
                                              )
                                              .join(", ")}
                                          </>
                                        )}</td>
                                      <td className="deal-owner-name">{dealValue.attributes.deal_owner.name}</td>                                
                                    </tr>                                
                                  </table>
                              </Box>                              
                                  </Box>
                                  <ListItemIcon className="template-icon-deal">
                                  <Box className="price-text">
                                    ${dealValue.attributes.total_amount}
                                  </Box>
                                    <Radio
                                      tabIndex={-1}                                
                                      checked={this.state.dealsSelected === dealValue.id}
                                    />
                                  </ListItemIcon>
                                </ListItem>
                              );
                            })}
                          </List>
                        </RadioGroup>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="modal-footer">                 
                    <Button
                      className={`primary-btn ${classes.primaryButton}`}
                      onClick={() => this.handleContinouDeal(this.state.dealsSelected)}
                      data-test-id="handleContinouDealBtn"
                    >
                     {this.t(`${configJSON.continoueBtnTxt}`)}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Modal>
        )
      }

      renderModalOfFilters = (classes:any) =>{ 
        const { allDealsData } =
          this.state;  
          const filterOwners = allDealsData.map((dealOwner: any) => ({
            value: dealOwner.attributes.deal_owner.id,
            label: dealOwner.attributes.deal_owner.name,
          }));
          const uniqueFiltersOwnerSet = new Set(
            filterOwners.map((item: any) => item.value)
          );
      
         const uniqueFiltersOwner = Array.from(uniqueFiltersOwnerSet).map((value) =>
            filterOwners.find((item: any) => item.value === value)
          );
        const uniqueNameOfContactsSet = new Set(); 
        const uniqueContact: any = [];
    
        allDealsData.forEach((deal: any) => {
          const contacts = deal.attributes.contacts || [];
          contacts.forEach((contact: { id: string; name: string }) => {
            const contactId = contact.id;
            const contactName = contact.name;
    
            if (!uniqueNameOfContactsSet.has(contactName)) {
              uniqueNameOfContactsSet.add(contactName);
              uniqueContact.push({ id: contactId, name: contactName });
            }
          });
        });
    
        return(
        <Modal
          aria-labelledby="modal-modal-title"
          className={classes.modalFilterDialog}
          open={this.state.isFiltersOnDeal}
          aria-describedby="modal-modal-description"
          onClose={this.handleCloseFilterOnDeals}
        >
          <Box className={`view-modal-dialog ${classes.modalFilterDialogBox}`}>
            <Box className="modal-content">
              <Box className="modal-wrapper">
                <Box>
                  <Typography className="modal-headding">{this.t(`${configJSON.txtFilters}`)}</Typography>
                </Box>
                <Box className="reset-cross">
                  <Box
                    onClick={this.removeDealFilters}
                    className="reset-txt"
                    data-test-id="removeDealFilters"
                  >
                    {this.t(`${configJSON.txtReset}`)}
                  </Box>
                  <Box
                    onClick={this.handleCloseFilterOnDeals}
                    data-test-id="handleFiltersClose"
                    className="close-icon"
                  >
                    <CloseIcon className="close-icon" />
                  </Box>
                </Box>
              </Box>
              <Typography className="desc"></Typography>
                  <Typography className="filter-headding">{this.t(`${configJSON.stageTxt}`)}</Typography>
                  <FormControl
                    variant="outlined"
                    className="select-outer"
                  >
                    <InputLabel>
                      {this.t(`${configJSON.stageTxt}`)}
                    </InputLabel>
                    <Select
                      value={this.state.filterStageVal}
                      label={this.t(`${configJSON.chooseCategory}`)}
                      name="filterStageVal"
                      data-test-id="stage-filter"
                      onChange={this.handleInputFiltersChange}
                      IconComponent={ExpandMoreIcon}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        classes: { paper: classes.dropdownStyle },
                      }}
                    >                  
                      {configJSON.stageState.map((stageUnit: any) => {
                        return (
                          <MenuItem value={stageUnit.value} key={stageUnit.value} className="status-item">
                          <Box className="status-name">{this.t(`${stageUnit.name}`)}</Box>
                          <Box className={`status ${stageUnit.className}`}></Box>
                        </MenuItem>
                        )
                      })}
    
                    </Select>
                  </FormControl>      
                <Typography className="filter-headding">{this.t(`${configJSON.dealAmountTxt}`)}</Typography>
                <Box className="form-row">
                <Box className="form-col col6">
                <Box className="form-control">
                       <CustomInputWeb
                          type="number"
                          name="fromAmount"
                          value={this.state.fromAmount}
                          onChange={this.handleInputFiltersChange}                
                          label={this.t(`${configJSON.fromTxt}`)}
                          data-test-id="fromAmount"
                        />
                  </Box>
                  </Box>
                  <Box className="form-col col6">
                    <Box className="form-control">
                        <CustomInputWeb
                          type="number"
                          label={this.t(`${configJSON.toTxt}`)}
                          name="toAmount"
                          value={this.state.toAmount}
                          data-test-id="toAmount"
                          onChange={this.handleInputFiltersChange}
                        />
                   </Box>
                  </Box>
                  </Box>  
                  <Typography className="filter-headding">{this.t(`${configJSON.closeByDateTxt}`)}</Typography>
                  <Box className="form-row">
                <Box className="form-col col6">
                <Box className="form-control">          
                 <FormControl
                    variant="outlined"
                    className="select-outer"
                  >
                    <InputLabel>
                    {this.t(`${configJSON.closeByDateTxt}`)}
                    </InputLabel>
                    <Select
                      name="closeDate"
                      value={this.state.closeDate}
                      IconComponent={ExpandMoreIcon}
                      label= {this.t(`${configJSON.closeByDateTxt}`)}
                      onChange={this.handleInputFiltersChange}
                      data-test-id="closeDate"
                      MenuProps={{
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        classes: { paper: classes.dropdownStyle },
                      }}
                    > 
                     <MenuItem value="on_or_before">{this.t(`${configJSON.onOrBefore}`)}</MenuItem>
                     <MenuItem value="on_or_after">{this.t(`${configJSON.onOrAfter}`)}</MenuItem>
                    </Select>
                  </FormControl> 
                  </Box>
                  </Box>
                  <Box className="form-col col6">
                    <Box className="form-control">
                    <CustomDatePicker
                      onOpenPickNewDate={false}
                      currentDate=""
                      onChange={this.dateChangeFilter}               
                      placeholder={this.t(`${configJSON.selectDateFilter}`)}
                      data-test-id="select-date"
                      t={this.t}
                    />                 
                   </Box>
                  </Box>
                  </Box>                    
                  <Typography className="filter-headding">{this.t(`${configJSON.contactTxt}`)}</Typography>
                  <FormControl
                    className="select-outer"
                    variant="outlined"
                  >
                    <InputLabel>
                      {this.t(`${configJSON.selectContactTxt}`)}
                    </InputLabel>
                    <Select
                      name="contact"
                      value={this.state.contact}
                      label={this.t(`${configJSON.selectContactTxt}`)}
                      onChange={this.handleInputFiltersChange}
                      IconComponent={ExpandMoreIcon}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        classes: { paper: classes.dropdownStyle },
                      }}
                      data-test-id="contact"
                    >
                      {uniqueContact.map((aContact: any) => {
                        return (
                          <MenuItem key={aContact.id} value={aContact.id}>
                             {aContact.name}
                          </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>           
              
                  <Typography className="filter-headding">{this.t(`${configJSON.dealOwnerNameTxt}`)}</Typography>
                  <FormControl
                    variant="outlined"
                    className="select-outer"
                  >
                    <InputLabel>
                      {this.t(`${configJSON.dealOwnerNameTxt}`)}
                    </InputLabel>
                    <Select
                      name="dealOwner"
                      value={this.state.dealOwner}
                      onChange={this.handleInputFiltersChange}
                      IconComponent={ExpandMoreIcon}
                      label={this.t(`${configJSON.dealOwnerNameTxt}`)}
                      data-test-id="dealOwner"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        classes: { paper: classes.dropdownStyle },
                      }}
                    >
                      {uniqueFiltersOwner.map((dealOwner: any) => {
                        return (
                          <MenuItem
                          value={dealOwner.value}
                            key={dealOwner.value}
                            >
                            {dealOwner.label}
                            </MenuItem>
                        );
                       })}
                    </Select>
                  </FormControl>            
                <Box className="view-more-dropdown">
                                    <Box className="dropdown-menu-item">
                                      <Typography className="dropdown-menu-item-text">
                                        {this.t(`${configJSON.hasTasks}`)}
                                      </Typography>
                                      <Switch
                                        name="dealsHasTask"
                                        checked={this.state.dealsHasTask}
                                        className="dropdown-menu-item-switch"
                                        data-test-id="hasTaskDeals"
                                        onChange={this.handleChecked}
                                      />
                                    </Box>
                                    <Box className="dropdown-menu-item">
                                      <Typography className="dropdown-menu-item-text">
                                        {this.t(`${configJSON.hasAttachment}`)}
                                      </Typography>
                                      <Switch
                                        name="hasAttachment"
                                        className="dropdown-menu-item-switch"
                                        onChange={this.handleChecked}
                                        checked={this.state.hasAttachment}
                                      />
                                    </Box>
                                    <Box className="dropdown-menu-item">
                                      <Typography className="dropdown-menu-item-text">
                                        {this.t(
                                          `${configJSON.hasAssociatedProduct}`
                                        )}
                                      </Typography>
                                      <Switch
                                        name="hasAssociatedProduct"
                                        checked={this.state.hasAssociatedProduct}
                                        className="dropdown-menu-item-switch"
                                        onChange={this.handleChecked}
                                      />
                                    </Box>
                     </Box>
                
                  <Button className={`primary-btn2 ${classes.primaryButton}`} 
                   onClick={this.applyFilters}
                   data-test-id="applyFilters" 
                   >
                    {this.t(`${configJSON.txtApplyFilters}`)}</Button>
            </Box>
          </Box>
        </Modal>
      )};

    createTaskModal=(classes:any)=>{
      const popOverOpen = Boolean(this.state.popoveAnchorEl);
      const popoverId = popOverOpen ? "simple-popover" : undefined;
      const timerStandOpen = Boolean(this.state.timerStandAnchorEl);
      const timerStandID = timerStandOpen ? "simple-popper" : undefined;
      const symbolOfCurrency = getCurrencySymbol(this.userToken);
      return(
        <Box className={classes.createTaskWrapper}>
            <Box className="task-actions">
              {this.state.tasksInDraft.map((taskInDraft: any) => {
                return (
                    <Button
                      className={`secondary-btn ${classes.secondaryButton}`}                   
                    >
                      <Box
                        component="span"
                        className="draft-name"
                        data-test-id={`taskInDraftBtn`}
                        onClick={(e: any) => this.openTaskInDraft(e, taskInDraft.id)}
                      >
                        <img
                          className="draft-img"
                          src={fileTree}
                          alt="fileTree"
                        />
                        {taskInDraft.title == "" ? "Draft" : taskInDraft.title}
                      </Box>
                       <Box
                        data-test-id="deleteDraftTaskBtn"
                        className="close"
                        onClick={(e: any) => this.deleteDraftTask(taskInDraft.id)}
                      >
                        <img src={closeGray} alt="closeGray" />
                       </Box>
                    </Button>
                );
              })}
              <Button
                aria-describedby={popoverId}
                className={`primary-btn ${classes.primaryButton}`}
                ref={this.popoverRef}
                onClick={this.popoverHandleClick}
                data-testid="createTaskBtn"
              >
                + {this.t(`${configJSON.txtCreateTask}`)}
              </Button>
            </Box>
            <Button style={{display:"none"}} data-test-id="selectedProducts" onClick={()=>this.getAllProductsFromInventory(["12"])}></Button>
            <Popover
              id={popoverId}
              anchorEl={this.state.popoveAnchorEl}
              open={popOverOpen}
              onClose={this.popoverHandleClose}
              classes={{ paper: `${classes.createTaskBox}` }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}           
              onKeyDown={(event) => {this.handleEscTaskEvent(event)}}
              data-test-id="create-task-popover"
            >
              <Box
                onClick={this.popoverHandleClose}
                className="auth-back show-sm"
              >
                <i>
                  <svg
                    viewBox="0 0 24 24"
                    className="MuiSvgIcon-root"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                  </svg>
                </i>
              </Box>    
              <Box className="task-heading">
                <Box className="heading-left">
                  <Typography className="modal-title" component="h2">
                    {!this.state.isEditing ? this.t(configJSON.txtCreateTask) : this.t(configJSON.txtEditTask)}
                  </Typography>
                  <Box component="p" className="sub-txt">
                    {this.state.isEditing
                      ? this.t(configJSON.letsEditTaskSubTxt)
                      : this.t(configJSON.txtLetCreate)}
                  </Box>
                </Box>
                <Box className="heading-right">
                   <Box
                    onClick={this.saveAsTaskInDraft}
                    data-test-id="saveAsTaskInDraft"
                    className="heading-icon hide-sm"
                     
                  >
                    <img alt="minimizeGray" src={minimizeGray}  />
                   </Box>
                   <Box
                    data-test-id="closeTaskPopover"
                    onClick={this.popoverHandleClose}
                    className="heading-icon hide-sm"
                  >
                    <img alt="closeGray" src={closeGray}  />
                   </Box>
                </Box>
              </Box>
              <Box className="task-description">
                <Box className="task-form" component="form">
                  <Box className="form-info-wrapper">
                    <Box className="form-row">
                      <Box className="form-col">
                        <CustomInputWeb
                          name="title"
                          type="text"
                          data-test-id="crateTaskTitle"
                          onChange={this.handleOnChange}
                          label={this.t(`${configJSON.txtTitle}`)}
                          errors={this.state.error.title}
                          value={this.state.title}
                          isRequired={true}
                        />
                      </Box>
                    </Box>
                    <Box className="form-row">
                    <Box className="form-col col6">
                      <FormControl className="select-outer" variant="outlined">
                        <InputLabel id="project">{this.t(`${configJSON.projectTxt}`)}</InputLabel>
                        <Select
                          id="project"
                          name="project"
                          labelId="project"
                          value={this.state.projectId > 0 ?this.state.projectId:undefined}
                          label={this.t(`${configJSON.projectTxt}`)}
                          onChange={this.changeProjectHandler}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            classes: {
                              paper: `${classes.dropdownStyle} modal-dropdown`,
                            },
                          }}
                          IconComponent={ExpandMoreIcon}
                          disabled={this.props.projectId !== undefined}
                          data-test-id="projectSelect"
                        >
                          {this.props.projectList.map((proj) => {
                            return (
                              <MenuItem value={Number(proj.id)} key={proj.id}>
                                {proj.attributes.title}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box className="form-col col6">
                      <FreeSoloCreateOption
                        id="job-id"
                        options={this.state.projectTaskList.map((val) => {
                          return {
                            id: val.id,
                            title: val.attributes.name,
                          };
                        })}
                        addLinkTitle={this.t(`${configJSON.addNewTaskListTxt}`)}
                        addEditCallback={this.taskListAdd}
                        label={this.t(`${configJSON.labelTitleText}`)}
                        noOptionTitle={this.t(`${configJSON.noTaskListFoundTxt}`)}
                        value={this.state.taskListObject.name}
                        onChange={this.handleTaskList}
                        errorMsgNoRecord={this.t(`${configJSON.notCreatedTasklistTxt}`)}
                        errors={this.state.error.taskListName}
                        disabled={this.props.taskListId != undefined}
                        hideOtherOptions={true}
                        notRequired={true}
                        data-test-id="taskTasklist"
                      />
                    </Box>
                  </Box>
                    <Box className="form-row">
                      <Box className="form-col col6">
                        <Box className="form-control">
                          <CustomDatePicker
                            currentDate={this.state.due_date}
                            error={this.state.error.due_date}
                            t={this.t} 
                            onOpenPickNewDate={false}
                            required={false}
                            data-test-id="crateTaskDueDate"
                            placeholder={this.t(`${configJSON.txtDueDate}`)}
                            onChange={this.handleDueDate}
                            inPortal={true}          
                            showPastWarning={true}
                          />
                        </Box>
                      </Box>
                      <Box className="form-col col6">
                        <Box className="form-control">
                          <CustomDatePicker
                            placeholder={this.t(`${configJSON.txtDeadline}`)}
                            currentDate={this.state.deadline_date}
                            onOpenPickNewDate={false}
                            onChange={this.handleDeadlineDate}
                            showPastWarning={true}
                            data-test-id="crateTaskDeadline"
                            inPortal={true}
                            required={false}
                            t={this.t}
                            range={false}
                            isDeal={false}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box className="form-row">
                      <Box className="form-col">
                        <CustomInputWeb
                          isMultiline
                          type="text"
                          rows={4}
                          label={this.t(`${configJSON.txtDescription}`)}
                          name="description"
                          value={this.state.description}
                          data-test-id="crateTaskDesc"
                          onChange={this.handleOnChange}
                        />                      
                      </Box>
                    </Box>                              
                    <Box className="form-row">
                    <Box className="wrap-option">                      
                       {!this.state.showAddProductsAssets && this.state.productsSelectedId.length === 0 && (
                         <Button
                           data-test-id="handleShowAssetsBtn"
                           className="btn-add-option"
                           onClick={this.handleInventoryAssetsShow}
                         >
                           + {this.t(`${configJSON.addProductAssestsTxt}`)}
                         </Button>
                       )}
                    </Box>
                    <Box className="wrap-option">                      
                       {!this.state.showAddExpendituresBtn && (
                         <Button
                           data-test-id="handleShowAddExpenditures"
                           className="btn-add-option"
                           onClick={this.handleShowAddExpenditures}
                         >
                           + {this.t(`${configJSON.addExpenditures}`)}
                         </Button>
                       )}
                    </Box>
                    <Box className="wrap-option">                      
                       {!this.state.addDealShow && this.state.dealsSelected===""  && (
                         <Button
                           className="btn-add-option"
                           onClick={this.handleShowAddDealsModal}
                           data-test-id="handleShowDealBtn"
                         >
                           + {this.t(`${configJSON.addDealTxt}`)}
                         </Button>
                       )}
                    </Box>
                    </Box>
                    {this.state.showAddExpendituresBtn && 
                    <Box className="form-row">
                      <Box className="form-col">
                      <Typography className="expenditure-title">
                      {this.t(`${configJSON.addExpenditures}`)}
                      </Typography>
                        <CustomInputWeb
                          name="expenditures"
                          type="number"                       
                          autoFocus={true}                     
                          placeholder={this.t(`${configJSON.expendituresText}`)}  
                          onChange={this.handleOnChange}
                          value={this.state.expenditures}
                          onKeyDown={(e: any) => {
                            if (["e", "E", "-"].includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          data-test-id="expenditures"
                          startAdornment={
                            <InputAdornment position="start">
                              <AttachMoneyIcon />
                            </InputAdornment>
                          }
                        />                      
                      </Box>
                    </Box>}  
                    {this.state.addDealShow && this.addDealModalOpen(classes)}
                    {this.state.isFiltersOnDeal && this.renderModalOfFilters(classes)}
                    {this.state.showAssetsOfInventory &&   
                    <AddInventoryAndAssests
                    closeProductsModal={this.handleInventoryAssetsShowClose}
                      openProductsModal={this.state.showAssetsOfInventory}
                      selectedDealProducts={this.selectedProducts}
                      buttonTxt={this.t("Continue")}
                      data-test-id="is-save-product"
                      classes={this.props.classes}
                    />}                 
                     {this.state.viewProductsSelected && (
                      <ProductInventoryViewModal
                        modalOpen={this.state.viewProductsSelected}
                        selectedProductsData={this.state.selectedProductsData}
                        modalClose={this.handleViewInventoryAssetsClose}
                        currencySymbol={symbolOfCurrency}
                        storageData={this.userToken}
                        openEdit={this.handleInventoryAssetsShow}
                        t={this.t}
                        classes={classes}
                        data-test-id="ProductInventoryViewModal"
                      />
                    )}
                    {this.state.productsSelectedId.length > 0 &&  <Box className="info-group favorite-product">           
                    <Box className="group-form favorite-box" component="form">
                      <Box className="category-left-box">
                        <Typography className="left-title">
                        {this.state.productsSelectedId.length} {this.t(`${configJSON.inventoryItemAddedTxt}`)}
                        </Typography>               
                        <Typography className="total-amount-txt">
                        {generateSummary(this.state.productNameAndCount, this.t)}
                        </Typography>
                      </Box>
                      <Box className="category-right-box">                      
                        <Button
                          className="arrow-btn"
                          onClick={this.handleInventoryAssetsView}
                          data-test-id="viewProductsBtn"
                        >
                           <ArrowForwardIosIcon className="btn-icon" />
                        </Button>
                      </Box>
                </Box>
            </Box>}
                        {this.state.dealsSelected &&   <Box className="info-group favorite-product">           
                        <Box className="group-form favorite-box" component="form">
                          <Box className="category-left-box">
                            <Typography className="left-title">
                            {this.state.singleDataDeal.attributes?.name}
                            </Typography>               
                            <Typography className="total-amount-txt">
                            To [{this.state.singleDataDeal.attributes?.contacts.length > 0 && (
                                            <>
                                              {this.state.singleDataDeal.attributes?.contacts
                                                .map(
                                                  (contact: {
                                                    id: string;
                                                    name: string;
                                                  }) => contact.name
                                                )
                                                .join(", ")}
                                            </>
                                          )}]; Close by {this.renderCloseDate(this.state.singleDataDeal.attributes?.close_date)}
                            </Typography>
                          </Box>
                          <Box className="category-right-box">
                            <Typography className="amount-txt">
                            {symbolOfCurrency}{getNumberFormat(this.userToken, this.state.singleDataDeal.attributes?.total_amount)}                       
                            </Typography>
                            <Button
                              className="arrow-btn"
                              onClick={this.handleCloseAddDealsModal}
                              data-test-id="close-deal"
                            >
                              <CloseIcon className="btn-icon" />
                            </Button>
                          </Box>
                    </Box>
                </Box>}
                  </Box>              
                  <Box className="form-info-wrapper">
                    <Typography className="form-heading" variant="h2">
                    {this.t(`${configJSON.attachmentsTxt}`)}
                    </Typography>
                    <FileDrop
                      onDrop={() => this.handleOnDrop}
                      onDragOver={this.dragFileEnter}
                      onDragLeave={this.dragFileLeave}
                      data-test-id="handleOnDropFile"
                    >
                      <Box
                        className={`${classes.fileUploadWrapper} `}
                        onClick={this.onBrowseEvent}
                        data-test-id="onBrowse"
                      >
                        <form ref={this.formRef}>
                          <input
                            type="file"
                            name="upload-photo"
                            onChange={this.handleOnDrop}
                            accept="*"
                            ref={this.fileRef}
                            multiple
                            data-test-id="add-task-attachment"
                            hidden
                          />
                        </form>
                        <Box className="file-upload-block">
                          <Box className="file-subtxt" component="p">
                            {this.t(`${configJSON.clickHereTxt}`)}
                            <Box component="span" className="browse-link">
                              {this.t(`${configJSON.browseTxt}`)}
                            </Box>
                            {this.t(`${configJSON.yourPictureTxt}`)}
                          </Box>
                          <Box className="upload-icon">
                            <img src={uploadImg} alt="uploadImg" />
                          </Box>
                          <Button className={`secondary-btn2 ${classes.secondaryButton}`}>
                            {this.t(`${configJSON.addAttachmentsTxt}`)}
                          </Button>
                        </Box>
                      </Box>
                    </FileDrop>
                    {this.state.filesUploaded.length > 0 && (
                   <List className="attachlist-ul">
                      {this.state.filesUploaded.map((file: any, index: any) => {
                        return(
                          <ListItem
                          role={undefined}
                          disableRipple
                          className="attachlist-li"                        
                          dense
                          key={file.name}
                          button
                        >
                          <ListItemIcon className="attachlist-icon">
                            <img src={checkGreen} alt="checkGreen" />
                          </ListItemIcon>
                          <ListItemText
                            className="attachlist-label"                         
                            primary={file.name || file.filename}
                          />
                          <ListItemSecondaryAction>
                             <Box   className="tasklist-link">
                              <img src={closeGray} alt="closeGray" data-test-id="removeFileUPload" 
                              onClick={()=>this.removeFileUPloadEvent(index)}
                              />
                             </Box>
                          </ListItemSecondaryAction>
                        </ListItem>
                      )})}
                    </List>
                  )}                  
                  </Box>
                  <Box>
                    <p style={{ textAlign:"right", padding: "20px", border:"2px dashed #e5e5e5", color:"#AFBACA" }}>{this.t("Projected Labor Cost")}:<span style={{color:"#2B2B2B"}}>{symbolOfCurrency}{getNumberFormat(this.userToken, this.LabourCostCalculation())}</span></p>
                  </Box>
                </Box>
              </Box>
              <Box className="createtask-bottom">
                <Box className="createtask-left">
                  <Box className="outerbutton-wrapper">
                     <Box
                      className="outer-link dropmenu-btn"
                      data-test-id="crateTaskPriority"
                      aria-haspopup="true"
                      aria-controls="simple-menu"
                       
                      onClick={this.handleDropMenu}
                    >
                      <Box
                        className={`priority ${
                          this.state.priority == 1 || this.state.priority == 2
                            ? "active-priority"
                            : ""
                        }`}
                      >
                        {this.state.priority == 1 ? "!!" : "!!!"}
                      </Box>
                     </Box>
                    <Menu
                      className={classes.dropDropdown}
                      open={Boolean(this.state.createTaskanchorEl)}
                      transformOrigin={{
                        horizontal: "left",
                        vertical: "bottom",
                      }}
                      keepMounted
                      anchorEl={this.state.createTaskanchorEl}
                      getContentAnchorEl={null}
                      onClose={() => this.dropMenuClose(this.state.priority)}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      id="simple-menu"      
                    >
                      <MenuItem
                        onClick={() => this.dropMenuClose(2)}
                        data-test-id="CreateTaskPriorityEmergency"
                      >
                        <Box className="priority-name">{this.t(`${configJSON.txtEmergency}`)}</Box>
                        <Box className="priority-icon">!!!</Box>
                      </MenuItem>
                      <MenuItem
                        data-test-id="CreateTaskPriorityCritical"
                        onClick={() => this.dropMenuClose(1)}
                      >
                        <Box className="priority-name">{this.t(`${configJSON.txtCritical}`)}</Box>
                        <Box className="priority-icon">!!</Box>
                      </MenuItem>
                      <MenuItem
                        onClick={() => this.dropMenuClose(0)}
                        data-test-id="CreateTaskPriorityNone"
                      >
                        <Box className="priority-name">{this.t(`${configJSON.txtNone}`)}</Box>
                      </MenuItem>
                    </Menu>
                  </Box>
                  <Box className="outerbutton-wrapper">
                     <Box
                      className="outer-link"
                      data-test-id="CreateTaskHours"
                      onClick={this.toggleHoursModal}
                      aria-describedby={timerStandID}
                       
                    >
                      <img alt="timerSand" src={timerSand}/>
                      <Box className="time">{this.state.taskHours}</Box>
                     </Box>
                    {this.state.showHoursModal && (
                      <Box className={classes.timestandDropbox} id={timerStandID}>
                        <Box className="timestand-inner">
                          <Box className="timestand-heading">{this.t(`${configJSON.txtTaskHours}`)}</Box>
                          <Box component="form">
                            <Box className="time">
                              <TextField
                                placeholder="00"
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                inputProps={{
                                  step: 1,
                                  min: 0,
                                  max: 999,
                                }}
                                name="hours"
                                id="standard-basic"
                                value={this.state.hours}
                                label=""
                                type="number"
                                onChange={this.hoursChange}
                                data-test-id="CreateTaskHoursInput"
                              />
                              <Box component="span" className="colon-txt">
                                :
                              </Box>
                              <TextField
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                placeholder="00"
                                label=""
                                id="standard-basic2"
                                type="number"
                                name="minutes"
                                onChange={this.minutesChange}
                                value={this.state.minutes}
                                data-test-id="CreateTaskMinutesInput"
                              />                           
                            </Box>
                          </Box>
                        </Box>
                        <Box className="timestand-actions">
                           <Box                           
                            className="secondary-link"
                            onClick={this.toggleHoursModal}
                            data-test-id="CreateTaskHoursCancel"
                          >
                            {this.t(`${configJSON.txtCancel}`)}
                           </Box>
                           <Box                           
                            onClick={this.setHours}
                            className="primary-link"
                            data-test-id="CreateTaskHoursSave"
                          >
                            {this.t(`${configJSON.txtSave}`)}
                           </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box className="outerbutton-wrapper">
                     <Box
                        data-test-id="createTaskassignee"
                        className="outer-link"
                        onClick={this.handleAssignee}
                      >
                      <img alt="accountPlus"  src={accountPlus} />
                      <Box className="assignee-list">
                        {this.displayAssigneeOptions()
                          .filter((y: any) => this.state.assignees.includes(y.id))
                          .slice(0, 3)
                          .map((user: any) => {
                            return (
                              <Box className="assignee-bubble" key={user.id}>
                                {user.initials}
                              </Box>
                            );
                        })}
                        {this.state.assignees.length > 3 && (
                          <Box className="assignee-bubble">
                            +{this.state.assignees.length - 3}
                          </Box>
                        )}
                      </Box>
                     </Box>
                  </Box>
                </Box>
                <Box className="createtask-right">
                  <Button
                    onClick={this.createTaskBtn}
                    className={`primary-btn ${classes.primaryButton}`}
                    data-test-id="saveTaskBtn"
                  >
                    {this.state.isEditing ? this.t(configJSON.updateTaskTxt) : `+ ${this.t(configJSON.txtCreateTask)}`}
                  </Button>
                </Box>
              </Box>
            </Popover>
        </Box>
      )
    }

    assigneeChangeModal = (classes:any) => {
      const teamsOption = this.displayTeamsOptions().filter(
        (team) => !this.state.taskSelectedAssignees.includes(team.id));
        
      const assigneesOptions = this.displayAssigneeOptions().filter(
        (assignee) => !this.state.taskSelectedAssignees.includes(assignee.id)
      );
      return (
      <Modal
        aria-labelledby="modal-modal-title"
        open={this.state.isActiveAssigneeChanges}
        aria-describedby="modal-modal-description"
        onClose={this.handleAssigneeModalClose}
        className={classes.modalDialog}
      >
        <Box className={classes.modalDialogBox}>
                <Box className="modal-dialoginner">
                  <Box className="modal-heading">
                    <Typography className="modal-title" component="h2">
                      {this.state.taskSelectedAssignees.length} {this.t(`${configJSON.txtMemberSelected}`)}
                    </Typography>
                     <Box
                      onClick={this.handleAssigneeModalClose}
                      className="close-icon"
                      data-test-id="assigneeModalCloseBtn"
                    >
                      <CloseIcon />
                     </Box>
                  </Box>
                  <Box className="modal-description">
                    <Box component="p" className="sub-txt">
                      {this.t(`${configJSON.txtAssigneeDescription}`)}
                    </Box>
                    <Box className="modal-form" component="form">
                      <List className="assignee-multiSelect-list selected-list">
                        <ListSubheader>
                          <CustomInputWeb
                            fullWidth={true}
                            placeholder={this.t(`${configJSON.txtSearchAssignees}`)}
                            name="assigneeSearchText"
                            autoFocus={true}
                            onChange={this.handleOnChange}
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            }
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        <ListSubheader className="multiselect-subheader">
                        {this.t(`${configJSON.txtAssignees}`)}
                           <Box
                            className="tasklist-link"
                            onClick={this.removeSelectedAssignees}
                            data-test-id="clearAssigneesBtn"
                          >
                           {this.t(`${configJSON.txtRemoveAllAssignees}`)}
                           </Box>
                        </ListSubheader>                     
                        {this.displayAssigneeOptions()
                          .filter((x: any) =>
                            this.state.taskSelectedAssignees.includes(x.id)
                          )
                          .map((singleAssignee: any) => (
                            <ListItem
                                onClick={() => this.handleAssigneeChange(singleAssignee.id)}
                                value={singleAssignee.id}
                                key={singleAssignee.id}
                            >
                              <Box className="assignee-detail">
                                <Box className="assignee-left">
                                  <Box className="assignee-profile">
                                    {singleAssignee.initials}
                                  </Box>
                                  <Box className="assignee-info">
                                    <Box className="assignee-name">
                                      {singleAssignee.title}
                                    </Box>
                                    <Box className="assignee-email">
                                      {singleAssignee.email}
                                    </Box>
                                    {singleAssignee.job_role && <Chip
                                      label={singleAssignee.job_role}
                                      className={classes.chipButton}
                                    />} 
                                  </Box>
                                </Box>
                                <Box className="assignee-right">
                                  <Box className="assinee-selected">
                                    <img
                                      alt="checkBoxEmpty"
                                      src={checkBoxEmpty}
                                      className="normal-img"
                                    />
                                    <img
                                      alt="checkBoxFilled"
                                      src={checkBoxFilled}
                                      className="selected-img"
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </ListItem>
                          ))}
                          {this.displayTeamsOptions()
                          .filter((x: any) =>
                            this.state.taskSelectedAssignees.includes(x.id)
                          )
                          .map((team: any) => (
                            <ListItem onClick={() => this.handleAssigneeChange(team.id)} value={team.id} key={team.id}>
                              <Box className="assignee-detail">
                                <Box className="assignee-left 1">
                                  <Box className="assignee-profile 1">
                                    {team.initials}
                                  </Box>
                                  <Box className="assignee-info">
                                    <Box className="assignee-name">
                                      {team.title}
                                    </Box>
                                    <Box className="assignee-email">
                                      {team.users} {team.users > 1 ? this.t(`${configJSON.membersTxt}`) : this.t(`${configJSON.memberTxt}`)}
                                    </Box>
                                  </Box>
                                </Box>
                                <Box className="assignee-right">
                                  <Box className="assinee-selected">
                                    <img
                                      src={checkBoxEmpty}
                                      alt="checkBoxEmpty"
                                      className="normal-img"
                                    />
                                    <img
                                      src={checkBoxFilled}
                                      alt="checkBoxFilled"
                                      className="selected-img"
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </ListItem>
                          ))}
                        <ListSubheader className="multiselect-subheader">
                        {this.t(`${configJSON.txtUnassigned}`)}
                        </ListSubheader>
                      </List>
                      <Box className="tasklist-tabwrapper">
                        <Box className="assignee-tabs">
                          <Tabs
                            onChange={this.handleAssigneeTabsValue}
                            aria-label="simple tabs example"
                            value={this.state.assigneesTabValue}
                            data-test-id="updateAssigneeTabsBtn"
                          >
                            <Tab label={this.t(`${configJSON.teamsTxt}`)} {...assigneeA11yProps(0)} />
                            <Tab label={this.t(`${configJSON.membersTxt}`)} {...assigneeA11yProps(1)} />
                          </Tabs>
                        </Box>
  
                        <TabPanel
                         value={this.state.assigneesTabValue} 
                         index={0}>
                          <List className="assignee-multiSelect-list">
                            <ListSubheader className="multiselect-membres">
                              <Box className="total-member">
                                {teamsOption.length
                                }&nbsp;
                               {teamsOption.length > 1 ? this.t(`${configJSON.teamsTxt}`): this.t(`${configJSON.teamTxt}`)}
                              </Box>
                              <FormControl className="select-control sortby-control">
                                <Select
                                IconComponent={ExpandMoreIcon}
                                defaultValue={this.t(`${configJSON.txtSortBy}`)}
                                  MenuProps={{
                                    anchorOrigin: {
                                      horizontal: "left",
                                      vertical: "bottom",
                                    },
                                    transformOrigin: {
                                      horizontal: "left",
                                      vertical: "top",
                                    },
                                    getContentAnchorEl: null,
                                    classes: { paper: classes.dropdownStyle },
                                  }}
                                  data-testid="sortTeams"
                                  displayEmpty
                                  onChange={this.handleSortByAssignee}
                                  renderValue={(value: any) => {
                                    return <Box>{value}</Box>;
                                  }}
                                >
                                  <MenuItem value="A-Z">{this.t(`${configJSON.aTozTxt}`)}</MenuItem>
                                  <MenuItem value="Z-A">{this.t(`${configJSON.zToaTxt}`)}</MenuItem>
                                </Select>
                              </FormControl>
                            </ListSubheader>
                            {this.sortRecords(this.displayTeamsOptions())
                              .filter(
                                (x: any) =>
                                  !this.state.selectedTeamList.includes(x.id)
                              )
                              .map((sortOption: any) => (
                                <ListItem
                                  key={sortOption.id}
                                  onClick={() => this.handleAssigneeWithMemberId(sortOption.memberId, sortOption.id)}
                                  value={sortOption.id}
                                  data-test-id="handleAssigneeWithMemberId"
                                >
                                  <Box className="assignee-detail">
                                    <Box className="assignee-left">
                                      <Box className="assignee-profile">
                                        {sortOption.initials}
                                      </Box>
                                      <Box className="assignee-info">
                                        <Box className="assignee-name">
                                          {sortOption.title}
                                        </Box>
                                        <Box className="assignee-email">
                                          {sortOption.users} {sortOption.users > 1 ? this.t(`${configJSON.membersTxt}`) : this.t(`${configJSON.memberTxt}`)}
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box className="assignee-right">
                                      <Box className="assinee-unselected">
                                        <img
                                          src={checkBoxEmpty}
                                          className="normal-img"
                                          alt="checkBoxEmpty"
                                        />
                                        <img
                                          src={checkBoxFilled}
                                          alt="checkBoxFilled"
                                          className="selected-img"
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </ListItem>
                              ))}
                          </List>
                        </TabPanel>
                        <TabPanel
                         value={this.state.assigneesTabValue} 
                         index={1}>
                          {assigneesOptions.length !==0 ? <List className="assignee-multiSelect-list">
                            <ListSubheader className="multiselect-membres">
                              <Box className="total-member">
                                {assigneesOptions.length}&nbsp;
                                {assigneesOptions.length > 1 ? this.t(`${configJSON.membersTxt}`): this.t(`${configJSON.memberTxt}`)}
                              </Box>
                              <FormControl className="select-control sortby-control">
                                <Select
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      horizontal: "left",
                                      vertical: "top",
                                    },
                                    getContentAnchorEl: null,
                                    classes: { paper: classes.dropdownStyle },
                                  }}
                                  onChange={this.handleSortByAssignee}
                                  displayEmpty
                                  IconComponent={ExpandMoreIcon}
                                  data-testid="sortTeams"
                                  renderValue={(value: any) => {
                                    return <Box>{value}</Box>;
                                  }}                               
                                  defaultValue={this.t(`${configJSON.txtSortBy}`)}
                                >
                                  <MenuItem value="A-Z">{this.t(`${configJSON.aTozTxt}`)}</MenuItem>
                                  <MenuItem value="Z-A">{this.t(`${configJSON.zToaTxt}`)}</MenuItem>
                                </Select>
                              </FormControl>
                            </ListSubheader>
                            {this.sortRecords(this.displayAssigneeOptions())
                              .filter(
                                (assigneeOption: any) =>
                                  !this.state.taskSelectedAssignees.includes(assigneeOption.id)
                              )
                              .map((user: any) => (
                                <ListItem
                                    value={user.id}
                                    onClick={() => this.handleAssigneeChange(user.id)}
                                    key={user.id}
                                    data-test-id="handleAssigneeChangeBtn"
                                >
                                  <Box className="assignee-detail">
                                    <Box className="assignee-left">
                                      <Box className="assignee-profile">
                                        {user.initials}
                                      </Box>
                                      <Box className="assignee-info">
                                        <Box className="assignee-name">
                                          {user.title}
                                        </Box>
                                        <Box className="assignee-email">
                                          {user.email}                                      
                                        </Box>  
                                        {user.job_role && <Chip
                                           label={user.job_role}
                                           className={classes.chipButton}
                                        />}                                   
                                      </Box>
                                    </Box>
                                    <Box className="assignee-right">
                                      <Box className="assinee-unselected">
                                        <img
                                          src={checkBoxEmpty}
                                          alt="checkBoxEmpty"
                                          className="normal-img"
                                        />
                                        <img
                                          src={checkBoxFilled}
                                          className="selected-img"
                                          alt="checkBoxFilled"
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </ListItem>
                              ))}
                          </List> : <Typography className="not-found">{this.t(configJSON.memberNotFountError)}</Typography>
                          }
                          
                        </TabPanel>
                      </Box>
                    </Box>
                  </Box>
                </Box>
        </Box>
      </Modal>
      )
    }

    renderDateInformation = (dateLabel:string, date:string ,cssClass:string) => (
      <Box className={cssClass}>
        <Box className="label">{this.t(`${dateLabel}`)}: </Box>
        <Box className="value">
          {date ? moment(date).format("MMM DD , YYYY") : ""}
          <img className="icon" src={calendarViewIcon} alt="calendarViewIcon" />
        </Box>
      </Box>
    );
  
    renderAccordionOfDates = () => (
      <ListItem className="date-item-root">
        <Accordion className="accordion-block">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="open-content"
            id="open-header"
            className="accordion-heading"
          >
            <Box className="list-label">{this.t(`${configJSON.txtDates}`)}</Box>
            <Box className="list-description1">
              {this.renderDateInformation(configJSON.dueDate, this.state.projectTask?.attributes.due_date,"date-info")}
            </Box>
          </AccordionSummary>
          <AccordionDetails className="accordion-details">
            <Box className="list-description1">
              {this.renderDateInformation(configJSON.txtDeadline, this.state.projectTask?.attributes.deadline_date ,"date-info-1")}
            </Box>
          </AccordionDetails>
        </Accordion>
      </ListItem>
    );

    showTaskActivityLog = () => {
        const {classes} = this.props;
        return (
          <>
          {this.state.projectTask !==null && 
            <ActivityLog 
            classes={classes}
            activityId={this.state.projectTask?.id}
            activityType="task"
            taskPriority={this.state.projectTask.attributes.priority}
            activityTitle={this.state.projectTask?.attributes.title}
            data-test-id="task-logs"
            />
           }
       </>
            
        )
      }

    viewTaskModal = (classes: any) => {
      const taskPopOverOpen = Boolean(this.state.popoveAnchorEl);
      const popoverId = taskPopOverOpen ? "simple-popover" : undefined;
      const status = this.state.projectTask?.attributes.status;
      const time= this.state.projectTask?.attributes.hours;
      let displayTaskHours = "";
      if(time){
        const timeData :string[]= time.split(":");
        if(Number(timeData[1])>0){
          displayTaskHours = `${timeData[0]} h ${timeData[1]} m`
        }
        else{
          displayTaskHours = timeData[0] + "hrs"
        }
      }
    const countOfProduct = this.state.projectTask?.attributes?.inventory_or_assets?.data?.length;
    const valueOfStatus = configJSON.statusMap[status] ?? 1; 
    const statusClassName = configJSON.classMap[valueOfStatus]; 
    const transformedData = {
      "inventory_costs": Object.entries(this.state.detailsOfTaskCost.inventory_costs).map(([name, cost]) => ({
        name,
        cost: String(cost)
      }))
    };  
    const totalCost = Object.values(this.state.detailsOfTaskCost.inventory_costs).reduce((sum, cost) => sum + cost, 0);
    const symbolOfCurrency = getCurrencySymbol(this.userToken);
      return (
        <Modal
          open={this.state.taskModalOpen}
          aria-labelledby="modal-modal-title"
          onClose={this.handleTaskModalClose}
          className={classes.modalDialog}
          aria-describedby="modal-modal-description"
        >
          <Box className={`view-modal-dialog ${classes.modalDialogBox}`}>
            <Box className="modal-heading">
              <Box className="heading-links">
              <Box className="heading-link show-sm">
                   <Box
                    onClick={this.handleTaskModalClose}
                    data-testid="viewTaskClose"
                    className="auth-back icon"
                  >
                    <i>
                      <svg
                        className="MuiSvgIcon-root"
                        viewBox="0 0 24 24"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                      </svg>
                    </i>
                   </Box>
                </Box>    
                <Box className="heading-link hide-sm">
                   <Box
                    aria-describedby={popoverId}
                    className="icon"
                    onClick={this.editTaskOpen}
                    data-test-id="viewEditTask"
                  >
                    <img src={editGray} alt="editGray" />
                   </Box>
                </Box>
                <Box className="heading-link hide-sm">
                   <Box
                    onClick={this.handleAssignee}
                    className="icon"
                    data-test-id="viewUpdateAssignee"
                  >
                    <img src={accountGray} alt="accountGray" />
                   </Box>
                </Box>
                <Box className="heading-link hide-sm">
                   <Box
                    className="icon"
                    data-testid="duplicateTask"
                    onClick={() =>
                      this.handleDuplicateTask(this.state.projectTask?.id)
                    }
                  >
                    <img src={copyGray} alt="copyGray" />
                   </Box>
                </Box>
                <Box className="heading-link">
                   <Box
                    aria-haspopup="true"
                    aria-controls="simple-menu"
                    className="dropmenu-btn"
                    onClick={(e) => this.handleMenuOpen(e)}
                    data-testid="viewMenuOpen"
                  >
                    <img src={dotsHorizontal} alt="dotsHorizontal" className="hide-sm" />
                    <img src={dotVerticle} alt="dotVerticle" className="show-sm" />
                   </Box>
                  <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    keepMounted
                    getContentAnchorEl={null}
                    onClose={this.handleMenuClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    className={classes.dropDropdown}
                  >
                     <MenuItem className="show-sm" onClick={this.editTaskOpen} data-test-id="viewEditTask">
                      {this.t(`${configJSON.editTxt}`)}
                    </MenuItem>
                    <MenuItem className="show-sm" onClick={this.handleAssignee} data-test-id="viewUpdateAssignee">
                      {this.t(`${configJSON.viewAssigneeTxt}`)}
                    </MenuItem>
                    <MenuItem 
                      className="show-sm" 
                      data-testid="duplicateTasksm"
                      onClick={() =>
                        this.handleDuplicateTask(this.state.projectTask?.id)
                      }
                    >
                      {this.t(`${configJSON.duplicateTaskTxt}`)}
                    </MenuItem>
                    <MenuItem
                      data-test-id="viewDeleteTask"
                      onClick={() => this.deleteTaskInProject()}
                    >
                      {this.t(`${configJSON.txtDeleteTask}`)}
                    </MenuItem>
                    <MenuItem
                      onClick={(e:any)=>this.handleMoveTasklistProject(e,this.state.selectedTasks)}
                      data-test-id="handleMoveTasklistProject"
                    >
                      {this.t(`${configJSON.moveTasklistProjectTitle}`)}
                    </MenuItem>                 
                  </Menu>
                  <Menu
                    id="changePriorityViewEl"
                    keepMounted
                    anchorEl={this.state.changePriorityViewEl}
                    onClose={() => this.closePriorityView()}
                    open={Boolean(this.state.changePriorityViewEl)}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    data-test-id="changePriorityMenu"
                    className={`changepriority-dropdown ${classes.dropDropdown}`}
                  >
                    <MenuItem
                      data-test-id="changePriorityEmergency"
                      onClick={() => this.closePriorityView(2)}
                    >
                      <Box className="priority-name">{this.t(`${configJSON.txtPriorityEmergency}`)}</Box>
                      <Box className="priority-icon">!!!</Box>
                    </MenuItem>
                    <MenuItem
                      onClick={() => this.closePriorityView(1)}
                      data-test-id="changePriorityCritical"
                    >
                      <Box className="priority-name">{this.t(`${configJSON.txtCritical}`)}</Box>
                      <Box className="priority-icon">!!</Box>
                    </MenuItem>
                    <MenuItem
                      data-test-id="changePriorityNone"
                      onClick={() => this.closePriorityView(0)}
                    >
                      <Box className="priority-name">{this.t(`${configJSON.txtNone}`)}</Box>
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
               <Box
                onClick={this.handleTaskModalClose}
                className="close-icon hide-sm"
                data-testid="viewTaskClose"
              >
                <CloseIcon />
               </Box>
            </Box>
            <Box className="main-content-txt">
            <Typography className="content-sub-txt">{this.state.projectTask?.attributes.title}</Typography>
            <Typography className="content-sub-txt content-dots">{this.state.projectTask?.attributes.project_title}</Typography>
            <Typography className="content-sub-txt content-dots">{this.state.projectTask?.attributes.task_list_name}</Typography>
            </Box>
            <Box className="modal-description">
              {this.state.projectTask !==null && <TimeTrackingBillingWeb classes={classes} data-test-id="TimeTrackingBilling" timerRunningProp={this.state.projectTask.attributes.is_running} taskDetails={this.state.projectTask} updateCurrentStatus={this.updateCurrentStatus} currId={this.state.currId} viewTaskHandler={this.handle_modalOpen} type="single"/> }
              
              <List className="taskview-list">
                <ListItem>
                  <Box className="list-label">{this.t(`${configJSON.txtStatus}`)}</Box>
                  <Box className="list-description">
                    <FormControl className="select-control status-control">
                      <Select
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          classes: { 
                            paper: `${classes.dropdownStyle} modal-dropdown`
                          },
                        }}
                        displayEmpty
                        onChange={this.viewChangeStatus}
                        className={`${statusClassName} MuiSelect-select`}                    
                        value={valueOfStatus}
                        data-test-id="status-update"
                      >                      
                        <MenuItem value={0}>{this.t(`${configJSON.txtStatusOpen}`)}</MenuItem>
                        <MenuItem value={3}>{this.t(`${configJSON.txtStatusInProgress}`)}</MenuItem>
                        <MenuItem value={1}>{this.t(`${configJSON.txtStatusCancelled}`)}</MenuItem>
                        <MenuItem value={2}>{this.t(`${configJSON.txtStatusCompleted}`)}</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </ListItem>
                <ListItem>
                  <Box className="list-label">{this.t(`${configJSON.inventoryAseetsViewTitle}`)}</Box>
                  <Box className="list-description">
                    <Box className="brand-names">
                    {this.state.projectTask?.attributes?.inventory_or_assets?.data?.slice(0, 2).map((item:any)=>{
                    return <>
                    <Box className="brand-list">
                     <Box className="list-subtitle">{this.t(`${configJSON.brandTxt}`)}</Box>
                    <Box className="list-value">
                      {item.attributes.name}
                    </Box>
                    </Box>   
                        </>
                      })}                
                    <Box>
                    {countOfProduct > 2 && <Chip
                     className={`more-btn ${classes.chipButton}`}
                     label={`+${countOfProduct - 2} more`}
                     data-test-id="showMoreBtn"
                    />} 
                    </Box>                
                    </Box>
                  </Box>
                </ListItem>
                <ListItem className="date-item-root">
                 {this.renderAccordionOfDates()}
                </ListItem>
                <ListItem>
                  <Box className="list-label">{this.t(`${configJSON.expendituresText}`)}</Box>
                  <Box className="list-description">{symbolOfCurrency}{getNumberFormat(this.userToken, this.state.projectTask?.attributes.expenditures)}</Box>
                </ListItem>
                <ListItem>              
                <Accordion className="accordion-block">
                <AccordionSummary
                  id="open-header"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="open-content"
                  className="accordion-heading"
                >
                  <Box className="list-label">
                    {this.t(`${configJSON.totalCostTxt}`)}
                  </Box>
                  <Box className="list-description" style={{display:"flex",justifyContent:"end",paddingRight: "35px"}}>
                    <Box className="date-info">
                        <Box className="label">{this.t(`${configJSON.actualTxt}`)}: </Box>
                        <Box className="value">                  
                        {symbolOfCurrency}{getNumberFormat(this.userToken, Math.floor(this.state.detailsOfTaskCost.actual_hours / 3600) !== 
                            parseInt(moment(this.state.detailsOfTaskCost.hours, "HH:mm:ss").format("H")) ?
                            ((+this.state.detailsOfTaskCost.total_cost) -
                            ((+this.state.detailsOfTaskCost.labour_cost) * 
                            ((parseInt(moment(this.state.detailsOfTaskCost.hours, "HH:mm:ss").format("H")) -
                              Math.floor(this.state.detailsOfTaskCost.actual_hours / 3600))
                            / (parseInt(moment(this.state.detailsOfTaskCost.hours, "HH:mm:ss").format("H")))))).toFixed(2) :
                            (this.state.detailsOfTaskCost.total_cost))
                          }</Box>
                      </Box>
                      </Box>
                </AccordionSummary>
                <AccordionDetails className="accordion-details">
                <Box className="labour-cost">
                <Box className="list-label-1">{this.t(`${configJSON.laborCostTxt}`)}</Box>
                <Box className="list-value">
                {symbolOfCurrency}{getNumberFormat(this.userToken, Math.floor(this.state.detailsOfTaskCost.actual_hours / 3600) !== 
                      parseInt(moment(this.state.detailsOfTaskCost.hours, "HH:mm:ss").format("H")) ?
                      ((+this.state.detailsOfTaskCost.labour_cost) * ((Math.floor(this.state.detailsOfTaskCost.actual_hours / 3600)) 
                      / (parseInt(moment(this.state.detailsOfTaskCost.hours, "HH:mm:ss").format("H"))))) :
                      (this.state.detailsOfTaskCost.labour_cost))
                      }
                      </Box>
                  </Box>
                </AccordionDetails>
                <Box className="employee-txt">
              <Box className="employee-value">{this.state.detailsOfTaskCost.employee_count}&nbsp;{this.state.detailsOfTaskCost.employee_count > 1 ? this.t(`${configJSON.employeesTxt}`) : this.t(`${configJSON.employeeTxt}`) }</Box>
              <Box className="actual-position">
                <Box className="list-actual">
                    <Box className="list-subtitle">{this.t(`${configJSON.actualTxt}`)}:
                    </Box>
                    <Box className="list-value">
                    {formatTimeForShowingHours(this.state.detailsOfTaskCost.actual_hours)} hours
                    </Box>
                  </Box>             
                <Box className="list-actual">
                    <Box className="list-subtitle">{this.t(`${configJSON.projectedTxt}`)}:
                    </Box>
                    <Box className="list-value">
                   {moment(this.state.detailsOfTaskCost.hours, "HH:mm:ss").format("H")} hours
                    </Box>
                  </Box>
                </Box> 
                </Box> 
                <Box className="accordion-details">
  
                <Box className="labour-cost">
                <Box className="list-label-1">{this.t(`${configJSON.inventoryCostTxt}`)}</Box>
                <Box className="list-value">
                {symbolOfCurrency}{getNumberFormat(this.userToken, totalCost)}
                </Box>
                </Box>
                <table className="inventory-table">
                  {transformedData.inventory_costs.map((data:{name:string,cost:string})=>{
                    return <tr className="inventeory-items">
                    <th className="employee-value-head">{data.name} <img className="image" src={inventoryIcon} alt="icon"/></th>
                    <td className="employee-value">{moment(this.state.detailsOfTaskCost.hours, "HH:mm:ss").format("H")} hours</td>
                    <td className="employee-value">{symbolOfCurrency}{getNumberFormat(this.userToken, data.cost)}</td>
                </tr>
                })} 
                </table>
                </Box>   
                <ListItem>
                  <Box className="list-label">{this.t(`${configJSON.expendituresText}`)}</Box>
                  <Box className="list-description">{symbolOfCurrency}{getNumberFormat(this.userToken, this.state.detailsOfTaskCost.expenditures)}</Box>
                </ListItem>
                </Accordion>
                </ListItem>
                <ListItem>
                  <Box className="list-label">{this.t(`${configJSON.txtAssignees}`)}</Box>
                  <Box className="list-description">
                    <Box className="assignee-list">
                      {this.state.projectTask?.attributes.assignees
                        .slice(0, 4)
                        .map((assignee: any, index: number) => {
                          return (
                            <LightTooltip title={`${assignee.first_name} ${assignee.last_name}`} arrow placement="top">
                              <Box className="assignee-bubble">{`${assignee.first_name
                                ?.charAt(0)
                                .toUpperCase()}${assignee.last_name
                                ?.charAt(0)
                                .toUpperCase()}`}</Box>
                            </LightTooltip>
                          );
                        })}
                      {this.state.projectTask?.attributes.assignees.length > 4 && (
                        <Box className="assignee-bubble">
                          +{this.state.projectTask?.attributes.assignees.length - 4}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </ListItem>
                <ListItem>
                  <Box className="list-label">{this.t(`${configJSON.createdByTxt}`)}</Box>
                  <Box className="list-description">{this.state.projectTask?.attributes.created_by_name}</Box>
                </ListItem>
                {this.userRole === 'employee' && <>
                <ListItem>
                  <Box className="list-label">{this.t(`${configJSON.unTrackedHrs}`)}</Box>
                </ListItem>
                </>}
                
              </List>
              <Box className="tasklist-tabwrapper">
                <Box>
                  <Tabs
                    aria-label="simple tabs example"
                    value={this.state.viewValueOfTabs}
                    data-test-id="viewTaskBottomTabs"
                    onChange={this.setTabsViewValue}
                  >
                    <Tab label={this.t(`${configJSON.txtDescription}`)} {...viwA11yProps(0)} />
                    <Tab label={this.t(configJSON.attacmentsTxt , { count: this.state?.projectTask?.attributes?.attachments_url.length })} {...viwA11yProps(1)} />
                    <Tab label={this.t(`${configJSON.activityLogsTxt}`)} {...viwA11yProps(2)} />
                  </Tabs>
                </Box>
                <TabPanel value={this.state.viewValueOfTabs} index={0}>
                  {this.state.projectTask?.attributes.description ? (
                    <Box className="description-box">
                      {this.state.projectTask?.attributes.description}
                    </Box>
                  ) : (
                    <Typography>
                      No Description
                    </Typography> 
                  )}
                </TabPanel>
                <TabPanel value={this.state.viewValueOfTabs} index={1}>
                {this.state?.projectTask?.attributes?.attachments_url.length === 0 ? <List
                    className="attachments-list-default-view"                  
                  >
                   <img src={attacmentsIcon} className="attacments-icon" alt="attachmentImg1"></img>
                   <Typography className="no-attachment-text">
                   {this.t(`${configJSON.notAttachmentTest}`)}
                   </Typography>
                   <Typography className="no-attachment-sub-text">
                   {this.t(`${configJSON.subTextForDEfaultAttaments}`)}
                   </Typography>
                   <FileDrop>
                      <Box
                        onClick={this.onBrowseEvent}
                        data-test-id="onBrowse"
                      >
                        <form ref={this.formRef}>
                          <input
                            accept="*"
                            name="upload-photo"
                            onChange={this.handleOnDrop}
                            type="file"
                            data-test-id="add-project-attachment"
                            hidden
                            multiple
                            ref={this.fileRef}
                          />
                        </form>
                        <Box className="file-upload-block 2">                       
                        <Button
                            className={`secondary-btn ${classes.secondaryButton}`}
                            onClick={this.handleEventUpload}
                          >
                            + {this.t(`${configJSON.selecteFileTxt}`)}
                          </Button>                   
                        </Box>
                      </Box>
                  </FileDrop>                
                  </List> :  <List
                    className="attachments-list"
                    subheader={
                      <ListSubheader
                      id="nested-list-subheader"
                      className="subheader-title"
                      >
                        <Box className="title">{this.t(`${configJSON.attachmentsTxt}`)}</Box>
                    <FileDrop>
                      <Box
                        data-test-id="onBrowse"
                        onClick={this.onBrowseEvent}
                      >
                        <form ref={this.formRef}>
                          <input
                            accept="*"
                            onChange={this.handleOnDrop}
                            name="upload-photo"
                            type="file"
                            hidden
                            ref={this.fileRef}
                            data-test-id="handleOnAttachmentDrop"
                            multiple
                          />
                        </form>
                        <Box className="file-upload-block 1">                       
                         <Box className="tasklist-link"   data-test-id="handleEventUpload" onClick={this.handleEventUpload}>
                          + {this.t(`${configJSON.uploadTxt}`)}
                         </Box>                     
                        </Box>
                      </Box>
                  </FileDrop>
                      </ListSubheader>
                    }
                  >
                    {this.state?.projectTask?.attributes?.attachments_url?.map((image:any)=>{
                    return<ListItem className="list-item">
                      <Box className="list-img">
                        <img src={image.url ? image.url: attachmentImg1} alt="attachmentImg1" />
                      </Box>
                      <Box className="list-name">
                        {image.filename}
                      </Box>
                    </ListItem>
                    })}
                  </List>}
                
                </TabPanel>
                <TabPanel value={this.state.viewValueOfTabs} index={2}>
                  {this.showTaskActivityLog()}
                          
                </TabPanel>
              </Box>
            </Box>
          </Box>
        </Modal>
      );
    };

    openDetailsModal = () => {
      const {classes} = this.props;
      return (
        <>{this.state.taskModalOpen && <>{this.viewTaskModal(classes)}</>  }</>
      )
      
    }

    // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes,isOpen,projectClose } = this.props;

    const { currentProject,popoverAnchorEl } = this.state;
    const assigneesOfProject = this.getAssigneeNames(currentProject?.attributes?.assignees ?? []);
    const projectLead = this.getProjectLead(currentProject);

    //project create edit
    const popOverOpen1 = Boolean(popoverAnchorEl);
    const popoverId1 = popOverOpen1 ? "simple-popover" : undefined; 
    const status = currentProject?.attributes.status;    
    const statusValue = configJSON.projectStatusMap[status] ?? 1; 
    const statusClassName = configJSON.projectClassMap[statusValue];
    const hasProjectStatus = (project:{ attributes:{ status:string } }) => project?.attributes?.status;
    const getProgressStyle = (percentage: any) => ({
      width: `${Math.max(percentage, 6)}%`,
      height: "100%",
      borderRadius: "10px",
      position: "absolute"  as "absolute",
      top: 0,
      left: 0,
      fontSize: "10px",
      color: "white",
      fontWeight: "bold" as "bold",
      paddingTop: "3px",
      paddingLeft: "5px",
    });
    const taskCompleted = currentProject?.attributes.tasks.data.filter((taskData: any) => taskData.attributes.status === "completed").length
    const taskCancelled = currentProject?.attributes.tasks.data.filter((taskData: any) => taskData.attributes.status === "cancelled").length
    const totalTasks = currentProject?.attributes.tasks.data.length;
    const totalHours = currentProject?.attributes.tasks.data.reduce((acc: number, taskData: any) => {
      const eachTaskHour = taskData.attributes.hours.split(":")[0];
      return acc + Number(eachTaskHour);
    }, 0);
    const totalTrackedHours = currentProject?.attributes.tasks.data.reduce((acc: number, taskData: any) => {
      const eachTaskHour = taskData.attributes.duration;
      return acc + eachTaskHour
    }, 0);
    const taskCompletePercent = currentProject?.attributes.tasks.data.length ? `${((taskCompleted / (totalTasks - taskCancelled))*100).toFixed(2)}%` : "0%"

    return (
     <>
      <Modal
        className={classes.modalDialog}
        aria-labelledby="modal-modal-title"    
        onClose={()=>{ projectClose(); this.projectDataEmpty();}}
        open={isOpen}
        aria-describedby="modal-modal-description"
         data-test-id="viewProjectCloseFirst"
        
      >
        <Box className={`view-modal-dialog ${classes.modalDialogBox}`}>
          <Box className="modal-heading">
            <Box className="heading-links">
            <Box className="heading-link show-sm">
                <Box
                  data-testid="viewTaskClose"
                  onClick={()=>{ projectClose(); this.projectDataEmpty();}}    
                  data-test-id="viewProjectCloseSec"              
                  className="auth-back icon"
                >
                  <i>
                    <svg
                      className="MuiSvgIcon-root"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                    </svg>
                  </i>
                </Box>
              </Box>  
              <Box className="heading-link hide-sm">
                <Box
                  data-test-id="viewEditProject"
                  aria-describedby={popoverId1}
                  onClick={this.editProjectOpen}                  
                  className="icon"
                >
                  <img src={editGray} alt="editGray" />
                </Box>
              </Box>
              <Box className="heading-link hide-sm">
                <Box  className="icon" onClick={this.getTimesheetModalOpen}  data-test-id="getTimesheet">
                  <img src={contentSave} alt="contentSave" />
                </Box>
              </Box>
              <Box className="heading-link hide-sm">
                <Box                 
                  className="icon"
                  data-test-id="duplicateProject"
                  onClick={this.duplicateProject}
                >
                  <img src={copyGray} alt="copyGray" />
                </Box>
              </Box>
              <Box className="heading-link">
                <Box                 
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={this.handleMenuOpen}
                  className="dropmenu-btn"
                  data-test-id="viewMenuOpen"
                >
                 <img src={dotsHorizontal} alt="dotsHorizontal" className="hide-sm" />
                 <img src={dotVerticle} alt="dotVerticle" className="show-sm" />
                </Box>
                <Menu
                  keepMounted
                  getContentAnchorEl={null}
                  open={Boolean(this.state.anchorEl)}
                  className={classes.dropDropdown}
                  onClose={this.handleMenuClose}
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{
                    horizontal: "left",
                    vertical: "bottom",
                  }}
                  transformOrigin={{
                    horizontal: "left",
                    vertical: "top",
                  }}
                  id="simple-menu"
                >
                  <MenuItem className="show-sm" onClick={this.editProjectOpen} data-test-id="viewEditProject">
                   {this.t(`${configJSON.editTxt}`)}
                  </MenuItem>
                  <MenuItem className="show-sm" 
                    data-test-id="duplicateProject"
                    onClick={this.duplicateProject}
                    >
                    {this.t(`${configJSON.duplicateProjectTxt}`)}
                  </MenuItem>
                  <MenuItem className="show-sm">                    
                    {this.t(`${configJSON.saveAsTemplate}`)}
                  </MenuItem>
                  {/* <MenuItem onClick={this.getTimesheetModalOpen} data-test-id="getTimesheet">
                    {this.t(`${configJSON.getTimesheetTxt}`)}
                  </MenuItem> */}
                  <MenuItem onClick={this.tasklistOpenPopover}  data-test-id="createTasklist">                    
                    {this.t(`${configJSON.craeteTaskListTxt}`)}
                  </MenuItem>
                  <MenuItem
                    onClick={this.taskOpenPopover}
                    data-test-id="createTaskFromProject"
                  >
                    {this.t(`${configJSON.txtCreateTask}`)}
                  </MenuItem>
                  <MenuItem
                    onClick={this.deleteProjectHandler}
                    data-test-id="viewDeleteProject"
                  >                    
                    {this.t(`${configJSON.deleteProjectTxt}`)}
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
            <Box
              className="close-icon hide-sm"
              onClick={()=>{ projectClose(); this.projectDataEmpty();}}
              data-test-id="viewProjectClose"
            >
              <CloseIcon />
            </Box>
          </Box>
          <Box className="modal-description">
            <Box className="task-subtitle">
              {currentProject?.attributes.category?.category}
            </Box>
            <Typography className="modal-title" component="h2">
              {currentProject?.attributes.title}
            </Typography>
            <Box className="task-progress-block">
              <Box className="progress-info">
                <Box className="prec-value">{taskCompletePercent} {this.t(`${configJSON.txtStatusCompleted}`)}</Box>
                <Box className="total-value">{formatTimeForShowingHours(totalTrackedHours)} / {totalHours}:00:00</Box>
              </Box>
              <Box className="progress-wrap">
                <Box className="progress-inner" style={{ width: `${taskCompletePercent}` }}>
                </Box>
              </Box>
            </Box>
            <List className="taskview-list">
              <ListItem>
                <Box className="list-label">{this.t(`${configJSON.txtStatus}`)}</Box>
                <Box className="list-description">
                  <FormControl className="select-control status-control">
                    {hasProjectStatus(currentProject) && <Select
                      IconComponent={ExpandMoreIcon}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        classes: {
                          paper: `${classes.dropdownStyle} modal-dropdown`,
                        },
                      }}
                      onChange={this.projectChangeStatus}
                      displayEmpty
                      value={this.state.projectStatus}
                      //defaultValue={2}
                      data-test-id="projectStatusChange"
                      className={`${statusClassName} MuiSelect-select`}     
                    >
                      <MenuItem value={0}>{this.t(`${configJSON.txtStatusOpen}`)}</MenuItem>
                      <MenuItem value={1}>{this.t(`${configJSON.onTrackTxt}`)}</MenuItem>
                      <MenuItem value={2}>{this.t(`${configJSON.offTrackTxt}`)}</MenuItem>
                      <MenuItem value={3}>{this.t(`${configJSON.txtStatusCompleted}`)}</MenuItem>
                    </Select>}
                  </FormControl>
                </Box>
              </ListItem>
              <ListItem>
                <Box className="list-label">{this.t(`${configJSON.txtAssignees}`)}</Box>
                <Box className="list-description">
                  <Box className="assignee-list">
                    
                    {/* <Box className="assignee-bubble more-bubble" onClick={this.allAssigneeModalOpen}>+5</Box> */}
                    {assigneesOfProject.slice(0, 4).map((assignee:any)=>{
                      return(
                        <LightTooltip title={`${assignee.first_name} ${assignee.last_name}`} arrow placement="top">
                          <Box className="assignee-bubble" key={assignee.id}>{assignee.initials}</Box>
                        </LightTooltip>
                      )
                    })}
                      {assigneesOfProject.length > 4 && (
                      <Box className="assignee-bubble more-bubble" 
                       onClick={this.allAssigneeModalOpen}
                       data-test-id="showMoreAssignee"
                       >
                        +{assigneesOfProject.length - 4}
                      </Box>
                    )}

                  </Box>
                </Box>
              </ListItem>
              <ListItem className="date-item-root">
                <Box className="list-label">{this.t(`${configJSON.txtDates}`)}</Box>
                <Box className="list-description">
                  <Box className="date-info">
                    <Box className="label">{this.t(`${configJSON.startDateTxt}`)}: </Box>
                    <Box className="value">
                      {currentProject?.attributes.start_date
                        ? moment(currentProject?.attributes.start_date).format(
                            "ddd, MMM DD, YYYY"
                          )
                        : ""}
                      <img
                        className="icon"
                        src={calendarViewIcon}
                        alt="calendarViewIcon"
                      />
                    </Box>
                  </Box>
                  <Box className="date-info">
                    <Box className="label">{this.t(`${configJSON.endDateTxt}`)}: </Box>
                    <Box className="value">
                      {/* Mar 30, 2022 */}
                      {currentProject?.attributes.end_date
                        ? moment(currentProject?.attributes.end_date).format(
                            "ddd, MMM DD, YYYY"
                          )
                        : ""}
                      <img
                        className="icon"
                        src={calendarViewIcon}
                        alt="calendarViewIcon"
                      />
                    </Box>
                  </Box>
                </Box>
              </ListItem>
              <ListItem>
                <Box className="list-label">{this.t(`${configJSON.projectLeadTxt}`)}</Box>
                <Box className="list-description">{projectLead}</Box>
              </ListItem>
            </List>
            <Box className="tasklist-tabwrapper">
              <Box>
                <Tabs
                  aria-label="simple tabs example"
                  value={this.state.viewValueOfTabs}
                  onChange={this.setTabsViewValue}
                  data-test-id="viewTaskBottomTabs"
                >
                  <Tab label={this.t(`${configJSON.txtDescription}`)} {...viwA11yProps(0)} />
                  <Tab label={this.t(`${configJSON.budgetTxt}`)}  {...viwA11yProps(1)} />
                  <Tab label={this.t(configJSON.attacmentsTxt , { count: currentProject?.attributes?.attachments_url.length })} {...viwA11yProps(2)} />
                  <Tab label={this.t(`${configJSON.activityLogsTxt}`)} {...viwA11yProps(3)} />
                </Tabs>
              </Box>
              <TabPanel value={this.state.viewValueOfTabs} index={0}>
              {currentProject?.attributes.description ? (
                <Box className="description-box">
                  {currentProject?.attributes.description}
                </Box>
              ) : (
                <Typography>
                  No Description
                </Typography> 
              )}
              </TabPanel>
              <TabPanel value={this.state.viewValueOfTabs} index={1}>
                <Box className="budget-wrapper">
                  <Box className="budget-main">
                    <Box className="slider-wrap">
                      <Box className="slider-heading">
                        <Box className="slider-label">{this.t(`${configJSON.budgetTxt}`)}
                        <TruncatedTextWithTooltip classes={this.props.classes} icon={warningIcon} title={this.t(`${configJSON.tooltip1}`)} />
                        </Box>
                          {this.actualAndProjectedTxt(classes)}
                      </Box>
                      <Box className="slider-inner">
                        <Box className="progress-wrap" style={{ position: "relative", width: "100%", height: "20px" }}>
                          <Box
                            className="progress-inner"
                            style={getProgressStyle(
                              currentProject?.attributes?.actual_budget &&
                              currentProject?.attributes?.projected_budget
                                ? (currentProject.attributes.actual_budget /
                                    currentProject.attributes.projected_budget) *
                                  100
                                : 6
                            )}
                          >
                            {currentProject?.attributes?.actual_budget}
                          </Box>
                          <Box
                            className="progress-label"
                            style={{
                              left: "100%"
                            }}
                          >
                          </Box>
                        </Box>
                        <Box className="max-value">
                          {Math.max((currentProject?.attributes?.actual_budget), (currentProject?.attributes?.projected_budget))}
                        </Box>
                      </Box>                       
                    </Box>
                    <Box className={`slider-wrap ${
                            currentProject?.attributes?.actual_hours > currentProject?.attributes?.projected_hours
                              ? "overflow-count"
                              : ""
                          }`}>
                      <Box className="slider-heading">
                        <Box className="slider-label">{this.t(`${configJSON.txtHours}`)}                      
                        <TruncatedTextWithTooltip classes={this.props.classes} icon={warningIcon} title={this.t(`${configJSON.tooltip2}`)} />
                        </Box>
                         {this.actualAndProjectedTxt(classes)}
                      </Box>
                      <Box className="slider-inner">
                        <Box className="progress-wrap" style={{ position: "relative", width: "100%", height: "20px" }}>
                          <Box
                            className="progress-inner"
                            style={getProgressStyle(
                              currentProject?.attributes?.actual_hours &&
                              currentProject?.attributes?.projected_hours
                                ? (currentProject.attributes.actual_hours /
                                    currentProject.attributes.projected_hours) *
                                  100
                                : 6
                            )}
                          >
                            {currentProject?.attributes?.actual_hours}
                          </Box>
                           <Box
                            className="progress-label"
                            style={{
                              left: "100%"
                            }}
                          >
                          </Box>
                        </Box>
                        <Box className="max-value">
                          {Math.max(
                            this.parseTimeToHours(currentProject?.attributes?.actual_hours), 
                            parseFloat(currentProject?.attributes?.projected_hours) || 0
                          )} 
                        </Box>
                      </Box>
                    </Box>
                    <Accordion className="accordion-block">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="budget-content"
                        id="budget-header"
                        className="accordion-heading"
                      >
                        <Box className="heading">{this.t(`${configJSON.fullDetailsTxt}`)}</Box>
                        <Box className="sub-txt">
                          {this.t(`${configJSON.fullDetailsSubTxt}`)}
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails className="accordion-details">
                        <TableContainer>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  colSpan={3}
                                  className="table-heading"
                                  component="th"
                                  scope="row"
                                >                                  
                                  {this.t(`${configJSON.laborCostTxt}`)}
                                </TableCell>
                              </TableRow>
                              <TableRow>                              
                                <TableCell>{currentProject?.attributes?.labor_data.labor_count} {this.t(`${configJSON.employeesTxt}`)}</TableCell>
                                <TableCell>{currentProject?.attributes?.labor_data.actual_labor_hours} {this.t(`${configJSON.hoursTxt}`)}</TableCell>
                                <TableCell>&#163;{currentProject?.attributes?.labor_data.actual_labor_cost}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  colSpan={3}
                                  className="table-heading"
                                  component="th"
                                  scope="row"
                                >                                  
                                  {this.t(`${configJSON.inventoryCostTxt}`)}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  {this.t(`${configJSON.machineryItemTxt}`)} 
                                  <Box className="open-icon">
                                    <OpenInNewIcon />
                                  </Box>
                                </TableCell>
                                <TableCell>{currentProject?.attributes?.machinery_data.actual_machinery_hours} {this.t(`${configJSON.hoursTxt}`)}</TableCell>
                                <TableCell>&#163;{currentProject?.attributes?.machinery_data.actual_machinery_cost}</TableCell>
                              </TableRow>
                              <TableRow className="last-row">
                                <TableCell
                                  className="table-heading last"
                                  component="th"
                                  scope="row"
                                >
                                  {this.t(`${configJSON.expendituresText}`)}
                                </TableCell>
                                <TableCell colSpan={2}>&#163;{currentProject?.attributes?.expenditure}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Box className="budget-footer">
                    {this.t(`${configJSON.totalCostActual}`)}:<Box component="span" className="price">&#163;{(currentProject?.attributes?.labor_data.actual_labor_cost)+(currentProject?.attributes?.machinery_data.actual_machinery_cost)+(currentProject?.attributes?.expenditure)}</Box>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={this.state.viewValueOfTabs} index={2}>
              {currentProject?.attributes?.attachments_url.length===0?
               <List
                  className="attachments-list-default-view"              
                >
                 <img src={attacmentsIcon} className="attacments-icon" alt="attachmentImg1"/>
                 <Typography className="no-attachment-text 1">
                 {this.t(`${configJSON.notAttachmentTest}`)}
                 </Typography>
                 <Typography className="no-attachment-sub-text 1">
                 {this.t(`${configJSON.subTextForDEfaultAttaments}`)}
                 </Typography>
                 <FileDrop>
                    <Box
                      data-test-id="onBrowse"
                      onClick={this.onBrowseEvent}
                    >
                      <form ref={this.formRef}>
                        <input
                          name="upload-photo"
                          accept="*"
                          onChange={this.handleOnDropEvent}
                          type="file"
                          hidden
                          multiple
                          data-test-id="add-project-attachment"
                          ref={this.fileRef}
                        />
                      </form>
                      <Box className="file-upload-block 1">                       
                        <Button
                          onClick={this.handleUpload}
                          className={`secondary-btn ${classes.secondaryButton}`}                         
                        >
                          + {this.t(`${configJSON.selecteFileTxt}`)}
                        </Button>                        
                      </Box>
                    </Box>
                </FileDrop>                
                </List>: <List
                  className="attachments-list"  
                  subheader={
                    <ListSubheader
                    id="nested-list-subheader"
                    className="subheader-title"
                    >
                      <Box className="title 1">{this.t(`${configJSON.attachmentsTxt}`)}</Box>
                  <FileDrop>
                    <Box
                      onClick={this.onBrowseEvent}                   
                      data-test-id="onBrowse"
                    >
                      <form ref={this.formRef}>
                        <input
                          name="upload-photo"
                          accept="*"
                          onChange={this.handleOnDropEvent}                          
                          type="file"
                          multiple
                          hidden
                          data-test-id="add-project-attachment"
                          ref={this.fileRef}
                        />
                      </form>
                      <Box className="file-upload-block 1">                       
                      <Box className="tasklist-link 1" onClick={this.handleUpload}>
                        + {this.t(`${configJSON.uploadTxt}`)}
                      </Box>                     
                      </Box>
                    </Box>
                   </FileDrop>
                    </ListSubheader> 
                  }             
                >
                  {currentProject?.attributes?.attachments_url.map((item:{
                      id: number;
                      filename: string;
                      content_type: string;
                      byte_size: number;
                      created_at: string;
                      url: string;
                    })=>{
                    return <ListItem  key={item.id}  className="list-item">
                    <Box className="list-img">
                      <img alt="imgAttachment" src={item.url ? item.url : attachmentImg1 } />
                    </Box>
                    <Box className="list-name">
                    {item.filename}
                    </Box>
                    <ListItemSecondaryAction className="list-secondary">
                      <IconButton aria-label="delete" data-test-id="attachmentRemove" edge="end" onClick={()=>this.attachmentRemove(item.id.toString())}>
                        <CloseIcon/>
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  })}
                </List>}
              
              </TabPanel>
              <TabPanel value={this.state.viewValueOfTabs} index={3}>
                <ActivityLog 
                classes={classes}
                activityType="project"
                activityId={this.state.currentProject?.id}
                activityTitle={this.state.currentProject?.attributes?.title}
                />
              </TabPanel>
            </Box>
            <Box className="tasklist-tabwrapper">
              <Box>
                <Tabs
                  aria-label="simple tabs example"
                  value={this.state.projectTaskTabsValue}
                  onChange={this.setProjectTaskTabsValue}
                  data-test-id="projectTaskBottomTabs"
                >
                  <Tab label={this.t(`${configJSON.tasklistsTxt}`)} {...viwA11yProps(0)} />
                  <Tab label={this.t(`${configJSON.txtTasks}`)} {...viwA11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={this.state.projectTaskTabsValue} index={0}>
              {this.state.projectTaskList.length > 0 ? (
                  this.state.projectTaskList.map((tasklist: any) => {
                    return (
                      <Box className="tasklist-task-wrap" key={tasklist.id}>
                        <Box className="task-heading">
                          <Box className="heading">
                            {tasklist.attributes.name}
                          </Box>
                          <Box className="total-task">{tasklist.attributes.tasks.data.length} {tasklist.attributes.tasks.data.length>1 ? this.t(`${configJSON.txtTasks}`) : this.t(`${configJSON.txtTask}`)}</Box>
                        </Box>

                        <List className="tasklist-task-ul">
                          {tasklist.attributes.tasks.data.map((task:any) => {
                             const formatHours = hoursToSeconds(task.attributes.hours);
                             const isTimerRunning = task.attributes.is_running;     
                            return (
                              <ListItem
                                onClick={this.handleTaskListTasks(task.id)}
                                dense
                                role={undefined}
                                key={task.id}
                                className="tasklist-task-li"
                              >
                                <Box className="tasklist-inner">
                                  <ListItemIcon className="tasklist-icon">
                                    {task.attributes.current_status === "complete"? <img src={greenCheck} alt="check" /> :  <img src={grayCheck} alt="check" />}
                                  </ListItemIcon>
                                  <Box className="tasklist-label">
                                    <Box className="task-title">
                                      <Box className={task.attributes.status === "completed"?"title-dash":"title"} onClick={() => this.handle_modalOpen(task.id)} data-test-id="modalOpenClick">{task.attributes.title}</Box>
                                      <Box className="title-right">
                                        <Box className="controller-link">  
                                        {renderTimerIcon(
                                          task.id,
                                          task.attributes.status,
                                          isTimerRunning,
                                          this.startTaskTimerAPI
                                        )}
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box className="task-desc">
                                      <Box className="desc-left">
                                        <Box className="date" component="span">
                                        {task.attributes.due_date
                                          ? moment(
                                              task.attributes.due_date
                                            ).format("MMM DD , YYYY")
                                          : ""}
                                        </Box>
                                      </Box>
                                      <Box className="desc-right">
                                        <Box
                                          className="timing"
                                          component="span"
                                        >
                                          {formatTimeTaskHours(task.attributes.duration)}/{formatTimeTaskHours(formatHours)}
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </ListItem>
                            );
                          })}
                        </List>
                      </Box>
                    );
                  })
                ) : (
                  <Box className="no-task-block">
                    <img
                      src={formatTreeBlue}
                      alt="formatTreeBlue"
                      className="task-img"
                    />
                    <Box className="heading">{this.t(`${configJSON.noTasklistsTxt}`)}</Box>
                    <Box className="sub-txt">
                    {this.t(`${configJSON.notTsaklistSubTxt}`)}
                    </Box>
                    <Box className="button-wrapper full-btn">
                      <Button
                        className={`secondary-btn ${classes.secondaryButton}`}
                        title="+ Create Tasklists"
                        onClick={this.tasklistOpenPopover}
                      >
                        + {this.t(`${configJSON.createTstklistTxt}`)}
                      </Button>
                    </Box>
                  </Box>
                )}
              </TabPanel>
              <TabPanel value={this.state.projectTaskTabsValue} index={1}>
                <Button data-test-id="getProjectTasksByTab" onClick={()=>{this.startTaskTimerAPI("1","started"); this.getProjectTasksByTab()}} style={{display:"none"}}></Button>
                {currentProject?.attributes.tasks.data.length > 0
                ?this.showTaskByTab()
                :
                <Box className="no-task-block">
                  <img
                    src={fileTreeBlue}
                    alt="fileTreeBlue"
                    className="task-img"
                  />
                  <Box className="heading">{this.t(`${configJSON.noTasksTxt}`)}</Box>
                  <Box className="sub-txt">{this.t(`${configJSON.noTasksSubTxt}`)}</Box>
                  <Box className="button-wrapper full-btn">
                    <Button
                      className={`secondary-btn ${classes.secondaryButton}`}
                      title="+ Create Tasks"
                      onClick={this.taskOpenPopover}
                    >
                      + {this.t(`${configJSON.createTaskTxt}`)}
                    </Button>
                  </Box>
                </Box>
                }
              </TabPanel>
            </Box>
          </Box>
        </Box>
      </Modal> 
      
        {this.projectLeadModal(classes)}
        {this.deleteProjectModal()}
        {this.projectCreatedModal(classes)}
        {this.createProjectBlock(classes)}
        {this.timeSheetModal(classes)}
        {this.timesheetChooseAssigneesModal(classes)}
        {this.allAssigneeModal(classes)}
        {this.projectPopover(classes)}
        {this.projectTemplateModal(classes)}
        {this.openDetailsModal()}
        {this.createTaskModal(classes)}
        {this.assigneeChangeModal(classes)}
        {this.moveTaskFromProject(classes)}
        <ConfirmActionModal
            isOpen={this.state.isDeletingTask}
            handleClose={this.handleClose}
            modalConfirmAction={this.deleteTaskFromList}
            modalMessage={this.state.selectedTasks.length < 1 ? this.t(configJSON.confirmDeleteSingleTask) : this.t(configJSON.confirmDeleteTask, {
              taskCount: this.state.projectTask != null || this.state.deleteTaskId !== 0
                ? 1
                : this.state.selectedTasks.length
            })}
            confirmBtnTxt={this.t(`${configJSON.txtDelete}`)}
            cancelBtnTxt={this.t(`${configJSON.txtCancel}`)}
            modalHeading={this.t(`${configJSON.txtDeleteTask}`)}
            data-testid="confirmDeleteTask"
          />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#ffffff",
    color: "#2b2b2b",
    boxShadow: "0px 7.32px 9.76px -2.44px #10182814",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "13.39px",
    borderRadius: "4.88px",
    padding: "4.88px 7.32px",
    textAlign: "center",
    fontFamily: "expletus sans"
  },
  arrow: {
    color: "#ffffff",
  },
}))(Tooltip);
export default withStyles(customStyles, { withTheme: true })(ProjectView);
// Customizable Area End
