import React from "react";
// Customizable Area Start
import { Box, Typography, List, ListItem, Modal, Button,Switch } from "@material-ui/core";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import {
  userIcon,
  sunIcon,
  emailIcon,
  downThumbIcon,
  CalenderIcon,
  information,
  dummyImage,
  dummyImage2,
  backArrow,
  editGray,
} from "./assets";
// Customizable Area End

import ViewEmailCampaignControllerWeb, {
  Props,
  // Customizable Area Start
  configJSON,
  // Customizable Area End
} from "./ViewEmailCampaignController.web";

// Customizable Area Start
import WebLoader from "../../../components/src/WebLoader.web";
import moment from "moment";
import CreateEmailCampaign from  "./CreateEmailCampaign.web";
// Customizable Area End

export class ViewEmailCampaign extends ViewEmailCampaignControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderSwitchSection = (title:string, switchChecked:boolean, switchName:string, dataTestId:string, content:any) => {
    return (
      <Box className="box-content">
        <Box className="border-box">
          <Box className="right-content">
            <Box className="task-form" component="form">
              <Box className="form-info-wrapper">
                <Box className="form-row">
                  <Box className="switch-list-wrapper">
                    <Box className="dropdown-menu-item">
                      <Typography className="modal-box-title" component="h2">
                        {title} <img src={information} alt="information" />
                      </Typography>
                      <Switch
                        className="dropdown-menu-item-switch"
                        checked={switchChecked}
                        name={switchName}
                        data-test-id={dataTestId}
                        onChange={this.handleAdvancedCampaignSettings}
                      />
                    </Box>
                    {switchChecked && (
                      <Typography className="sub-txt" component="p">
                        {content}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
  
  getStatusDisplay = (status:string) => {
    return status === 'queued' ? 'Scheduled' : status.charAt(0).toUpperCase() + status.slice(1);
  }
  showcampaignLists = (campaignGroups: any) => {
    if (!campaignGroups) {
      return null; // Return null if campaignGroups is undefined
    }
    
    return campaignGroups.map((group: any) => (
      <ListItem key={group.id} onClick={() => this.redirectToGroupsPage(group.id)} data-test-id={`lists-${group.id}`}>
        <span className="gray-badge">{group?.name}</span>
      </ListItem>
    ));
  };
  
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, openViewModal, closeViewModal } = this.props;
    const { viewCampaignDetail , isAdvanceSetting} = this.state;
    return (
      <>
      {this.state.isLoading && <WebLoader/>}
      <Modal
        className={classes.modalDialog}
        aria-labelledby="modal-modal-title"
        onClose={closeViewModal}
        open={openViewModal}
        aria-describedby="modal-modal-description"
      >
        <Box className={`view-modal-dialog ${classes.modalDialogBox}`}>
          <Box className="modal-heading">
            <Box className="heading-links">
          {viewCampaignDetail.status === "queued" && 
          <Box className="heading-link">
            <Link
            to="#"
            className="close-icon"
            onClick={(event: any) => this.continueReuseCampaign(event)}
            data-test-id="editCampaign"
          >
             <img src={editGray} alt="editCampaign" />
          </Link>
          </Box>
          }
          <Box className="heading-link">
            <Link
              to="#"
              className="close-icon"
              onClick={closeViewModal}
              data-testid="viewTaskClose"
            >
              <CloseIcon />
            </Link>
            </Box>
          </Box>
          </Box>
          <Box className="modal-description-deal">
            <Typography className="modal-title" component="h2">
              {viewCampaignDetail.name}
            </Typography>
            <Typography className="sub-txt" component="p">
              {this.t(`${configJSON.emailCampaignSubTxt}`)}
            </Typography>
            <List className="dealview-list">
              <ListItem>
                <Box className="list-label">
                  {this.t(`${configJSON.statusTxt}`)}
                </Box>
                <Box className="list-description">
                  {viewCampaignDetail.status && (
                     <span className="status-control-sent">
                     {this.getStatusDisplay(viewCampaignDetail.status)}
                   </span>)}
                 
                </Box>
              </ListItem>
              <ListItem>
                <Box className="list-label">
                  {this.t(`${configJSON.creatorTxt}`)}
                </Box>
                {viewCampaignDetail.sender !== undefined && (
                  <Box className="list-description">
                    by {viewCampaignDetail.sender.name}
                  </Box>
                )}
              </ListItem>
              <ListItem>
                <Box className="list-label">
                  {this.t(`${configJSON.fromTxt}`)}
                </Box>
                {viewCampaignDetail.sender !== undefined && (
                  <Box className="list-description">
                    {viewCampaignDetail.sender.email}
                  </Box>
                )}
              </ListItem>
              <ListItem>
                <Box className="list-label">
                  {this.t(`${configJSON.toTxt}`)}
                </Box>
                <Box className="list-description">
                  <List className="badges-wrapper">
                    {this.showcampaignLists(viewCampaignDetail?.groups?.data)}
                  </List>
                </Box>
              </ListItem>
              <ListItem>
                <Box className="list-label">
                  {this.t(`${configJSON.dateTxt}`)}
                </Box>
                <Box className="list-description">
                {viewCampaignDetail.status === "sent" ?  <>
                <Box className="date-info">
                   
                    {viewCampaignDetail.created_at !== null && (
                      <>
                        <Box className="label">
                          {this.t(`${configJSON.sentOnTxt}`)}:
                        </Box>

                        <Box className="Value">
                          {moment(viewCampaignDetail.created_at).format("ddd MMM DD, YYYY")}{" "}
                          <img src={CalenderIcon} alt="calneder icon" />{" "}
                        </Box>
                      </>
                    )}
                  </Box>
                </> : <>
                <Box className="date-info">
                    {viewCampaignDetail.created_at !== null && (
                      <>
                        <Box className="label">
                          {this.t(`${configJSON.createdOnTxt}`)}:
                        </Box>

                        <Box className="Value">
                          {moment(viewCampaignDetail.created_at).format("ddd MMMM DD, YYYY")}{" "}
                          <img src={CalenderIcon} alt="calneder icon" />{" "}
                        </Box>
                      </>
                    )}
                  </Box>
                <Box className="date-info">
                    {viewCampaignDetail.scheduled_at !== null && (
                      <>
                        <Box className="label">
                          {this.t(`${configJSON.scheduledTxt}`)}:
                        </Box>

                        <Box className="Value">
                          {moment(viewCampaignDetail.scheduled_at).format("ddd MMMM DD, YYYY")}{" "}
                          <img src={CalenderIcon} alt="calneder icon" />{" "}
                        </Box>
                      </>
                    )}
                  </Box>
                </>
                    
                  }
                  
                 
                </Box>
              </ListItem>
              <ListItem>
                <Box className="list-label">
                  {this.t(`${configJSON.designTxt}`)}
                </Box>
                {viewCampaignDetail.template !== undefined && (
                  <>
                  {viewCampaignDetail.template !== null && (
                    <Box className="list-description">
                    {viewCampaignDetail.template.name}
                    <Link to="#" className="blue-link">
                    </Link>
                  </Box>
                  )}
                    
                  </>
                )}
              </ListItem>
              <ListItem>
                <Box className="list-label">
                  {this.t(`${configJSON.advanceSettingsTxt}`)}
                </Box>
                <Box className="list-description" data-test-id="handleAdvanceSettings" onClick={this.handleAdvanceSettings}>
                  <Link to="#" className="blue-link">
                    {this.t(`${configJSON.viewTxt}`)}
                  </Link>
                </Box>
              </ListItem>
            </List>
          </Box>
          <Box className="modal-grid-outer">
            <Box className="modal-grid-main">
              <Box className="modal-grid-item wide delivered">
                <Box className="modal-grid-item-inner">
                  <Box className="desc-block">
                    <Typography className="desc-title">
                      {viewCampaignDetail.delivered || 0}<span>(100%)</span>{" "}
                    </Typography>
                    <Typography className="desc-text">Delivered</Typography>
                  </Box>
                  <Box className="icon-block">
                    <img src={userIcon} alt="user icon" />
                  </Box>
                </Box>
              </Box>
              <Box className="modal-grid-item thin openers">
                <Box className="modal-grid-item-inner">
                  <Box className="icon-block">
                    <img src={emailIcon} alt="email icon" />
                  </Box>
                  <Box className="desc-block">
                    <Typography className="desc-title">
                      {viewCampaignDetail.unique_openers || 0}<span>(21.35%)</span>{" "}
                    </Typography>
                    <Typography className="desc-text">Views</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="modal-grid-item thin clicks">
                <Box className="modal-grid-item-inner">
                  <Box className="icon-block">
                    <img src={sunIcon} alt="clicks icon" />
                  </Box>
                  <Box className="desc-block">
                    <Typography className="desc-title">
                      {viewCampaignDetail.unique_clicks || 0} <span>(1%) </span>{" "}
                    </Typography>
                    <Typography className="desc-text">Clicks</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="modal-grid-item wide unsubscribers">
                <Box className="modal-grid-item-inner">
                  <Box className="desc-block">
                    <Typography className="desc-title">
                      0 <span>(20%)</span>{" "}
                    </Typography>
                    <Typography className="desc-text">unsubscribers</Typography>
                  </Box>
                  <Box className="icon-block">
                    <img src={downThumbIcon} alt="down thumb icon" />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Button className={`primary-btn ${classes.primaryButton}`} 
              onClick={(event: any) => this.continueReuseCampaign(event)
              }
              data-test-id="resuse-campaign-btn">
              {this.t(`${configJSON.reuseCampaignTxt}`)}
          </Button>
          </Box>
        </Box>
      </Modal>
      {this.state.reuseEmailCampaignForm && (
          <CreateEmailCampaign 
          popoveAnchorEl={this.state.reuseCampaignEl}
          popoverClose={this.popoverHandleClose}
          getAllCampaingsList={this.props.getAllList}
          isReusingCampaign={this.state.reuseEmailCampaignForm}
          campaignIdToReuse={this.props.campaignViewId}
          data-test-id="reuse-campaign"
          />
        )}
      {isAdvanceSetting && <Modal
        className={classes.modalDialog}
        aria-labelledby="modal-modal-title"
        onClose={this.handleCloseAdvanceSettings}
        open={openViewModal}
        aria-describedby="modal-modal-description"
      >
        <Box className={`view-modal-dialog ${classes.modalDialogBox}`}>
          <Box className="modal-heading">
            <Box
              className="close-icon"
              onClick={this.handleCloseAdvanceSettings}
              data-testid="viewTaskClose"
            >
              <CloseIcon />
            </Box>
          </Box>
          <Box className="modal-description-deal">
            <Box className="back-arrow-txt">
            <Box
              className="backArrow-icon"
              onClick={this.handleBackBtnAdvanceSettings}
              data-testid="backArrowClose"
              style={{cursor: "pointer"}}
            >
            <img src={backArrow} alt="backArrow"></img>
            </Box>
            <Typography className="sub-txt" component="h2">
              {viewCampaignDetail.name}/&nbsp;
            </Typography><Typography className="setting-title">{this.t(`${configJSON.advanceSettingsTxt}`)}</Typography>
            </Box>
            <Typography className="modal-title" component="h2">
            {this.t(`${configJSON.advanceSettingsTxt}`)}
            </Typography>
            <Typography className="sub-txt" component="p">
              {this.t(`${configJSON.subTitleTxt}`)}
            </Typography>          
          </Box>
         
             {this.renderSwitchSection(
          this.t(`${configJSON.googleAnalytics}`),
          this.state.gAEnabled,
          'GoogleAnalytics',
          'ga-switch',
          viewCampaignDetail.utm_campaign
        )}
            
         

        {this.renderSwitchSection(
          this.t(`${configJSON.emailTxt}`),
          this.state.EmbedImagesEnable,
          'EmbedImages',
          'embed-images-switch',
          <>
            <img className="image" src={dummyImage} alt="dummy" />
            <img className="image" src={dummyImage2} alt="dummy2" />
          </>
        )}

        {this.renderSwitchSection(
          this.t(`${configJSON.addAnAttachmentsTxt}`),
          this.state.attachmentEnable,
          'addAttachment',
          'attachment-switch',
          <>
            <img className="image" src={dummyImage} alt="dummy" />
            <img className="image" src={dummyImage2} alt="dummy2" />
          </>
        )}

        {this.renderSwitchSection(
          this.t(`${configJSON.mirrorTxt}`),
          this.state.mirrorLinkEnable,
          'mirrolLink',
          'mirrolLink',
          ''
        )}
        </Box>
      </Modal>}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default ViewEmailCampaign;
// Customizable Area End
