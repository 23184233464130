import React from "react";
import { Box, Typography, Grid, FormControl, Select, MenuItem, Card } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Chart } from "react-charts";
import SalesDashboardController, { Props } from "./SalesDashboardController.web";
import GlobalHeaderWeb from "../../customisableuserprofiles/src/GlobalHeader.web";
import { customStyles } from "./EquizStyles.web";
import { down_arrow } from "./assets";
export const configJSON = require("./config");
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import CommonModal from "./components/CommonModal";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import SortQuizWeb from "./SortQuiz.web";
import {tuneVertical, ellipse} from "./assets";


export class SalesDashboard extends SalesDashboardController {

    totalSales = (classes: any, t: any) => {
        const { isTotalSalesModalOpen } = this.state;

        const stageColors = {
            initiated: "gray",
            followup: "blue",
            won: "green",
            lost: "red",
            started: "orange",
          };
    
        return (
            <CommonModal
                isOpen={isTotalSalesModalOpen}
                onClose={this.handleModalClose}
                title={this.t(`$${this.state.totalSalesValue} Total Sales`)}
                description=""
                bodyContent={
                    <Box style={{padding: "0 60px"}}>
                        {/* Search bar */}
                        <Box style={{marginBottom: "0px", marginTop: "-20px"}} className="search-wrapper">
                            <CustomInputWeb
                                fullWidth
                                type="search"
                                placeholder={this.t("Start typing to search...")}
                                onChange={(event: any) => console.log('Search input:', event.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                            <Box className="filter-group" data-test-id="mobileFilter">
                                <img src={tuneVertical} alt="Filter Icon" />
                            </Box>
                            
                        </Box>
                        <Box style={{marginTop: "15px", marginLeft: "-15px", marginRight: "-25px"}} className={`${classes.dialogContent} filter-box-fb`}>
                            <Typography
                                className={`${classes.dialogContent} quiz-count`}
                            >
                               {`${this.state.salesDealCount} ${this.t("Deals")}`}
                            </Typography>
                            <SortQuizWeb
                                classes={classes}
                                sortHandler={() => { }}
                                sortType="librarySort"
                                data-test-id="sort-dropdown"
                                t={this.t}
                            />
                        </Box>

                        {this.state.salesDeals.map((obj) => {
                            const stageColors = {
                                initiated: "#12AEF1",
                                followUp: "#F7E223",
                                won: "#00BA88",
                                lost: "black",
                                sentProposal: "#F6A318",
                              } as const;

                            const normalizeStage = (stage: string | undefined) => {
                                if (!stage) return undefined;
                                const formattedStage = stage.toLowerCase().replace(/\s+/g, ""); // Remove spaces
                                const stageMap: Record<string, keyof typeof stageColors> = {
                                    initiated: "initiated",
                                    "sentproposal": "sentProposal",
                                    "followup": "followUp",
                                    won: "won",
                                    lost: "lost",
                                };
                                return stageMap[formattedStage]; 
                            };
                        
                            const stageKey = normalizeStage(obj.attributes.stage);
                            const stageColor = stageKey ? stageColors[stageKey] : "gray"; 

                            return(
                                <Box sx={{ minWidth: 275, paddingLeft: 20, display: "flex", flexDirection: "column" }} className={classes.fbCardRow}>
                                <div style={{marginTop: "10px", display: "flex", flexDirection: "row", justifyContent: "space-between"}} className={classes.campaignsBlock}>
                                    <div className= "user-col-two">
                                        <div style={{ justifyContent: "flex-start", gap: "5px", alignItems: "center"}} className="user-details">
                                            <span style={{color: "#2B2B2B"}} className="name">Close by: {obj.attributes.close_date ? new Date(obj.attributes.close_date).toLocaleDateString("en-US", { month: "short", day: "2-digit", year: "numeric" }) : "null"}</span>
                                            <span style={{borderRadius: "50%", width: "5px", height: "5px", backgroundColor: "#E8ECF2"}}></span>
                                            <span style={{color: "#2B2B2B"}} className="date">{obj.attributes.stage}</span>
                                            <span style={{borderRadius: "50%", width: "5px", height: "5px", backgroundColor: stageColor}}></span>
                                        </div>
                                        <div className="card-title">{obj.attributes.name}</div>
                                        <div style={{justifyContent: "flex-start", gap: "10px"}} className="card-tabs">
                                            <span className="card-tab-wrap">
                                                <div style={{color: "#94A3B8"}} className="card-head">CONTACT</div>
                                                <div className="val-wrap">
                                                    <span className="val-one">{obj.attributes.contacts[0].first_name}</span> 
                                                    <span className="val-one">{obj.attributes.contacts[0].last_name}</span>
                                                </div>
                                            </span>
                                            <span className="card-tab-wrap">
                                                <div style={{color: "#94A3B8"}} className="card-head">DEAL OWNER</div>
                                                <div className="val-wrap">
                                                <span className="val-one">{obj.attributes.deal_owner.first_name}</span> 
                                                <span className="val-one">{obj.attributes.deal_owner.last_name}</span>
                                                </div>
                                            </span>
                                           
                                        </div>
                                    </div>
                                    <div>{obj.attributes.total_amount === null ? 0 : obj.attributes.total_amount}</div>
                                </div>
                                <div style={{marginTop: "10px", marginBottom: "10px", border: "solid 1px #E8ECF2" }} className="line"></div>
                                </Box>
                            )
                        })}
                    </Box>
                }
                classes={classes}
            />
        );
    }

    newContacts = (classes: any, t: any) => {
        const { isNewContactsModalOpen } = this.state;
    
        return (
            <CommonModal
                isOpen={isNewContactsModalOpen}
                onClose={this.handleModalClose}
                title={(`${this.state.newContactsCount} ${this.t("New Contacts")}`)}
                description=""
                bodyContent={
                    <Box style={{padding: "0 60px"}}>
                        {/* Search bar */}
                        <Box className="search-wrapper">
                            <CustomInputWeb
                                fullWidth
                                type="search"
                                placeholder={this.t("Start typing to search...")}
                                onChange={(event: any) => console.log('Search input:', event.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                            <Box className="filter-group" data-test-id="mobileFilter">
                                <img src={tuneVertical} alt="Filter Icon" />
                            </Box>
                            
                        </Box>
                        <Box style={{marginTop: "15px", marginLeft: "-15px", marginRight: "-25px"}} className={`${classes.dialogContent} filter-box-fb`}>
                            <Typography
                                className={`${classes.dialogContent} quiz-count`}
                            >
                                {`${this.state.newContactsCount} ${this.t("Outreaches")}`}
                            </Typography>
                            <SortQuizWeb
                                classes={classes}
                                sortHandler={() => { }}
                                sortType="librarySort"
                                data-test-id="sort-dropdown"
                                t={this.t}
                            />
                        </Box>
    
                        <Box sx={{ minWidth: 275, paddingLeft: 20 }} className={classes.fbCardRow}>
                        <div className={classes.newContacts}>
                         {this.state.newContacts.map((obj) => {
                            return(
                                <div className="contacts-row">
                                    <span className="contact">
                                        <span className="initials">CW</span>
                                        <span>
                                            <div className= "contact-name">{`${obj.first_name}` + " " + `${obj.last_name}`}</div>
                                            <div className= "contact-email">{`${obj.email}`}</div>
                                        </span>
                                    </span>
                                    <span style={{fontSize: "12px"}}>10</span>
                                </div>
                            )
                          })}
                        </div>
                        </Box>

                        
                    </Box>
                }
                classes={classes}
            />
        );
    }

  render() {
    const { classes } = this.props;    
    const pageURL = window.location.pathname;
    const headerData = this.getHeaderData();
    const footerData = this.getFooterData();
    const primaryAxis = this.getPrimaryAxis();
    const secondaryAxes = this.getSecondaryAxes();
    const data = this.data;
    const tooltip = this.tooltip;

    return (
      <Box>
      {/* {this.state.isLoading && <WebLoader />} */}
      <Box
          className={`wrapper ${classes.wrapper} ${classes.dashboardWrapper}`}
      >
          <GlobalHeaderWeb pageLink={pageURL} />
          <Box className={classes.innerWrapper}>

              <Box className={classes.teamModuleWrapper}>
                  <Grid className="box-center" container spacing={2}>
                      <Grid item sm={9} xs={9}>
                          <Box className="google-heading-wrapper">
                              <Box className="heading-left">
                                  <Typography className="heading" variant="h1">
                                     {this.t(configJSON.statisticsTxt)}
                                  </Typography>
                              </Box>
                              <Box style={{marginRight: "-20px"}} className="heading-right  align-see-all">
                                  <Box className="filter-list">
                                      <FormControl className="select-control sortby-control">
                                          <Select
                                              MenuProps={{
                                                  anchorOrigin: {
                                                      vertical: "bottom",
                                                      horizontal: "left",
                                                  },
                                                  transformOrigin: {
                                                      vertical: "top",
                                                      horizontal: "left",
                                                  },
                                                  getContentAnchorEl: null,
                                                  classes: { paper: classes.dropdownClass },
                                              }}
                                              onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                                                this.setState({ selectedTotalSalesFilter: e.target.value as string });
                                              }}
                                              value={this.state.selectedTotalSalesFilter}
                                              
                                              displayEmpty
                                              style={{fontFamily: "Expletus Sans", minWidth: "130px"}}
                                              inputProps={{ IconComponent: () => null }}
                                              renderValue={(value) => {
                                                  return (
                                                      <Box className={classes.filterBox}>
                                                          <span>{value as string}</span>
                                                          <img
                                                              className="select-icon"
                                                              style={{marginRight: "-20px", marginLeft: "15px"}}
                                                              src={down_arrow}
                                                              alt="sortVariant"
                                                          />
                                                      </Box>
                                                  );
                                              }}
                                              data-test-id="sbmt-quiz-dropdown"
                                              
                                          >
                                              <MenuItem className={classes.dropdown_ele} value="4 weeks">{this.t(configJSON.last4WeeksTxt)}</MenuItem>
                                              <MenuItem className={classes.dropdown_ele} value="This month">{this.t(configJSON.thisMonthTxt)}</MenuItem>
                                              <MenuItem className={classes.dropdown_ele} value="This quarter">{this.t(configJSON.thisQuarterTxt)}</MenuItem>
                                              <MenuItem className={classes.dropdown_ele} value="This semester">{this.t(configJSON.thisSemesterTxt)}</MenuItem>
                                              <MenuItem className={classes.dropdown_ele} value="This year">{this.t(configJSON.thisYearTxt)}</MenuItem>
                                          </Select>
                                      </FormControl>

                                  </Box>
                              </Box>                                    
                              <Box sx={{ width: "100%" }}>
                                  <Box sx={{ minWidth: 275 }} className="sales-card-row">
                                    {this.renderStatisticList(headerData, "header")}
                                  </Box>
                              </Box>
                          </Box>

                          <Box className="google-heading-wrapper">
                              <Box className="heading-left">
                                  <Typography className="heading" variant="h1">
                                    {this.t(configJSON.dealsTxt)}
                                  </Typography>
                              </Box>
                              <Box style={{marginRight: "-20px"}} className="heading-right  align-see-all">
                                  <Box className="filter-list">
                                      <FormControl className="select-control sortby-control">
                                          <Select
                                              MenuProps={{
                                                  anchorOrigin: {
                                                      vertical: "bottom",
                                                      horizontal: "left",
                                                  },
                                                  transformOrigin: {
                                                      vertical: "top",
                                                      horizontal: "left",
                                                  },
                                                  getContentAnchorEl: null,
                                                  classes: { paper: classes.dropdownClass },
                                              }}
                                              // onChange={this.handleSubmittedQuizesSort}
                                              
                                              displayEmpty
                                              defaultValue={this.t(configJSON.last7DaysTxt)}
                                              style={{fontFamily: "Expletus Sans", minWidth: "130px"}}
                                              inputProps={{ IconComponent: () => null }}
                                              renderValue={(value) => {
                                                  return (
                                                      <Box className={classes.filterBox}>
                                                          <span>{value as string}</span>
                                                          <img
                                                              className="select-icon"
                                                              style={{marginRight: "-20px", marginLeft: "15px"}}
                                                              src={down_arrow}
                                                              alt="sortVariant"
                                                          />
                                                      </Box>
                                                  );
                                              }}
                                              data-test-id="sbmt-quiz-dropdown"
                                              
                                          >
                                              <MenuItem className={classes.dropdown_ele} value="4 weeks">{this.t(configJSON.last4WeeksTxt)}</MenuItem>
                                              <MenuItem className={classes.dropdown_ele} value="This month">{this.t(configJSON.thisMonthTxt)}</MenuItem>
                                              <MenuItem className={classes.dropdown_ele} value="This quarter">{this.t(configJSON.thisQuarterTxt)}</MenuItem>
                                              <MenuItem className={classes.dropdown_ele} value="This semester">{this.t(configJSON.thisSemesterTxt)}</MenuItem>
                                              <MenuItem className={classes.dropdown_ele} value="This year">{this.t(configJSON.thisYearTxt)}</MenuItem>
                                          </Select>
                                      </FormControl>

                                  </Box>
                                  <Link to={"/deals"} style={{ textDecoration: "none" }}>
                                   <Typography className="see-all-txt">{this.t(configJSON.seeAll)}</Typography>
                                  </Link>
                              </Box>

                              <Box sx={{ width: "100%", height: "100%" }}>
                                  <Box className="google-card-row">
                                      <Box sx={{ width: "1500px", height: "400px" }}>
                                          <Chart
                                              options={{
                                                  data,
                                                  primaryAxis,
                                                  secondaryAxes,
                                                  tooltip,
                                              }}
                                          />
                                      </Box>
                                  </Box>
                              </Box>

                              <Box sx={{ width: "100%" }}>
                                  <Box sx={{ minWidth: 275 }} className="sales-card-row">
                                    {this.renderStatisticList(footerData, "footer")}
                                  </Box>
                              </Box>
                              {this.state.isTotalSalesModalOpen && this.totalSales(classes, this.t)}
                              {this.state.isNewContactsModalOpen && this.newContacts(classes, this.t)}
                          </Box>
                      </Grid>
                  </Grid>
              </Box>
          </Box>
      </Box>
  </Box>    
  );
  }
}

export default withStyles(customStyles, { withTheme: true })(SalesDashboard);
