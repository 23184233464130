Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfGoogleApi";
exports.labelBodyText = "CfGoogleApi Body";

exports.btnExampleTitle = "CLICK ME";
exports.connectToMetaEndPoint = "/bx_block_dashboard/meta/oauth_url";
exports.connectToGoogleEndPoint = "/bx_block_dashboard/google/connect";
exports.connectToBrevoEndPoint = "/bx_block_dashboard/brevos/connect";

exports.gglaccessTokenEndPoint ="/bx_block_dashboard/google/callback";
exports.metaAccessTokenEndPoint ="/bx_block_dashboard/meta/callback";
exports.brevoAccessTokenEndPoint ="/bx_block_dashboard/brevos/callback";
exports.getDisconnectedGmbIntegrations = "/bx_block_dashboard/google/gmb_disconnect";
exports.getDisconnectedFbIntegrations = "/bx_block_dashboard/meta/meta_disconnect";

// ConnectAccountStartConfig Start

exports.btnSkip = "Skip";
exports.txtAddIntegrationTitle = "Let's add your integrations";
exports.txtAddIntegrationBody = "You can add various integrations to\n enhance your experience in EtOH Suite";
exports.txtStart = "Start"

// ConnectAccountStartConfig End

// ConnectAcoountIntegrations Start

exports.btnSkip = "Skip";
exports.txtIntegrationTitle = "Integrations";
exports.txtIntegrationBody = "Simply connect each account by logging into the system ";
exports.txtFinish = "Finish"

// ConnectAcoountIntegrations End

// ConnectAccountAddMetricsS Start

exports.txtChooseMetricsToConnect = "Choose metrics to connect";
exports.txtConfirmMetricsToConnect = "Confirm which metrics you want in the app";
exports.txtAddMetrics = "Add Metrics"

// ConnectAccountAddMetricsS End
// Customizable Area End