// Customizable Area Start
import { v4 as uuidv4 } from 'uuid';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import i18n from "../../../web/src/utilities/i18n";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isFbModal: boolean;
  isInstaModal: boolean;
  lastPostPageId: string;
  metaPageErrorResponse: string;
  isLoading: boolean;
  lastPostFbName: string;
  fbFollowerCount: number;
  instaFollowerCount: number;
  lastDatePostFb: string;
  lastPostInstaName: string;
  lastDatePostFbError: string;
  lastDatePostInstaErr: string;
  lastDatePostInsta: string;
  metafacebookUrl: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export const configJSON = require("./config");

export default class MarketingDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
    userToken: any;
    apimetaIntegrationId: string = "";
    apiUserMetaAllPages: string = "";
    apiRetrieveLastPostTime: string = "";
    apiRetrieveLastPostName: string = "";
    apiFollowersOverTime: string = "";
    userSessionData: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.state = {
        isFbModal: false,
        metafacebookUrl: '',
        lastPostPageId: '',
        lastDatePostFb: '',
        lastPostFbName: '',
        lastPostInstaName: '',
        isLoading: false,
        lastDatePostInsta: '',
        fbFollowerCount: 0,
        instaFollowerCount: 0,
        metaPageErrorResponse: '',
        lastDatePostFbError: '',
        lastDatePostInstaErr: '',
        isInstaModal: false,
    }

    this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    // Customizable Area End
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }


  // Customizable Area Start

    async componentDidMount(){
      this.getmetaIntegrationsUrl();
      this.getUserMetaAllpages();
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
      if(prevState.lastPostPageId != this.state.lastPostPageId) {
        this.retrieveLastPostTime();
        this.retrieveLastPostName()
        this.retrieveFollowersOverTime();
      }
    }

    async receive(from: string, message: Message) {

      // Customizable Area Start
      if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
  
        if (apiRequestCallId === this.apimetaIntegrationId) {
          this.setState({isLoading: false});
          this.setState({metafacebookUrl: responseJson.url})
        }

        if(apiRequestCallId === this.apiUserMetaAllPages){ 
          this.setState({isLoading: false});
          if(responseJson?.error){
            this.setState({metaPageErrorResponse: responseJson?.error})
          } else {
            this.setState({lastPostPageId: responseJson.data[0].page_id})
          }
        }

        if(apiRequestCallId === this.apiRetrieveLastPostTime) {
          this.setState({isLoading: false});
          if(responseJson?.error){
            console.log("response json", responseJson.error);
          } else {
            this.setState({
              lastDatePostFb: responseJson?.last_post_time?.facebook,
              lastDatePostInsta: responseJson?.last_post_time?.instagram,
              lastDatePostFbError: responseJson?.last_post_time?.facebook_error,
              lastDatePostInstaErr: responseJson?.last_post_time?.instagram_error,
            })
          }
        }

        if(apiRequestCallId === this.apiRetrieveLastPostName) {
          this.setState({isLoading: false});
          this.setState({lastPostFbName: responseJson?.last_post?.facebook?.title, lastPostInstaName: responseJson?.last_post?.Instagram?.title  });
          if(responseJson?.error){
            console.log("responseJson", responseJson?.error);
          }
        }

        if(apiRequestCallId === this.apiFollowersOverTime) {
          this.setState({isLoading: false, 
            instaFollowerCount: (responseJson?.data?.instagram?.followers > 0 && responseJson?.data?.facebook?.followers != null) ?  responseJson?.data?.instagram?.followers : 0, 
            fbFollowerCount: (responseJson?.data?.facebook?.followers > 0 && responseJson?.data?.facebook?.followers != null)  ? responseJson?.data?.facebook?.followers : 0, 
          });
        }
      }
      // Customizable Area End
    }

  

    t(keyValue: string) {
      return i18n.t(keyValue, { ns: "translation" });
    }

    closeFbModal = () => this.setState({isFbModal: false});

    closeInstaModal = () => this.setState({isInstaModal: false});
 
    getmetaIntegrationsUrl = () => {
      this.setState({isLoading: true});
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.userToken?.meta?.token,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.apimetaIntegrationId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodGET
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getMetaOuth
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getUserMetaAllpages = () => {
      this.setState({isLoading: true});
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.userToken?.meta?.token,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.apiUserMetaAllPages = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodGET
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getUserMetapages
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    retrieveLastPostTime = () => {
      this.setState({isLoading: true});
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.userToken?.meta?.token,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.apiRetrieveLastPostTime = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodGET
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.retrievelastPostTime}=${this.state.lastPostPageId}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    retrieveLastPostName = () => {
      this.setState({isLoading: true});
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.userToken?.meta?.token,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.apiRetrieveLastPostName = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodGET
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.retrieveLastPostName}=${this.state.lastPostPageId}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    retrieveFollowersOverTime = () => {
      this.setState({isLoading: true});
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.userToken?.meta?.token,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.apiFollowersOverTime = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodGET
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.retrieveFollowersOverTime}=${this.state.lastPostPageId}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleMetaFbClick = () => {
      window.location.href = this.state.metafacebookUrl;
    }

  

    // Customizable Area End


}
