Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfEScore";
exports.labelBodyText = "CfEScore Body";

exports.baseURL = "https://etohapp-123437-ruby.b123437.stage.eastus.az.svc.builder.ai"
exports.createQuizEndPoint = "bx_block_dashboard/quizzes";
exports.getSubmittedQuizes = "/bx_block_dashboard/submitted_quizzes"
exports.reTakeQuizEndPoint = "/bx_block_dashboard/retake_quiz"
exports.submitQuizEndPoint = "/bx_block_dashboard/submissions";
exports.quizResultEndPoint = "/bx_block_dashboard/submission_result";
exports.quizSubmitResultEndPoint = "/bx_block_dashboard/submissions";
exports.quizFilterSearchEndPoint = "/bx_block_dashboard/quizzes_filter?";
exports.editQuizEndPoint="/bx_block_dashboard/quizzes"
exports.simulateQuizSubmitEndPoint = '/bx_block_dashboard/quiz_simulations';
exports.methodGET = "GET"
exports.methodPOST = "POST"
exports.methodDELETE = "DELETE"
exports.methodPUT = "PUT"

exports.btnExampleTitle = "CLICK ME";
exports.getQuizzesListEndPoint = "/bx_block_dashboard/quizzes"
exports.getQuizDetailsEndPoint = "/bx_block_dashboard/submissions"
exports.deleteQuizEndPoint = "/bx_block_dashboard/destroy_quiz"
exports.getSimulationSimulationEndpoint = "bx_block_dashboard/quiz_simulations?quiz_id=";
exports.updateSimulationQuizEndpoint = "bx_block_dashboard/quiz_simulations";
exports.getSimulationQueEndpoint = "bx_block_dashboard/submissions?quiz_id=";
exports.getMetaOuth = "bx_block_dashboard/meta/oauth_url";
exports.getUserMetapages = "bx_block_dashboard/meta/get_user_meta_pages";
exports.retrievelastPostTime = "bx_block_dashboard/meta/retrieve_last_post_time?page_id";
exports.getMetaOuth = "bx_block_dashboard/meta/oauth_url";
exports.retrieveLastPostName = "bx_block_dashboard/meta/retrieve_last_post?page_id";
exports.retrieveFollowersOverTime = "bx_block_dashboard/meta/followers_over_time?page_id";

exports.labelTitleTxt = "Take an E-Quiz today!";
exports.labelDetailTxt = "E-Score is a useful tool for assessing your company on industry standards. Take one of our pre-made E-Quizzes or create your own.";
exports.labelBtnCreateOwn = "Create Your Own";
exports.lebelBtnChooseQuiz = "Choose E-Quiz";
exports.retakeQuiz = "Retake E-Quiz";
exports.goToDashboard = "Go To Dashboard";
exports.labelCreate = "Create your own";
exports.textCreate = "Create your own E-Quiz";
exports.labelChoose = "Choose an E-Quiz";
exports.textChoose = "Take one of our E-Quizzes";
exports.economicQuizTitle = "Economic E-Quiz";
exports.economicQuizDescription = "How well are you performing economically?"
exports.howMuchGreenhouse = "How much greenhouse gasses does your facility emit per month?"
exports.recommendation = "Our Recommendation"
exports.recommendationDetail = "To improve your [E-Score Title] E-Score, you can refer to our $Online Tools$ or seek assistance from an expert through the $economic consultation services$ available at the EtOH Shop. For any questions, don’t hesitate to reach out to EtOH over $chat$"
exports.txtEquizTitle="E-Quiz Title"
exports.txtEquizCaption="Enter a caption (e.g. a question or the\npurpose of the E-Quiz)"
exports.txtMsgGradeA = "Congratulations, you reached\nthe goal!";
exports.txtMsgGradeB = "You're almost there\n";
exports.txtMsgGradeC = "A little more effort, do you need\nsome help?";
exports.txtMsgGradeD = "Don't give up, keep\npushing!";
exports.txtMsgGradeE = "Keep trying, progress comes\nwith practice!";
exports.txtYourEScoreTitle = "Your [E-Score Title] E-Score is";
exports.txtYourCompanyValues = "Your Company Values E-Score is ";
exports.txtOurRecommendation = "Our Recommendation";
exports.txtEtOHOffersExpert = "EtOH offers expert consulting services that can help enhance your E-Score. Get in touch with us through $chat$ for more information.";
exports.txtCompanyValue="Company Values"
exports.recommendationDetail = "To improve your [E-Score Title] E-Score, you can refer to our $Online Tools$ or seek assistance from an expert through the $economic consultation services$ available at the EtOH Shop. For any questions, don't hesitate to reach out to EtOH over $chat$"
exports.txtEScore = "E-Score"
exports.txtEScoreSubheading = "Know how well your company is performing."
exports.txtSortBy = "Sort by"
exports.txtEscores = "E-Scores"
exports.txtRetakeEQuiz = "Retake E-Quiz"
exports.txtFullDetails = "Full Details"
exports.txtSimulateQuiz = "Simulate Quiz"
exports.txtFilters = "Filters"
exports.txtReset = "Reset"
exports.txtDateLastTaken = "Date Last Taken"
exports.txtTakeEQuiz = "+ Take E-Quiz"
exports.txtApplyFilters = "Apply Filters"
exports.simulationEScore = "Simulation E-Score"
exports.hereIsTheResult = "Here is the result of your adjustments"
exports.officialEScore = "Official E-Score"
exports.thisIsYourLastEQuizResult = "This is your last E-Quiz result"
exports.escoreImprovementInstructions = "Use this mode to focus your improvements and see how changes to e-questions affect your overall e-score."
exports.adjustTheSlider = "Adjust the sliders in this simulation to see what your new E-Score would be!"
exports.yourLastOfficialEscore = "Your last official e-score is at the top and the slider inputs are in grey."
exports.pastSimulationDis = "Here are all of your past simulations"
exports.pastSimulations = "Past Simulations"
exports.tookEQuiz = "I took this is E-Quiz"
exports.iGotThis = "& I got this E-Score"
exports.whatisyour = "What’s your company’s E-Score?"
exports.downloadApp = "Download App"
exports.baseURL = require("../../../framework/src/config");
exports.txtChooseDateRange = "Choose Date Range"
exports.txtScoreAMessage = "Congratulations, you reached the goal!"
exports.txtScoreBMessage = "You're almost there"
exports.txtScoreCMessage = "A little more effort, do you need some help?"
exports.txtScoreDMessage = "Don't give up, keep pushing!"
exports.txtScoreEMessage = "Keep trying, progress comes with practice!"
exports.txtYourEcoEScoreIs = "Your Economic E-Score is"
exports.txtOurRecommendation = "Our Recommendation"
exports.txtRecommendationMessagePart1 = "you can refer to our "
exports.txtRecommendationMessagePart2 = " or seek assistance from an expert through the "
exports.txtRecommendationMessagePart3 = " available at the EtOH Shop. For any questions, don't hesitate to reach out to EtOH over "
exports.txtTryOutSimulationMode = "Try out Simulation Mode"
exports.txtTryOutSimulationModeSubtitle = "Use this mode to focus your improvements and see how changes to e-questions affect your overall e-score."
exports.txtWantToShareYourCode = "Want to share your code?"
exports.txtEconomicEScore = "Economic E-Score"
exports.txtEconomicEScoreContent = "How well are you performing economically?"
exports.txtDeleteEQuiz = "Delete E-Quiz"
exports.txtCancel = "Cancel"
exports.txtDelete = "Delete"
exports.txtExitEScoreTitle = "Exit E-Score"
exports.txtExitEScoreMsg = "Are you sure you want to exit? All progress will be lost."
exports.txtExit = "Exit"
exports.simulationTxt = "Simulation E-Score";
exports.simulationSubTxt = "Here is the result of your adjustments.";
exports.cancelBtn = "Cancel";
exports.saveSimulationBtn = "Save simulation";
exports.lastEsocreResultTxt = "This is your last E-Quiz result.";
exports.desSimulationQuiz = "How well are we abiding by our values?";
exports.simulationSaved = "Simulation Quiz Save Successfully.";
exports.scoreImporveBtn = "Want to improve the score?";
exports.scoreImproveDes = "To improve your Economic E-Score, consider purchasing our ";
exports.simulationDes = "economic consultation services";
exports.scoreImproveDes2 = "from the EtOH Shop. For any questions, don’t hesitate to reach out to EtOH over";
exports.recommendationLink = "https://tools.etoh.fr/"
exports.chatTxt = "chat";
exports.escoreTxt = "Your E-Score is";
exports.escoreTxtE = "Your E-Score ";
exports.simulationTimeTxt = 'Current versions';
exports.gradeAText = "Congratulations you reached the goal!";
exports.gradeBText = "You're almost there!";
exports.gradeCText = "Just need a little more effort. Do you need some help?";
exports.gradeDText = "Don't give up, keep pushing!";
exports.gradeEText = "Keep trying, progress comes with practice!";
exports.undoActionAPIEndPint = "/bx_block_action_log/undo";
exports.getAllDeals = "/bx_block_contactslist/deals?sort_by=DATE_CREATED";
exports.retakeQuizeconfirmationMessage ="Are you sure you want to retake this {{quizTitle}} E-Quiz? Your previous E-Score will be replaced by the new score."
exports.sortByTxt="Sort by";
exports.last7DaysTxt="Last 7 Days";
exports.last4WeeksTxt="Last 4 weeks";
exports.thisMonthTxt="This month";
exports.thisQuarterTxt="This quarter";
exports.thisSemesterTxt="This semester";
exports.thisYearTxt="This year";
exports.dealsTxt="Deals";
exports.seeAll="See All";
exports.totalSales="Total Sales";
exports.newContacts="New Contacts";
exports.initiated="Initiated";
exports.proposalsSent="Proposals Sent";
exports.followUps="Follow-ups";
exports.wonTxt="Won";
exports.lostTxt="Lost";


// Customizable Area End
