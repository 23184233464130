import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { ChooseMetrics } from "../../cfmetaapi/src/ChooseMetrics.web";
import i18n from "../../../web/src/utilities/i18n";

export const configJSON = require('./config')
const globalJSON = require('../../../framework/src/config')

export type NavigationRouteParamsType = {
    serviceCaller: string,
    connectCallback: () => void
}

interface S {
    isMetaConnected: boolean,
    isGoogleConnected: boolean,
    isSendinBlueConnected: boolean,
    showErrorModal: boolean,
    errorMessage: string,
    showFinishButton: boolean,
    disconnectMessage: string;
    disconnectFbMessage: string;
    disconnectMessageFbError: string;
    disconnectMessageError: string;
}

interface SS { }

export interface Props {
    navigation?: any;
    id?: string;
    classes?: any;
    backToStartIntegration?: any;
    skipToProfile?: any;
    state?:any;
}

export default class AddIntegrationController extends BlockComponent<Props, S, SS> {

    getMetaAuthUrlRequestId: string = "";
    getGoogleAuthUrlRequestId: string = "";
    getBravoConnectRequestId: string = "";
    getGmbDisconnetUrlRequestId: string = "";
    getFbDisconnectAccount: string = "";

    getGoogleAccessTokenRequestId: string = "";
    getMetaAccessTokenRequestId: string = "";

    userSessionData: any;
    userToken: any;

    constructor(props: Props) {
        super(props)
        this.receive = this.receive.bind(this)
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)

        this.userSessionData = localStorage.getItem("userData");
        this.userToken = JSON.parse(this.userSessionData);

        const isGoogleConnected = localStorage.getItem('IsGoogleConnected') === 'true';
        this.state = {
            isMetaConnected: false,
            isGoogleConnected,
            isSendinBlueConnected: false,
            showErrorModal: false,
            errorMessage: '',
            disconnectMessage: '',
            disconnectMessageError: '',
            disconnectMessageFbError: '',
            disconnectFbMessage: '',
            showFinishButton: isGoogleConnected,
        }
    }

    t(key:any, variables?: Record<string, any>) {
        return (i18n as any).t(key, { ns: "translation" , ...variables } )
      }

    async componentDidMount() {
        super.componentDidMount();

        const searchParams = new URLSearchParams(window.location.search);
        const hasAuthCode = searchParams.has('code') && searchParams.has('scope');

        const isGoogleConnected = localStorage.getItem('IsGoogleConnected') === 'true';

        this.setState({
            isGoogleConnected,
            showFinishButton: hasAuthCode || isGoogleConnected 
        });
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
            const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage))

            if (apiRequestCallId == this.getMetaAuthUrlRequestId) {
                const url = responseJson.url;
                window.open(url, '_self', 'noopener,noreferrer');
                return
            }

            if (apiRequestCallId == this.getGoogleAuthUrlRequestId) {
                let url = responseJson.url;
                window.open(url, '_self', 'noopener,noreferrer');
                return
            }

            if (apiRequestCallId == this.getBravoConnectRequestId) {
                let url = responseJson.url;
                window.open(url, '_blank', 'noopener,noreferrer');
                return
            }

            if(apiRequestCallId == this.getGmbDisconnetUrlRequestId) {
                if(!(responseJson?.error)) {
                    this.setState({disconnectMessage: responseJson?.message})
                } else {
                    this.setState({disconnectMessageError: responseJson.error});
                }
            }

            if(apiRequestCallId == this.getFbDisconnectAccount){
                if(!(responseJson?.error)) {
                    this.setState({disconnectFbMessage: responseJson?.message})
                } else {
                    this.setState({disconnectMessageFbError: responseJson.error});
                }
            }
        }
    };

    handleDisconnect = () => {
        const header = {
            "Content-Type": "application/json",
            token: this.userToken?.meta?.token,
        };

        let endPoint = `${globalJSON.baseURL}${configJSON.getDisconnectedGmbIntegrations}`
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        this.getGmbDisconnetUrlRequestId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint.toString()
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        )
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

    handleFbDisconnect = () => {
        const header = {
            "Content-Type": "application/json",
            token: this.userToken?.meta?.token,
        };

        let endPoint = `${globalJSON.baseURL}${configJSON.getDisconnectedFbIntegrations}`
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        this.getFbDisconnectAccount = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint.toString()
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        )
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

    connectToMeta = () => {
        const header = {
            "Content-Type": "application/json",
            token: this.userToken?.meta?.token,
        };

        let endPoint = `${globalJSON.baseURL}${configJSON.connectToMetaEndPoint}`
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        this.getMetaAuthUrlRequestId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint.toString()
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        )
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

    connectToGoogle = () => {
        const header = {
            "Content-Type": "application/json",
            token: this.userToken?.meta?.token,
        };

        let endPoint = `${globalJSON.baseURL}${configJSON.connectToGoogleEndPoint}`
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        this.getGoogleAuthUrlRequestId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint.toString()
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        )
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

    connectToBravo = () => {
        const header = {
            "Content-Type": "application/json",
            token: this.userToken?.meta?.token,
        };

        let endPoint = `${globalJSON.baseURL}${configJSON.connectToBrevoEndPoint}`
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
        this.getBravoConnectRequestId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint.toString()
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        )
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }

    onFinish = () => {
        const searchParams = new URLSearchParams(window.location.search);
    
        if (searchParams.has('code') && searchParams.has('scope')) {
            localStorage.setItem("IsGoogleConnected", 'true');
            this.setState({ 
                isGoogleConnected: true,
                showFinishButton: true 
            });
        }
        this.props.skipToProfile('Finish');
    };

}

