// Customizable Area Start
import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { customStyles } from "./CustomisableUserProfiles.web";
import { isEmpty } from "../../../framework/src/Utilities";
import { memberRevoked, checkedIcon, memberEdited, thumb } from "./assets";
import i18n from "../../../web/src/utilities/i18n";
import countries from "world-countries/countries.json";

export const configJSON = require("./config");

interface Currency {
  currencyName: string;
  currencyCode: string;
  currencySymbol: string;
}

export interface Props extends WithStyles<typeof customStyles> {
  navigation?: any;
  id?: string;
  classes: any;
  isMobile?: boolean;
}

export interface S {
  isEditProfile: boolean;
  isEditingEmail: boolean;
  isEditingPassword: boolean;
  firstName: string;
  isFormEdit: false;
  lastName: string;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  userCompanyName: string;
  userCompanyAddress: string;
  currencyName:string;
  currencyList: Currency[];
  postalCode: string;
  userJobRole: string;
  totalMembers: number;
  newEmailAddress: string;
  newEmailAddressError: string;
  modalOpen: boolean;
  emailSuccessMsg: string;
  isResend: boolean;
  resendSec: number;
  intervalId: NodeJS.Timeout | null;
  showTimer: boolean;
  disable: boolean;
  newPassword: string;
  newPasswordError: string;
  oldPassword: string;
  oldPasswordError: string;
  errorMessage: any;
  error: {
    errorFirstnameNotValid: string;
    errorlastNameNotValid: string;
    errorHourlyPay: string;
    errorCmpName: string;
    errorCountry: string;
    errorState: string;
    errorCity: string;
    errorPostalCode: string;
    errorAddress: string;
    errorJobTitle: string;
  };
  notifications: any[];
  isProfileDrawerOpened: boolean;
  page: number;
  hasNextPage: boolean;
  isAddTeam: boolean;
  teamsList: any;
  selectedTeamFilter: any;
  isRemoveTeam: boolean;
  userTeams: any[];
  teamLength: number;
  showMoreTeams: boolean;
  teamId: number;
  company: any;
  teams: any[];
  selectedTeams: any[];
  jobTitles: any[];
  jobTitle: any;
  isDeleting: boolean;
  deleteJobTitleId: string;
  jobTitleText: string;
  hourlyPay: number | null;
  countryList: any[];
  stateList: any[];
  cityList: any[];
  country: string;
  stateVal: string;
  city: string;
  isLoading:boolean;
  teamTitle:string;
  anchorEl : any;
  onExitSave:boolean;
  onEditExitFormSave: boolean;
}
interface SS {
  id: any;
}

export default class CustomisableUserProfilesController extends BlockComponent<
  Props,
  S,
  SS
> {
  customisableUserProfilesApiCallId: string = "";
  invitedMemListApiCallId: string = "";
  profileListApiCallId: string = "";
  updateUserEmailAPICallId: string = "";
  updatePasswordAPICallId: string = "";
  deleteJobtitleApiCallId: string = "";
  renameJobTitleRequestId: string = "";
  getJobTitlesRequestId: string = "";
  createNewJobTitleRequestId: string = "";
  undoActionAPICalld: string = "";
  scrollViewRef: any;
  removeMemberApiCallId: string = "";
  editOwnProfileApiCallId: string = "";
  addNewTeamApiCallId: string = "";
  countryListApiCallId: string = "";
  stateListApiCallId: string = "";
  cityListApiCallId: string = "";
  fetchTeamApiCallId: string = "";
  userSessionData: any;
  userToken: any;
  currentJobTitle: { id: number };
  showDialogTitle: any;
  setErrorTitle: any;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);

    this.state = {
      isEditProfile: false,
      isEditingEmail: false,
      isEditingPassword: false,
      isFormEdit: false,
      firstName: "",
      lastName: "",
      userFirstName: "",
      userLastName: "",
      userEmail: "",
      userCompanyName: "",
      userCompanyAddress: "",
      currencyName:"",
      currencyList: [],
      postalCode: "",
      userJobRole: "",
      totalMembers: 0,
      newEmailAddress: "",
      newEmailAddressError: "",
      modalOpen: false,
      emailSuccessMsg: "",
      isResend: false,
      resendSec: 15,
      intervalId: null,
      showTimer: false,
      disable: false,
      newPassword: "",
      newPasswordError: "",
      oldPassword: "",
      oldPasswordError: "",
      errorMessage: "",
      error: {
        errorFirstnameNotValid: "",
        errorlastNameNotValid: "",
        errorHourlyPay: "",
        errorCmpName: "",
        errorCountry: "",
        errorState: "",
        errorCity: "",
        errorPostalCode: "",
        errorAddress: "",
        errorJobTitle: "",
      },
      notifications: [],
      isProfileDrawerOpened: false,
      page: 0,
      hasNextPage: true,
      isAddTeam: false,
      teamsList: [],
      selectedTeamFilter: [],
      isRemoveTeam: false,
      userTeams: [],
      teamLength: 0,
      showMoreTeams: false,
      teamId: 0,
      company: "",
      teams: [],
      selectedTeams: [],
      jobTitles: [],
      jobTitle: null,
      isDeleting: false,
      deleteJobTitleId: "",
      jobTitleText: "",
      hourlyPay: null,
      countryList: [],
      stateList: [],
      cityList: [],
      country: "",
      stateVal: "",
      city: "",
      isLoading:false,
      teamTitle:"",
      anchorEl : null,
      onExitSave:false,
      onEditExitFormSave:false
    };
    this.currentJobTitle = { id: 0 };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    this.invitedMemList();
    this.fetchTeams();
    // this.fetchTitleList();
    this.countryListAPICall();
    const lang = localStorage.getItem("lang") ?? "en"; 
    await (i18n as any).changeLanguage(lang);
    const currencyList = this.getCurrencyList();
    this.setState({ currencyList });
  }

  getCurrencyList = () => {
    const currencySet = new Map<string, Currency>();

    countries.forEach((country) => {
      Object.entries(country.currencies).forEach(([currencyCode, currencyDetails]) => {
        if (currencyDetails && !currencySet.has(currencyCode)) {
          currencySet.set(currencyCode, {
            currencyName: currencyDetails.name,
            currencyCode: currencyCode,
            currencySymbol: currencyDetails.symbol || "",
          });
        }
      });
    });

    return Array.from(currencySet.values());
  }

  t(key:any , variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation", ...variables });
  }
  async componentDidUpdate(prevProps: Props, prevState: S) {
    this.checkTeamAndTitle(prevState);
    if (
      prevState.country !== this.state.country ||
      prevState.countryList !== this.state.countryList
    ) {
      this.setState({ cityList: [], stateList: [] });
      this.stateListAPICall();
    }
    if (
      prevState.stateVal !== this.state.stateVal ||
      prevState.stateList !== this.state.stateList
    ) {
      this.setState({ cityList: [] });
      this.cityListAPICall();
    }
    if (prevState.jobTitle !== this.state.jobTitle) {
      this.setState({
        error: {
          ...this.state.error,
          errorJobTitle: "",
        },
      });
    }

    if (prevState.showTimer !== this.state.showTimer && this.state.showTimer) {
      const newIntervalId = setInterval(() => {
        this.setState((prevState) => {
          return {
            ...prevState,
            resendSec: prevState.resendSec - 1,
          };
        });
      }, 1000);
      this.setState({ intervalId: newIntervalId });
    }
    if (prevState.resendSec !== this.state.resendSec) {
      if (this.state.resendSec == 0) {
        if (this.state.intervalId != null) {
          clearInterval(this.state.intervalId);
          this.setState({ showTimer: false, disable: false });
        }
      }
    }
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (!responseJson.errors) {
          switch (apiRequestCallId) {
            case this.customisableUserProfilesApiCallId:
              this.invitedMemList()
              this.customisableUserProfilesApiCallSuccess(responseJson);
              this.getProfileListApiCallSuccess(responseJson)
              break;
            case this.profileListApiCallId:
              this.getProfileListApiCallSuccess(responseJson);
              break;
            case this.updateUserEmailAPICallId:
              this.handleEmmailUpdateUser(responseJson);
              break;
            case this.updatePasswordAPICallId:
              this.handlePasswordUpdateUser(responseJson);

              break;
            case this.removeMemberApiCallId:
              this.setState({
                isRemoveTeam: false,
                userTeams: responseJson?.data?.attributes?.team ?? [],
                teamLength: responseJson?.data?.attributes?.team?.length,
              });
              this.createToastNotification("Removed from Team", memberRevoked);
              break;
            case this.fetchTeamApiCallId:
              this.setState({
                teams: responseJson.data,
              });
              break;
            case this.addNewTeamApiCallId:
              this.handleClose();
              this.setState({
                userTeams: responseJson?.data?.attributes?.team ?? [],
                teamLength: responseJson?.data?.attributes?.team?.length,
              });
              this.createToastNotification("Added to Team", checkedIcon);
              break;
            case this.deleteJobtitleApiCallId:
              this.deleteJobTitleResponse();
              break;
            case this.renameJobTitleRequestId:
              this.editTitleSuccess(responseJson);
              break;
            case this.getJobTitlesRequestId:
              if (this.currentJobTitle.id !== 0) {
                this.setState({ jobTitles: responseJson.data });
              } else {
                this.setState({
                  jobTitle: this.getCurrentJobTitle(responseJson.data),
                });
              }

              break;
            case this.countryListApiCallId:
              const countryList = responseJson.data.map(
                (val: any, ind: any) => ({
                  label: val.country_name,
                  value: val.id,
                })
              );

              this.setState({
                countryList: countryList,
              });
              break;
            case this.stateListApiCallId:
              const stateList = responseJson.data.map((val: any, ind: any) => ({
                label: val.state_name,
                value: val.id,
              }));

              this.setState({
                stateList: stateList,
              });
              break;
            case this.cityListApiCallId:
              const cityList = responseJson.data.map((val: any, ind: any) => ({
                label: val.city_name,
                value: val.id,
              }));

              this.setState({
                cityList: cityList,
              });
              break;
            case this.createNewJobTitleRequestId:
              this.createTitleSuccess(responseJson);
              break;
            case this.undoActionAPICalld:
              this.invitedMemList();
              this.fetchTeams();
              // this.fetchTitleList();
              break;
          }
        } else {
          this.checkTitleError(responseJson, apiRequestCallId);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
  }

  handlePossibleUndefinedAttribute = (
    attribute: any,
    defaultValue: any
  ): string => {
    if (isEmpty(attribute)) return defaultValue;
    return attribute;
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as keyof S;
    const value = event.target.value;
    this.setState({ [name]: value,
      isFormEdit:true
     } as unknown as Pick<S, keyof S>);
    let error = this.state.error;
    switch (name) {
      case "firstName":
        error.errorFirstnameNotValid = "";
        break;

      case "lastName":
        error.errorlastNameNotValid = "";
        break;
      case "hourlyPay":
        error.errorHourlyPay = "";
        break;
      case "userCompanyName":
        error.errorCmpName = "";
        break;
      // case "postalCode":
      //   error.errorPostalCode = "";
      //   break;
      // case "userCompanyAddress":
      //   error.errorAddress = "";
    }
    this.setState({ error: error });
  };
  handleProfilePostalCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (inputValue.length <= 10) {
      console.log(inputValue)
      this.setState({
        postalCode: event.target.value,
      })
    }
  }

  handleSelectChange = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const name = event.target.name as keyof S;
    const value = event.target.value;
    this.setState({ [name]: value } as unknown as Pick<S, keyof S>);

    let error = this.state.error;
    switch (name) {
      case "country":
        error.errorCountry = "";
        break;

      case "stateVal":
        error.errorState = "";
        break;
      // case "city":
      //   error.errorCity = "";
      //   break;
    }
    this.setState({ error: error });
  };

  isOwner = () => {
    return this.userToken?.data.attributes.job_type.toLowerCase() === "owner";
  };

  isManager = () => {
    return this.userToken?.data.attributes.job_type.toLowerCase() === "manager";
  };

  getCurrentJobTitle = (jobTitles: any) => {
    let jobTitle = this.state.jobTitle;
    let newjobTitles: any = jobTitles;
    if (jobTitle == null) {
      const curJobTitle = jobTitles.find(
        (x: any) => x.attributes.title == this.state.userJobRole
      );
      if (curJobTitle) {
        jobTitle = { id: curJobTitle.id, title: curJobTitle.attributes.title };
      } else {
        jobTitle = { id: 0, title: this.state.userJobRole };

        newjobTitles = [
          ...jobTitles,
          {
            id: jobTitle.id,
            type: "jobtitle",
            attributes: {
              id: jobTitle.id,
              title: jobTitle.title,
            },
          },
        ];
      }
    }
    this.setState({ jobTitles: newjobTitles });
    return jobTitle;
  };

  checkPrevStateTitles = (prevState: S) => {
    if (prevState.jobTitles !== this.state.jobTitles) {
      if (this.currentJobTitle.id !== 0) {
        const curTitle = this.state.jobTitles.find(
          (x) => x.id == this.currentJobTitle.id
        );
        this.setState({
          jobTitle: {
            id: curTitle.id,
            title: curTitle.attributes.title,
          },
        });
      }
      this.currentJobTitle = { id: 0 };
    }
  };

  checkTeamAndTitle = (prevState: S) => {
    this.checkPrevStateTitles(prevState);
  };

  createToastNotification = (toastMesssage: string, toastIcon?: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{toastMesssage}</div>
        <a
          href="#"
          className="toast-link"
          data-test-id="undo-toast-link"
          onClick={this.undoactionAPICall}
        >
          undo
        </a>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        icon: toastIcon ? () => <img src={toastIcon} alt="icon" /> : undefined,
      }
    );
  };

  getFirstLetterOfWord = (value: string) => {
    return value.charAt(0).toUpperCase();
  };

  customisableUserProfilesApiCallSuccess = (responseJson: any) => {
    if (responseJson.data.id > 0) {
      this.setState({
        firstName: responseJson.data.attributes.first_name,
        lastName: responseJson.data.attributes.last_name,
        userFirstName: responseJson.data.attributes.first_name,
        userLastName: responseJson.data.attributes.last_name,

        // jobTitle:
      });
      this.handleClose();
      this.createToastNotification(
        "Your profile has successfully updated",
        memberEdited
      );
    } else {
      this.createToastNotification(
        "Something went wrong when updating your profile. Please try again."
      );
    }
  };

  handleEmmailUpdateUser = (responseJson: {
    errors: { message: string }[];
    data: any;
  }) => {
    if (responseJson.errors && responseJson.errors.length > 0) {
      this.setState({
        newEmailAddressError: responseJson.errors[0].message,
      });
    } else {
      this.setState({
        emailSuccessMsg: "An email has been sent",
        isResend: true,
        showTimer: true,
        resendSec: 60,
        disable: true,
      });
    }
  };
  handlePasswordUpdateUser = (responseJson: {
    errors: { message: string; account: string }[];
    data: any;
    message: string;
  }) => {
    this.setState({ isLoading:false});
    if (responseJson.errors && responseJson.errors.length > 0) {
      if (responseJson.errors[0].message) {
        this.setState({
          oldPasswordError: responseJson.errors[0].message,
        });
      } else if (responseJson.errors[0].account) {
        this.setState({
          newPasswordError: responseJson.errors[0].account,
        });
      }
    } else {
      // this.createToastNotification(responseJson.message);
      this.createToastNotification("Your password has been updated.", thumb);
      this.handleClose();
    }
  };
  getProfileListApiCallSuccess = (responseJson: any) => {
    console.log("grew1", responseJson.data);
    if (responseJson.errors) {
      this.createToastNotification(
        "Could not fetch User Details. Please try again."
      );
      return;
    }
    this.setState(
      {
        firstName: responseJson.data.attributes.first_name,
        lastName: responseJson.data.attributes.last_name,
        userEmail: responseJson.data.attributes.email,
        userFirstName: responseJson.data.attributes.first_name,
        userLastName: responseJson.data.attributes.last_name,
        userCompanyName: responseJson.data.attributes.company_name,
        userCompanyAddress: responseJson.data.attributes.company_place_address,
        userJobRole: responseJson.data.attributes.job_role,
        postalCode:
          responseJson.data.attributes.company_address.attributes.postal_code,
        hourlyPay: responseJson.data.attributes.hourly_pay,
        totalMembers: responseJson.data.attributes.users,
        userTeams: responseJson.data.attributes.team ?? [],
        teamLength:
          responseJson.data.attributes.team != null
            ? responseJson.data.attributes.team.length
            : 0,
        country:
          responseJson.data.attributes.company_address.attributes.country ?? "",
        stateVal:
          responseJson.data.attributes.company_address.attributes.state ?? "",
        city: responseJson.data.attributes.company_address.attributes.city,
      },
      () => {
        // this.getCurrentJobTitle(this.state.jobTitles);
        this.fetchTitleList();
      }
    );
  };

  returnTeam = () => {
    this.props.navigation.navigate("AccountCreation");
  };
  returnInviteMembers = () => {
    this.props.navigation.navigate("InviteMembers");
  };

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };
  isPasswordPopupClose = () => {
    this.setState({ isEditingPassword: false});
    if(this.state.oldPassword && this.state.newPassword){
      this.setState({ onExitSave:true });
    }
  };
  onSave = () => {
    this.setState({ isEditingPassword: true, onExitSave:false });
  };
  onSaveEditForm = () => {
    this.editProfileController()
    this.createToastNotification(
      "Your profile has successfully updated",
      memberEdited
    );
    this.props.navigation.navigate("EquizDashboardWeb")
    this.setState({ onEditExitFormSave :false });
  };
  
  handleCloseEditExitSave = () => {
    this.props.navigation.navigate("EquizDashboardWeb")
    this.setState({ onEditExitFormSave :false }); 
  }
  handleClose = () => {
    this.setState({
      isEditProfile: false,
      isEditingEmail: false,
      isEditingPassword: false,
      newEmailAddress: "",
      newEmailAddressError: "",
      newPassword: "",
      newPasswordError: "",
      oldPassword: "",
      oldPasswordError: "",
      emailSuccessMsg: "",
      isResend: false,
      resendSec: 15,
      showTimer: false,
      disable: false,
      modalOpen: false,
      isAddTeam: false,
      isRemoveTeam: false,  
      onExitSave:false, 
    });
  };
  handleCloseTitle = () => {
    this.setState({
      isDeleting: false,
    });
  };

  handleEditBack = () => {};

  saveEmailAddressValue = (value: string) => {
    this.setState({ newEmailAddress: value, newEmailAddressError: "" });
  };

  savePasswordValue = (value: string) => {
    this.setState({ newPassword: value, newPasswordError: "" });
  }; 
  
  saveOldPasswordValue = (value: string) => {
    this.setState({ oldPassword: value, oldPasswordError: "" });
  };

  updateUserEmail = () => {
    if (this.state.newEmailAddress.trim() === "") {
      this.setState({ newEmailAddressError: "Please Enter email address" });
    } else {
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
        token: this.userToken.meta.token,
      };

      let body = {
        data: {
          new_email: this.state.newEmailAddress,
        },
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateUserEmailAPICallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.webUpdateUserEmail
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      //* Adding Request Method

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.requestMethodPatch
      );

      //* Making Network Request
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  isMatched = (inputPassword: string) => {
    let isContainsUpperCase = () => /[A-Z]/.test(inputPassword);
    let isContainsLowerCase = () => /[a-z]/.test(inputPassword);
    let isContainsNumber = () => /\d/.test(inputPassword);
    let isMinLength = () => /.{8,}$/.test(inputPassword);
    if (
      isContainsLowerCase() &&
      isContainsUpperCase() &&
      isContainsNumber() &&
      isMinLength() &&
      inputPassword !== ""
    ) {
      return true;
    }
  };

  updateUserPassword = () => {
    let errorFlag: boolean = false;
    let oldError: string = "";
    let newError: string = "";

    if (this.state.oldPassword.trim() === "") {
      errorFlag = true;
      oldError = "Please Enter old password";
    } else if (
      this.state.oldPassword.trim() !== "" &&
      !this.isMatched(this.state.oldPassword)
    ) {
      errorFlag = true;
      oldError = configJSON.errorPasswordNotValid;
    }
    if (this.state.newPassword.trim() === "") {
      errorFlag = true;
      newError = "Please Enter Password";
    } else if (
      this.state.newPassword.trim() !== "" &&
      !this.isMatched(this.state.newPassword)
    ) {
      errorFlag = true;
      newError = configJSON.errorPasswordNotValid;
    }
    if (errorFlag === false) {
      const header = {
        "Content-Type": "application/json",
        token: this.userToken.meta.token,
      };

      const body = {
        data: {
          old_password: this.state.oldPassword,
          new_password: this.state.newPassword,
        },
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updatePasswordAPICallId = requestMessage.messageId;

      //* Adding endpoint

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.webUpdateUserPassword
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      //* Adding Request Method

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.requestMethodPatch
      );

      //* Making Network Request
      this.setState({ isLoading:true});
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.setState({ oldPasswordError: oldError, newPasswordError: newError });
    }
  };

  userDataToSave = () => {
    if (this.isOwner()) {
      return {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        hourly_pay: this.state.hourlyPay,
        company_name: this.state.userCompanyName,
        country: this.state.country,
        state: this.state.stateVal,
        city: this.state.city,
        postal_code: this.state.postalCode,
        company_place_address: this.state.userCompanyAddress,
        job_role: this.state.jobTitle.title,
      };
    } else {
      return {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
      };
    }
  };

  checkOwnerFeilds = () => {
    let errorFlag = false;
    let error = this.state.error;
    if (!this.state.hourlyPay) {
      error.errorHourlyPay = "Please enter hourly pay";
      errorFlag = true;
    }
    if (this.state.jobTitle == null) {
      error.errorJobTitle = "Please select job title";
      errorFlag = true;
    }
    // if (this.state.city == "") {
    //   error.errorCity = "Please select city";
    //   errorFlag = true;
    // }
    if (this.state.country == "") {
      error.errorCountry = "Please select country";
      errorFlag = true;
    }
    if (this.state.stateVal == "") {
      error.errorState = "Please select state";
      errorFlag = true;
    }
    if (this.state.userCompanyName == "") {
      error.errorCmpName = "Please enter company name";
      errorFlag = true;
    }

    if (errorFlag) {
      this.setState({
        error: error,
      });
    }
    return errorFlag;
  };

  editProfileController = () => {
    let errorFlag = false;
    let firstNameError = this.state.error.errorFirstnameNotValid;
    let lastNameError = this.state.error.errorlastNameNotValid;

    if (this.state.firstName.trim() === "") {
      firstNameError = configJSON.errorFirstnameNotValid;
      errorFlag = true;
    }
    if (this.state.lastName.trim() === "") {
      lastNameError = configJSON.errorlastNameNotValid;
      errorFlag = true;
    }
    let errorOwner = false;
    if (this.isOwner()) {
      errorOwner = this.checkOwnerFeilds();
    }

    if (errorFlag) {
      this.setState({
        error: {
          ...this.state.error,
          errorFirstnameNotValid: firstNameError,
          errorlastNameNotValid: lastNameError,
        },
      });
    }

    if (errorFlag || errorOwner) {
      return;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.userToken.meta.token,
    };

    const httpBody = {
      data: this.userDataToSave(),
      token: this.userToken.meta.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.customisableUserProfilesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodPatch
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  invitedMemList = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.profileListApiCallId = requestMessage.messageId;

    //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileEndPoint
    );

    //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editProfile = (data: any) => {
    this.setState({ isEditProfile: true });
  };

  addNewTeam = () => {
    this.setState({ isAddTeam: true, selectedTeams: [] });
  };

  changeEmailHandler = () => {
    this.setState({
      isEditingEmail: true,
      isEditingPassword: false,
      modalOpen: true,
      isEditProfile: false,
    });
  };

  changePasswordHandler = () => {
    this.setState({
      isEditingPassword: true,
      isEditingEmail: false,
      modalOpen: true,
      isEditProfile: false,
    });
  };

  toggleProfileDrawer = () => {
    this.setState({
      isProfileDrawerOpened: true,
    });
  };
  closeProfileDrawer = () => {
    this.setState({
      isProfileDrawerOpened: false,
    });
  };

  removeTeamHandler = (id: any , title:string) => {
    if (!this.isOwner() && !this.isManager()) {
      return;
    }

    this.setState({ isRemoveTeam: true, teamId: id , teamTitle: title });
  };

  removeMemberApiCall = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const body = {
      choose_team: JSON.stringify(this.state.teamId),
      ids: this.userToken.data.id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeMemberApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webRemoveMember
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  toggleShowTeams = () => {
    this.setState({ showMoreTeams: !this.state.showMoreTeams });
  };

  teamSelectHandler = (e: any, value: any, reason: any) => {
    this.setState({ selectedTeams: value });
  };

  addToNewTeamApiCall = () => {
    if (this.state.selectedTeams.length == 0) {
      this.handleClose();
      return;
    }
    let choose_team = this.state.selectedTeams.map((item) => item.id);
    //   this.setState({selectedTeamLength: this.state.selectedTeamsData?.teams?.length})
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const body = {
      id: this.userToken?.data.id,
      team_ids: choose_team,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addNewTeamApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webUpdateMemberTeam
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.requestMethodPatch
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  countryListAPICall = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.countryListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webGetCountryList
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  stateListAPICall = () => {
    if (this.state.country == "" || this.state.countryList.length == 0) {
      return;
    }
    let country = this.state.countryList.find(
      (x: any) => x.label == this.state.country
    );
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.stateListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webGetStateList + `?country_id=${country.value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  cityListAPICall = () => {
    if (this.state.stateVal == "" || this.state.stateList.length == 0) {
      return;
    }
    let stateVal = this.state.stateList.find(
      (x: any) => x.label == this.state.stateVal
    );
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cityListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webGetCityList + `?state_id=${stateVal.value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchTeams = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchTeamApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webTeamsGetEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addJobTitle = (data: any, setError?: any, setShowDialog?: any) => {
    this.setErrorTitle = setError;
    this.showDialogTitle = setShowDialog;
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (data.id != null) {
      this.renameJobTitleRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.renameJobTitleEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            id: data.id,
            title: data.title,
          },
        })
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.requestMethodPatch
      );
    } else {
      this.createNewJobTitleRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createNewJobTitleEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            title: data.title,
          },
        })
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodPOST
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  titleChangeHandler = (e: any, newValue: any) => {
    this.setState({
      jobTitle: newValue,
    });
  };

  deleteJobTitle = (data: any) => {
    this.setState({ isDeleting: true, deleteJobTitleId: data.id });
  };

  deleteJobTitleFromList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const body = {
      id: this.state.deleteJobTitleId,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteJobtitleApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webDeleteJobTitleEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteJobTitleResponse = () => {
    if (
      this.state.jobTitle &&
      this.state.deleteJobTitleId == this.state.jobTitle.id
    ) {
      this.setState({ jobTitle: null });
    }

    this.setState({ deleteJobTitleId: "" });
    this.fetchTitleList();
    this.handleCloseTitle();
    this.createToastNotification("Title deleted Successfully");
  };

  fetchTitleList = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getJobTitlesRequestId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getJobTitlesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  undoactionAPICall = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.undoActionAPICalld = requestMessage.messageId;

    // //* Adding endpoint

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.undoActionAPIEndPint
    );

    // //* Adding header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    // //* Adding Request Method

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    // //* Making Network Request
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createTitleSuccess = (responseJson: any) => {
    this.currentJobTitle.id = responseJson.data.attributes.id;
    this.showDialogTitle(false);
    this.fetchTitleList();
  };

  editTitleSuccess = (responseJson: any) => {
    this.currentJobTitle.id = responseJson.id;
    this.showDialogTitle(false);
    this.fetchTitleList();
  };

  checkTitleError = (responseJson: any, apiRequestCallId: string) => {
    if (
      apiRequestCallId === this.createNewJobTitleRequestId ||
      apiRequestCallId === this.renameJobTitleRequestId
    ) {
      const msg: string = responseJson.errors[0].message;
      if (msg.includes("already exists")) {
        this.setErrorTitle(this.t("Job title already exists"));
      } else {
        this.setErrorTitle(msg);
      }
    }
    if (apiRequestCallId === this.updateUserEmailAPICallId) {
      if (responseJson.errors.length > 0) {
        this.setState({ newEmailAddressError: this.t("Email already exists") });
      }
    }
    if (apiRequestCallId === this.updatePasswordAPICallId) {
      this.handlePasswordUpdateUser(responseJson);
    }
  };
  backToDashboard=()=>{
    if(!this.state.isFormEdit){
      this.props.navigation.navigate("EquizDashboardWeb")
    }else{
      this.setState({
        onEditExitFormSave:true
      })
    }
  }
  addAnotherMember = () => {
    this.props.navigation.navigate("InviteMembers");
  };
  navigateToBulk = () => {
    this.props.navigation.navigate("BulkUpload");
  };

  currencyHandler = (e: React.ChangeEvent<{ value:unknown }>) => {
    this.setState({ currencyName: e.target.value as string });
  };

  displayCurrency = () => {
    const { currencyList } = this.state;
    const formatedData = currencyList.map((item, i) => { return ({ ...item, label: item.currencyName, value: item.currencyCode }) });
    return (formatedData)
  }
}
// Customizable Area End